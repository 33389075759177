import { Address } from './../update/address';
import { UpdateComponent } from './../update/update.component';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../global.service';
import { InformationComponent } from '../information.component';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ErrorCheckService } from '../../shared/services/errorCheck.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {
  goTo: string;
  resAdd: Address;
  mailAdd: Address;
  updateSuccess: boolean;
  resPostalCode = '';
  mailAddPostalCode = '';
  residentialCountry;
  flagAddress = '';
  mailingCountry;
  status: boolean;
  bothLocal: boolean;
  oneForeign: boolean;
  bothForeign: boolean;
  addressDataObj;
  individualFirstName;
  individualLastName;
  individualName;
  stateList;
  nonResidentFlag: boolean;
  showNonResidentMsg: boolean = false;
  addressData;
  isFromUpdate;
  editButtonFlag: boolean = true;
  isUnsuccessMsg = false;
  constructor(private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private _infoComponent: InformationComponent, private activeScreen: ActiveScreenService,
    private storedResponseData: StoredResponseDataService, private spinnerService: Ng4LoadingSpinnerService,
    private route: ActivatedRoute, private _router: Router, private titleService: Title, private errorCheckService: ErrorCheckService) {
  }

  ngOnInit() {
    setTimeout( () => {
    this.apiServiceCall.getSystemNumber().subscribe(res => {
      if(res['PensionAccountResponse'].status.statusCode != 0){        
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");
        
      }else{
      this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
      this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
      this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
      let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
      this._globalService.setSystemNumber(systemNumber);
      this.spinnerService.hide();
      //for checking the account state 
      this.activeScreen.changeMessage('View My Information');
      this.titleService.setTitle('Large Case Pensions Payee Services - View Address');
      this.activeScreen.setTitle('Large Case Pensions Payee Services - View Address');
      this.updateSuccess = this._globalService.getUpdateSuccess();
      this.spinnerService.show();
      this.getState();
      if (this.updateSuccess) {
        
        this.individualName = this._globalService.getIndividualName();
        this.flagAddress = this._globalService.getSameResMailFlag();
        if (this.flagAddress == "Y") {
          this._globalService.setSameAddressFlag(true);
        } else {
          this._globalService.setSameAddressFlag(false);
        }
        this._globalService.setSameResMailFlag("");
        this.resAdd = new Address();
        this.mailAdd=new Address();
        setTimeout(()=>{ this.getAddressData(); }, 4000);
        this.apiServiceCall.getAddressInfo().subscribe(addressData => {
          if (addressData["RegistrationResponse"].status.additionalStatus[0].statusCode === "55") {
            this._router.navigateByUrl("error");
          }
          this._globalService.setRegistrationData(addressData);
          this._globalService.setCommonFlag(true);
        },  error => {
          let str = JSON.parse(error["_body"]).moreInformation;
          let errorCode = str.search("0055");
          if (errorCode != -1) {
            this.spinnerService.hide();
            this._router.navigateByUrl("error");
          }
          else {
            this.spinnerService.hide();
            this._router.navigateByUrl("unexpectedErrorPage");
           }
        });
        //this.resAdd = this._globalService.getResAdd();
        //this.mailAdd = this._globalService.getMailAdd();

      /*  if ((this.resAdd['country'] === '' || this.resAdd['country'] === 'USA') &&
          (this.mailAdd['country'] === '' || this.mailAdd['country'] === 'USA')) {
          this.bothLocal = true;
        } else if ((!(this.resAdd['country'] === '' || this.resAdd['country'] === 'USA') &&
          !(this.mailAdd['country'] === '' || this.mailAdd['country'] === 'USA'))) {
          this.bothForeign = true;
        } else if (this.flagAddress === 'Y' && !(this.resAdd['country'] === '' || this.resAdd['country'] === 'USA')) {
          this.bothForeign = true;
        } else if (this.flagAddress === 'Y' && (this.resAdd['country'] === '' || this.resAdd['country'] === 'USA')) {
          this.bothLocal = true;
        } else {
          this.oneForeign = true;
        }*/
        this._globalService.setUpdateSuccess(false);
      } else {
        this.individualName = this._globalService.getIndividualName();
        this.nonResidentFlag = this._globalService.getNonResidentFlag();
        this.isFromUpdate = this._globalService.getIsFromUpdate();
        if (this.nonResidentFlag) {
          if (this.isFromUpdate) {
            this.showNonResidentMsg = true;
          }
          this.resAdd = this._globalService.getNonResidentResData();
          this.mailAdd = this._globalService.getNonResidentMailData();
        } else {
          this.resAdd = this._globalService.getResAdd();
          this.mailAdd = this._globalService.getMailAdd();
          setTimeout( () => {
            this.getAddressData()
          }, 4000);
          /*if (this._globalService.getCookie('email_ID')) {
            console.log(this._globalService.getAccountStatusChangeReason());
            if (this._globalService.getAccountStatusChangeReason() != "CONFIRMD") {
              this.verifyEmail();
            } else {
              this._globalService.deleteCookie("email_ID");
              this.getAddressData();
            }
          } else {
            this._globalService.deleteCookie("email_ID");
            this.getAddressData();
          }*/
        }
      }
     } });
  }, 2000);
  }
  /// getting state
  getState() {
    this.apiServiceCall.getState().subscribe(res => {
      this.stateList = res;
      this._globalService.setState(this.stateList);
      for (var i = 0; i < this.stateList.length; i++) {
        if (this.stateList[i].id === this.mailAdd['state']) {
          this.mailAdd['state'] = this.stateList[i].value;
        }
      }
      for (var i = 0; i < this.stateList.length; i++) {
        if (this.stateList[i].id === this.resAdd['state']) {
          this.resAdd['state'] = this.stateList[i].value;
        }
      }
    });
  }

  

  // setting residential data
  setResidentialData(addressData) {
    let postalArray = [];
    let zipLength;
    let zip4Length;
    this.resAdd['address1'] = this.addressDataObj.residentAddress.streetLine1;
    this.resAdd['address2'] = this.addressDataObj.residentAddress.streetLine2;
    this.resAdd['address3'] = this.addressDataObj.residentAddress.streetLine3;
    // checking the address whether it is domestic or foreign
    if (this.residentialCountry === '' || this.residentialCountry === 'USA') {
      this.resAdd['city'] = this.addressDataObj.residentAddress.city;
      this.resAdd['state'] = this.addressDataObj.residentAddress.state;
      this.resAdd['country'] = 'USA';
      this.resPostalCode = this.addressDataObj.residentAddress.postalCode;
      if (this.resPostalCode == '') {
        this.resPostalCode = '00000-0000';
        postalArray = this.resPostalCode.split('-');
        this.resAdd['zipcode'] = postalArray[0];
        this.resAdd['zip4'] = postalArray[1];
      } else {
        postalArray = this.resPostalCode.split('-');
        if (postalArray[0]) {
          zipLength = postalArray[0].length;
        } else {
          zipLength = 0;
          postalArray[0] = '';
        }
        if (postalArray[1]) {
          zip4Length = postalArray[1].length;
        } else {
          zip4Length = 0;
          postalArray[1] = '';
        }

        if (zipLength === 5) {
          this.resAdd['zipcode'] = postalArray[0];
        } else if (zipLength < 5) {
          let noOfZeros = 5 - zipLength;
          for (let i = 0; i < noOfZeros; i++) {
            postalArray[0] = '0' + postalArray[0];
          }
          this.resAdd['zipcode'] = postalArray[0];
        }
        if (zip4Length === 4) {
          this.resAdd['zip4'] = postalArray[1];
        } else if (zip4Length < 4) {
          let noOfZeros = 4 - zip4Length;
          for (let i = 0; i < noOfZeros; i++) {
            postalArray[1] = '0' + postalArray[1];
          }
          this.resAdd['zip4'] = postalArray[1];
        }
      }
    } else {
      this.resAdd['city'] = this.addressDataObj.residentForeignAddress.city;
      this.resAdd['state'] = this.addressDataObj.residentForeignAddress.country.geopoliticalSubregion[0].name;
      this.resAdd['country'] = this.addressDataObj.residentForeignAddress.country.fullName;
      this.resPostalCode = this.addressDataObj.residentForeignAddress.postalCode;
      if (this.resPostalCode == "") {
        this.resPostalCode = '0000-0000';
        postalArray = this.resPostalCode.split('-');
        this.resAdd['zipcode'] = postalArray[0];
        this.resAdd['zip4'] = postalArray[1];
      } else if (this.resPostalCode.length == 4) {
        this.resAdd['zipcode'] = this.resPostalCode;
        this.resAdd['zip4'] = "0000";
      }
      else {
        zipLength = this.resPostalCode.length;
        if (zipLength == 8) {
          this.resAdd['zipcode'] = this.resPostalCode.substring(0, 4);
          this.resAdd['zip4'] = this.resPostalCode.substring(4, 8);
        } else if (zipLength < 8) {
          let noOfZeros = 8 - zipLength;
          for (let i = 0; i < noOfZeros; i++) {
            this.resPostalCode = this.resPostalCode + '0'
          }
          this.resAdd['zipcode'] = this.resPostalCode.substring(0, 4);
          this.resAdd['zip4'] = this.resPostalCode.substring(4, 8);
        } else {
          this.resAdd['zipcode'] = '0000';
          this.resAdd['zip4'] = '0000';
        }
      }
    }
  }

  getAddressData() {
   // this.spinnerService.show();
    this.apiServiceCall.getState().subscribe(res => {
      this.stateList = res;
      this._globalService.setState(this.stateList);
      this.apiServiceCall.getAddressInfo().subscribe(addressData => {
        if (addressData["RegistrationResponse"].status.additionalStatus[0].statusCode === "55") {
          this._router.navigateByUrl("error");
        }
        this._globalService.setRegistrationData(addressData);
        this._globalService.setCommonFlag(true);
        this.loadData();
        for (var i = 0; i < this.stateList.length; i++) {
          if (this.stateList[i].id === this.mailAdd['state']) {
            this.mailAdd['state'] = this.stateList[i].value;
          }
        }
        for (var i = 0; i < this.stateList.length; i++) {
          if (this.stateList[i].id === this.resAdd['state']) {
            this.resAdd['state'] = this.stateList[i].value;
          }
        }
      },
       error => {
          let str = JSON.parse(error["_body"]).moreInformation;
          let errorCode = str.search("0055");
          if (errorCode != -1) {
            this.spinnerService.hide();
            this._router.navigateByUrl("error");
          }
          else {
            this.spinnerService.hide();
            this._router.navigateByUrl("unexpectedErrorPage");
           }
          this.spinnerService.hide();
        } );
    });
  }

  loadData() {
    let editShow;
    this.addressData = this._globalService.getRegistrationData();
    this.addressDataObj = this.addressData['RegistrationResponse'].readRegistrationResponse;
    editShow = this.addressDataObj.addressRules.isEnabled;
    if (editShow == "Y") {
      this.editButtonFlag = false;
    }
    else {
      this.editButtonFlag = true;
    }
    this.individualFirstName = this.addressDataObj.individual.nameFirst;
    this.individualLastName = this.addressDataObj.individual.nameLast;
    this.individualName = this.individualFirstName + ' ' + this.individualLastName;
    this._globalService.setIndividualName(this.individualName);
    this.storedResponseData.changeUser(this.individualName);
    this.flagAddress = this.addressDataObj.AddressData.currentValue;
    //same address flag for checkbox
    if (this.flagAddress == "Y") {
      this._globalService.setSameAddressFlag(true);
    } else {
      this._globalService.setSameAddressFlag(false);
    }
    this.mailingCountry = this.addressDataObj.mailingForeignAddress.country.fullName;
    this.residentialCountry = this.addressDataObj.residentForeignAddress.country.fullName;
    // if both address are same
    if (this.flagAddress === 'Y') {
      this.setResidentialData(this.addressDataObj);
      this.mailAdd = this.resAdd;
      this._globalService.setMailAdd(this.resAdd);
    } else {
      ///// if both the address are diffrent
      let mailPostalArray = [];
      let mailZipLength;
      let mailZip4Length;
      this.setResidentialData(this.addressDataObj);
      this.mailAdd['address1'] = this.addressDataObj.mailingAddress.streetLine1;
      this.mailAdd['address2'] = this.addressDataObj.mailingAddress.streetLine2;
      this.mailAdd['address3'] = this.addressDataObj.mailingAddress.streetLine3;
      ///// checking the address whether it is domestic or foreign for mailing address
      if (this.mailingCountry === '' || this.mailingCountry === 'USA') {
        this.mailAdd['city'] = this.addressDataObj.mailingAddress.city;
        this.mailAdd['state'] = this.addressDataObj.mailingAddress.state;
        this.mailAddPostalCode = this.addressDataObj.mailingAddress.postalCode;
        this.mailAdd['country'] = 'USA';
        if (this.mailAddPostalCode == '') {
          this.mailAddPostalCode = '00000-0000';
          mailPostalArray = this.mailAddPostalCode.split('-');
          this.mailAdd['zipcode'] = mailPostalArray[0];
          this.mailAdd['zip4'] = mailPostalArray[1];
        }
        else {
          mailPostalArray = this.mailAddPostalCode.split('-');

          if (mailPostalArray[0]) {
            mailZipLength = mailPostalArray[0].length;
          } else {
            mailZipLength = 0;
            mailPostalArray[0] = '';
          }
          if (mailPostalArray[1]) {
            mailZip4Length = mailPostalArray[1].length;
          } else {
            mailZip4Length = 0;
            mailPostalArray[1] = '';
          }


          if (mailZipLength === 5) {
            this.mailAdd['zipcode'] = mailPostalArray[0];
          } else if (mailZipLength < 5) {
            let noOfZeros = 5 - mailZipLength;
            for (let i = 0; i < noOfZeros; i++) {
              mailPostalArray[0] = '0' + mailPostalArray[0];
            }
            this.mailAdd['zipcode'] = mailPostalArray[0];
          }
          if (mailZip4Length === 4) {
            this.mailAdd['zip4'] = mailPostalArray[1];
          } else if (mailZip4Length < 4) {
            let noOfZeros = 4 - mailZip4Length;
            for (let i = 0; i < noOfZeros; i++) {
              mailPostalArray[1] = '0' + mailPostalArray[1];
            }
            this.mailAdd['zip4'] = mailPostalArray[1];
          }
        }
      } else {
        this.mailAdd['city'] = this.addressDataObj.mailingForeignAddress.city;
        this.mailAdd['state'] = this.addressDataObj.mailingForeignAddress.country.geopoliticalSubregion[0].name;
        this.mailAdd['country'] = this.addressDataObj.mailingForeignAddress.country.fullName;
        this.mailAddPostalCode = this.addressDataObj.mailingForeignAddress.postalCode;
        if (this.mailAddPostalCode == '') {
          this.mailAddPostalCode = '0000-0000';
          mailPostalArray = this.mailAddPostalCode.split('-');
          this.mailAdd['zipcode'] = mailPostalArray[0];
          this.mailAdd['zip4'] = mailPostalArray[1];
        } else if (this.mailAddPostalCode.length == 4) {
          this.mailAdd['zipcode'] = this.mailAddPostalCode;
          this.mailAdd['zip4'] = "0000";
        }
        else {
          mailZipLength = this.mailAddPostalCode.length;
          if (mailZipLength == 8) {
            this.mailAdd['zipcode'] = this.mailAddPostalCode.substring(0, 4);
            this.mailAdd['zip4'] = this.mailAddPostalCode.substring(4, 8);
            console.log("foriegn res mail code", this.mailAdd['zipcode'], this.mailAdd['zip4']);
          } else if (mailZipLength < 8) {
            let noOfZeros = 8 - mailZipLength;
            for (let i = 0; i < noOfZeros; i++) {
              this.mailAddPostalCode = this.mailAddPostalCode + '0'
            }
            this.mailAdd['zipcode'] = this.mailAddPostalCode.substring(0, 4);
            this.mailAdd['zip4'] = this.mailAddPostalCode.substring(4, 8);
            console.log("foriegn mail code", this.mailAdd['zipcode'], this.mailAdd['zip4']);
          } else {
            this.mailAdd['zipcode'] = '0000';
            this.mailAdd['zip4'] = '0000';
          }
        }
      }
    }
    /// for display messages content and edit button show functionality
    if ((this.residentialCountry === '' || this.residentialCountry === 'USA') &&
      (this.mailingCountry === '' || this.mailingCountry === 'USA')) {
      this.bothLocal = true;
    } else if ((!(this.residentialCountry === '' || this.residentialCountry === 'USA') &&
      !(this.mailingCountry === '' || this.mailingCountry === 'USA'))) {
      this.bothForeign = true;
    } else if (this.flagAddress === 'Y' && !(this.residentialCountry === '' || this.residentialCountry === 'USA')) {
      this.bothForeign = true;
    } else if (this.flagAddress === 'Y' && (this.residentialCountry === '' || this.residentialCountry === 'USA')) {
      this.bothLocal = true;
    } else {
      this.oneForeign = true;
    }
    if (this.addressDataObj.addressRules.message !== 'Service completed successfully.') {
      this.isUnsuccessMsg = true;
      this.mailAdd['unsuccessMsg'] = this.addressDataObj.addressRules.message;
    }
    this.spinnerService.hide();
    this._globalService.setMailAdd(this.mailAdd);
    this._globalService.setResAdd(this.resAdd);
    this._globalService.setNonResidentResData(this.resAdd);
    this._globalService.setNonResidentMailData(this.mailAdd);
    // });
  }
  setGoTo(value) {
    this._globalService.setGoTo(value);
    this._infoComponent.goTo = this._globalService.getGoTo();
  }

}
