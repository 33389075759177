import { DashboardComponent } from './components/dashboard.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationComponent } from '../information/information.component';
import { AuthService } from '../shared/services/auth.service';

@NgModule({
    declarations: [DashboardComponent],
    imports: [CommonModule],
    exports: [],
    bootstrap: [DashboardComponent],
    providers:[AuthService]
})
export class DashboardModule { }
