import { Component, OnInit, HostListener } from '@angular/core';
import { GlobalService } from '../../global.service';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { PaymentMethod } from './../payment-method/payment-method';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { FormatNumberPipe } from './../../pipes/format-number.pipe';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { InformationComponent } from '../information.component';
import { CompareRoutingNoValidator, CompareAccountNoValidator } from '../../compareValue.validator';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormatPhonePipe } from '../../pipes/formatphone.pipe';
import { ErrorCheckService } from '../../shared/services/errorCheck.service';
declare var $: any;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  displayPaymentUI = false;
  goTo = 'paymentMethod';
  showSSN = false;
  myMask = true;
  ssnMask = [/[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, '-', /[A-Za-z0-9]/, /[A-Za-z0-9]/, '-', /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/];
  registrationData;
  getRegistrationData;
  getPensionAccountData;
  getJointAccountHolderData;
  paymentMethod: PaymentMethod;
  multiplePayDiffPeyMethods = false;
  agreed = false;
  fNamePattern = '[a-zA-Z0-9 \'][^#&<>\\\\"~;$^%={}?]{0,14}$';
  lNamePattern = '[a-zA-Z0-9  \'][^#&<>\\\\"~;$^%={}?]{0,23}$';
  paymentMethodData = {};
  registrationPayload;
  updateUnsuccess = false;
  updateSuccess = false;
  updateUnsuccessMsg;
  paymentMethodFormForEFT: FormGroup;
  routingFormGroup: FormGroup;
  accountNoFormGroup: FormGroup;
  paymentMethodFormForResOrMail: FormGroup;
  paymentMethodFormForBank: FormGroup;
  selectedPayment;
  isAccountSavings: boolean;
  isJointAccount: boolean;
  editButtonFlag = false;
  selectedRelation;
  multiPayDiffPayMethods = false;
  pMethodSaveClicked = false;
  multiPayDiffMethodsMsg;
  sendMyPeriodicTableTo = {
    'D': 'Send check to my bank',
    'M': 'Send check to my mailing address',
    'E': 'Electronic Funds Transfer (EFT) to my bank'
  };
  yesOrNo = { 'Y': 'Yes', 'N': 'No' };
  accType = { 'S': 'Savings', 'C': 'Checking' };
  relationships = { 'S': 'Spouse', 'C': 'Child', 'P': 'POA', 'O': 'Other', 'BS': 'Brother/Sister', '': 'Other' };

  constructor(private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private spinnerService: Ng4LoadingSpinnerService, private activeScreen: ActiveScreenService,
    private _formBuilder: FormBuilder, private _router: Router, private titleService: Title,
  private errorCheckService: ErrorCheckService) { }

  ngOnInit() {
    this.titleService.setTitle('Large Case Pensions Payee Services - View Payment Information');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - View Payment Information');
    this.spinnerService.show();
    if(this._globalService.getSystemNumber()){
      this.paymentData();
    }else{
    this.apiServiceCall.getSystemNumber().subscribe(res=>{
      if(res['PensionAccountResponse'].status.statusCode != 0){        
        // // // ** //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");
        
      }else{     
      this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
      let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
      this._globalService.setSystemNumber(systemNumber);
      this.spinnerService.hide();
      this.paymentData();}
    });
  }
  }

  displayPayMethod() {
    this.routingFormGroup = this._formBuilder.group({
      routingNo: [this.paymentMethod.routingNo, [Validators.required, Validators.pattern('^([0-9]{9})?$')]],
      retypeRoutingNo: [this.paymentMethod.routingNo, [Validators.required]]
    }, {
        validator: CompareRoutingNoValidator.validate.bind(this)
      });
    this.accountNoFormGroup = this._formBuilder.group({
      accNo: [this.paymentMethod.accNo, [Validators.required,
      Validators.minLength(1), Validators.maxLength(18)]],
      retypeAccNo: [this.paymentMethod.accNo, [Validators.required]]
    }, {
        validator: CompareAccountNoValidator.validate.bind(this)
      });
    this.paymentMethodFormForEFT = this._formBuilder.group({
      sendPeriodicPayTo: [this.paymentMethod.sendMyPeriodPaymentTo, [Validators.required]],
      routingFormGroup: [this.routingFormGroup],
      accountNoFormGroup: [this.accountNoFormGroup],
      AccType: [this.isAccountSavings, [Validators.required]],
      jointAccHolder: [this.isJointAccount, [Validators.required]],
      jointAccHolderFName: [this.paymentMethod.jointAccHolderFName,
                            [Validators.required,
                            Validators.pattern(this.fNamePattern)]],
      jointAccHolderLName: [this.paymentMethod.jointAccHolderLName,
                            [Validators.required,
                             Validators.pattern(this.lNamePattern)]],
      jointAccHolderSSNMask: [this.paymentMethod.jointAccHolderSSNMask,
        [Validators.required, Validators.pattern('^[0-9]{3}[-]?\s?[0-9]{2}[-]?[0-9]{4}$')]],
      jointAccHolderSSN: [this.paymentMethod.jointAccHolderSSN,
      [Validators.required, Validators.pattern('^[0-9]{3}[-]?\s?[0-9]{2}[-]?[0-9]{4}$')]],
      jointAccHolderRel: [this.paymentMethod.jointAccHolderRelationship, [Validators.required]]
    });
    this.paymentMethodFormForResOrMail = this._formBuilder.group({
      sendPeriodicPayTo: [this.paymentMethod.sendMyPeriodPaymentTo, [Validators.required]],
    });
    this.paymentMethodFormForBank = this._formBuilder.group({
      sendPeriodicPayTo: [this.paymentMethod.sendMyPeriodPaymentTo, [Validators.required]]
    });
  }
  paymentData() {
    this.spinnerService.show();
    /*if (this._globalService.getCommonflag() === true) {
      this.displayPaymentUI = true;
      this.loadData();
      this.spinnerService.hide();
    } else {*/
      this.apiServiceCall.getAddressInfo().subscribe(addressData => {
        this.displayPaymentUI = true;
        return new Promise((resolve, reject) => {
          this._globalService.setRegistrationData(addressData);
          resolve();
          this.spinnerService.hide();
          }).then(()=>{
          this.loadData();
          this.spinnerService.hide();
          });
        /*this.setRegistration(addressData).then(()=>{
          this.loadData(); 
          this.displayPaymentUI = true;
        });*/
        //this.loadData();
        
       // this.spinnerService.hide();
       } ,
        error => {
          let str = JSON.parse(error["_body"]).moreInformation;
          let errorCode = str.search("0055");
          if (errorCode != -1) {
            this.spinnerService.hide();
            this._router.navigateByUrl("error");
          }
          else {
            this.spinnerService.hide();
            this._router.navigateByUrl("unexpectedErrorPage");
           }
          this.spinnerService.hide();
        });
   // }
  }

  setRegistration(addressData){
    return new Promise((resolve, reject) => {
      this._globalService.setRegistrationData(addressData);
     
    });
  }

  loadData() {
    this.spinnerService.show();
    this.registrationData = this._globalService.getRegistrationData();
    this.paymentMethod = this._globalService.getPaymentMethod();
    this.getRegistrationData = this.registrationData['RegistrationResponse'].readRegistrationResponse;
    this.getPensionAccountData = this.getRegistrationData.pensionAccount.pensionholderBankAccount;
    this.getJointAccountHolderData = this.getRegistrationData.jointAccountHolder;
    if (this.getRegistrationData.paymentMethodRules.isEnabled === 'Y') {
      this.editButtonFlag = false;
    } else {
      this.editButtonFlag = true;
    }
    this.patchPaymentVal();
    this.spinnerService.hide();
  }

  selectPayment(event: any) {
    this.selectedPayment = event.target.value;
    this.paymentMethodFormForEFT.value.sendPeriodicPayTo = this.selectedPayment;
    this.paymentMethod['sendMyPeriodPaymentTo'] = this.selectedPayment;
    this.paymentMethodFormForEFT.patchValue({ sendPeriodicPayTo: this.selectedPayment });
    this.paymentMethodFormForResOrMail.patchValue({ sendPeriodicPayTo: this.selectedPayment });
    this.paymentMethodFormForBank.patchValue({ sendPeriodicPayTo: this.selectedPayment });
  }

  patchPaymentVal() {
   this.paymentMethod['sendMyPeriodPaymentTo'] = this.getRegistrationData.pensionAccount.preferredPaymentType;
   this.paymentMethod['bankName'] = this.getPensionAccountData.bankName;
    this.paymentMethod['routingNo'] = this.getPensionAccountData.routingNumber;
    this.paymentMethod['accNo'] = this.getPensionAccountData.accountNumber;
    this.paymentMethod['accType'] = this.getPensionAccountData.accountType;
    this.paymentMethod['jointAccHolder'] = this.getPensionAccountData.isJointAccount;
    this.paymentMethod['jointAccHolderFName'] = this.getJointAccountHolderData.nameFirst;
    this.paymentMethod['jointAccHolderLName'] = this.getJointAccountHolderData.nameLast;
    this.paymentMethod['jointAccHolderSSN'] = ('000000000' + this.getJointAccountHolderData.socialSecurityNumber).slice(-9);
    this.paymentMethod['jointAccHolderSSNMask'] = (new FormatPhonePipe()).transform(this.paymentMethod['jointAccHolderSSN']);
    this.paymentMethod['jointAccHolderRelationship'] = this.getRegistrationData.jointaccountHolderRel.relationship;
    if (this.getPensionAccountData.accountType === 'S') {
      this.isAccountSavings = true;
    } else {
      this.isAccountSavings = false;
    }
    if (this.getPensionAccountData.isJointAccount === 'Y') {
      this.isJointAccount = true;
    } else {
      this.isJointAccount = false;
    }
    if (this.getRegistrationData.paymentMethodRules.message !== 'Service completed successfully.') {
      this.multiPayDiffPayMethods = true;
      this.paymentMethod['multiPayDiffPayMethods'] = true;
      this.multiPayDiffMethodsMsg = this.getRegistrationData.paymentMethodRules.message;
    }
    this.titleService.setTitle('Large Case Pensions Payee Services - View Payment Information');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - View Payment Information');
    this.activeScreen.changeMessage('View My Information');
  }

  patchSSN() {
    this.paymentMethodFormForEFT.controls['jointAccHolderSSNMask'].setValue(this.paymentMethodFormForEFT.controls.jointAccHolderSSN.value);
  }
  patchSSNMask() {
   this.paymentMethodFormForEFT.controls.jointAccHolderSSNMask.setValue(this.paymentMethodFormForEFT.controls.jointAccHolderSSNMask.value.replace(/-/g, ''));
   this.paymentMethodFormForEFT.controls.jointAccHolderSSNMask.setValue(this.paymentMethodFormForEFT.controls.jointAccHolderSSNMask.value.replace(/_/g, ''));
   this.paymentMethodFormForEFT.controls['jointAccHolderSSN'].setValue(this.paymentMethodFormForEFT.controls.jointAccHolderSSNMask.value);
  }

  selectRelation(event: any) {
    this.selectedRelation = event.target.value;
    this.paymentMethodFormForEFT.value.jointAccHolderRel = this.selectedRelation;
  }

  save() {
    this.pMethodSaveClicked = true;
    this.updateUnsuccess = false;
    if (this.paymentMethod['sendMyPeriodPaymentTo'] === 'E') {
      if (this.isJointAccount) {
        if (this.paymentMethodFormForEFT.valid && this.routingFormGroup.valid && this.accountNoFormGroup.valid) {
          if (this.agreed) {
          this.updateValue('paymentMethodFormForEFT');
        } else {
            $('#termsAndcondition').css('outline', '1px solid red');
            $('#termsAndcondition').focus();
            this.pMethodSaveClicked = false;
          }
        } else {
          this.validateAllFields(this.routingFormGroup);
          this.validateAllFields(this.accountNoFormGroup);
          this.validateAllFields(this.paymentMethodFormForEFT);
          setTimeout(() => {
            $('.errorBorder:first').focus();
            this.pMethodSaveClicked = false;
          }, 100);
        }

      } else {
        if (this.routingFormGroup.valid && this.accountNoFormGroup.valid) {
          if (this.agreed) {
          this.updateValue('paymentMethodFormForEFT');
        } else {
          $('#termsAndcondition').css('outline', '1px solid red');
          $('#termsAndcondition').focus();
          this.pMethodSaveClicked = false;
        }
        } else {
          this.validateAllFields(this.routingFormGroup);
          this.validateAllFields(this.accountNoFormGroup);
          setTimeout(() => {
            $('.errorBorder:first').focus();
            this.pMethodSaveClicked = false;
          }, 100);
        }
      }
    } else if (this.paymentMethod['sendMyPeriodPaymentTo'] === 'M') {
      if (this.paymentMethodFormForResOrMail.valid) {
        this.updateValue('paymentMethodFormForResOrMail');
      } else {
        this.validateAllFields(this.paymentMethodFormForResOrMail);
        $('.errorBorder:first').focus();
        this.pMethodSaveClicked = false;
      }
    } else if (this.paymentMethod['sendMyPeriodPaymentTo'] === 'D') {
      if (this.paymentMethodFormForBank.controls.sendPeriodicPayTo.value === 'D') {
      this.paymentMethodFormForBank.patchValue({ sendPeriodicPayTo: null });
      }
      if (this.paymentMethodFormForBank.valid) {
        this.updateValue('paymentMethodFormForBank');
      } else {
        this.validateAllFields(this.paymentMethodFormForBank);
        $('.errorBorder:first').focus();
        this.pMethodSaveClicked = false;
      }
    }
  }
  updateValue(formToBeSubmitted) {
    this._globalService.setCommonFlag(false);
      this.spinnerService.show();
      this.pMethodSaveClicked = true;
      if (formToBeSubmitted === 'paymentMethodFormForEFT') {
        this.paymentMethod['routingNo'] = this.routingFormGroup.value.routingNo;
        this.paymentMethod['sendMyPeriodPaymentTo'] = this.paymentMethodFormForEFT.value.sendPeriodicPayTo;
        this.paymentMethod['accNo'] = this.accountNoFormGroup.value.accNo;
        this.paymentMethod['jointAccHolderLName'] = this.paymentMethodFormForEFT.value.jointAccHolderLName;
        this.paymentMethod['jointAccHolderFName'] = this.paymentMethodFormForEFT.value.jointAccHolderFName;
        this.paymentMethod['jointAccHolderSSN'] = this.paymentMethodFormForEFT.value.jointAccHolderSSN.replace(/-/g, '');
        this.paymentMethod['jointAccHolderRelationship'] = this.paymentMethodFormForEFT.value.jointAccHolderRel;
        if (this.paymentMethodFormForEFT.value.AccType === false || this.paymentMethodFormForEFT.value.AccType === 'Checking') {
          this.paymentMethod['accType'] = 'C';
        } else {
          this.paymentMethod['accType'] = 'S';
        }
        if (this.paymentMethodFormForEFT.value.jointAccHolder === false || this.paymentMethodFormForEFT.value.jointAccHolder === 'No') {
          this.paymentMethod['jointAccHolder'] = 'N';
        } else {
          this.paymentMethod['jointAccHolder'] = 'Y';
        }
      }

      if (formToBeSubmitted === 'paymentMethodFormForResOrMail') {
        this.paymentMethod['sendMyPeriodPaymentTo'] = this.paymentMethodFormForResOrMail.value.sendPeriodicPayTo;
        this.paymentMethod['bankName'] = null;
        this.paymentMethod['routingNo'] = null;
        this.paymentMethod['accNo'] = null;
        this.paymentMethod['jointAccHolderLName'] = null;
        this.paymentMethod['jointAccHolderFName'] = null;
        this.paymentMethod['jointAccHolderSSN'] = null;
        this.paymentMethod['jointAccHolderRelationship'] = null;
        this.paymentMethod['accType'] = null;
        this.paymentMethod['jointAccHolder'] = null;
      }
      if (formToBeSubmitted === 'paymentMethodFormForBank') {
        this.paymentMethod['sendMyPeriodPaymentTo'] = this.paymentMethodFormForBank.value.sendPeriodicPayTo;
        this.paymentMethod['bankName'] = null;
        this.paymentMethod['routingNo'] = null;
        this.paymentMethod['accNo'] = null;
        this.paymentMethod['jointAccHolderLName'] = null;
        this.paymentMethod['jointAccHolderFName'] = null;
        this.paymentMethod['jointAccHolderSSN'] = null;
        this.paymentMethod['jointAccHolderRelationship'] = null;
        this.paymentMethod['accType'] = null;
        this.paymentMethod['jointAccHolder'] = null;
      }

      this.registrationPayload = this.getPaymentMethodPayload();

      this.apiServiceCall.updatePaymentMethod(this.registrationPayload).subscribe(res => {
        this._globalService.setCommonFlag(false);
        this.pMethodSaveClicked = false;
        if (res !== '0') {
          this.spinnerService.hide();
          this.updateUnsuccess = true;
          this.updateUnsuccessMsg = res;
        } else {
          this.spinnerService.hide();
          this.paymentData();
          this.goTo = 'paymentMethod';
          this.titleService.setTitle('Large Case Pensions Payee Services - View Payment Information');
          this.activeScreen.setTitle('Large Case Pensions Payee Services - View Payment Information');
          this.updateSuccess = true;
        }
      } ,
        error => {
          let str = JSON.parse(error["_body"]).moreInformation;
          let errorCode = str.search("0055");
          this.pMethodSaveClicked = false;
          if (errorCode != -1) {
            this.spinnerService.hide();
            this._router.navigateByUrl("error");
          }
          else {
            this.spinnerService.hide();
            this._router.navigateByUrl("unexpectedErrorPage");
           }
          this.spinnerService.hide();
        });
  }

  // field Validation
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  getPaymentMethodPayload() {
    console.log('Submitting Pay Method to payload for test');
    if(this.paymentMethod['jointAccHolderSSN'] =="" || this.paymentMethod['jointAccHolderSSN']== undefined){
      this.paymentMethod['jointAccHolderSSN'] ='000000000';
    }
    return this.paymentMethodData = {
      'RegistrationRequest': {
        'updateRegistrationRequest': {
          'addressrules': 'N',
          'contactrules': 'N',
          'paymentmethodrules': 'Y',
          'fedexrules': 'N',
          'statetaxrules': 'N',
          'addressfinalist': 'N',
          'residentstreetline1': this.getRegistrationData.residentAddress.streetLine1,
          'residentstreetline2': this.getRegistrationData.residentAddress.streetLine2,
          'residentstreetline3': this.getRegistrationData.residentAddress.streetLine3,
          'residentcity': this.getRegistrationData.residentAddress.city,
          'residentstate': this.getRegistrationData.residentAddress.state,
          'residentpostalcode': '00000-0000',
          'foreigncountryname': this.getRegistrationData.residentForeignAddress.country.fullName,
          'foreignprovincename': [
            {
              'name': this.getRegistrationData.residentForeignAddress.country.geopoliticalSubregion[0].name,
            }],
          'foreigncityname': this.getRegistrationData.residentForeignAddress.city,
          'foreignmailcode': this.getRegistrationData.residentForeignAddress.postalCode,
          'requeststreetLine1': this.getRegistrationData.mailingAddress.streetLine1,
          'requeststreetLine2': this.getRegistrationData.mailingAddress.streetLine2,
          'requeststreetLine3': this.getRegistrationData.mailingAddress.streetLine3,
          'requestcity': this.getRegistrationData.mailingAddress.city,
          'requeststate': this.getRegistrationData.mailingAddress.state,
          'requestpostalCode': '00000-0000',
          'requestcountryname': this.getRegistrationData.mailingForeignAddress.country.fullName,
          'requestprovincename': [
            {
              'name': this.getRegistrationData.mailingForeignAddress.country.geopoliticalSubregion[0].name
            }],
          'requestcityname': this.getRegistrationData.mailingForeignAddress.city,
          'requestforeignmailcode': this.getRegistrationData.mailingForeignAddress.postalCode,
          'foreigncountrycode': this.getRegistrationData.contactForeignPhone.country.code,
          'foreignphonenumber': '12345-74474744',
          'requestphonenumber': '456-273-1234',
          'emailaddress': this.getRegistrationData.email.address,
          'emaildomain': this.getRegistrationData.email.domain,
          'bankroutingnumber': this.paymentMethod['routingNo'],
          'bankname': this.paymentMethod['bankName'],
          'bankpaymenttype': this.paymentMethod['sendMyPeriodPaymentTo'],
          'bankaccountnumber': this.paymentMethod['accNo'],
          'bankaccounttype': this.paymentMethod['accType'],
          'bankjointaccountind': this.paymentMethod['jointAccHolder'],
          'bankjointholderlastname': this.isJointAccount ? this.paymentMethod['jointAccHolderLName']:"",
          'bankjointholderfirstname': this.isJointAccount ? this.paymentMethod['jointAccHolderFName']:"",
          'bankjointholdermiddlename': '',
          'bankjointholderssn': this.isJointAccount ? this.paymentMethod['jointAccHolderSSN']: "000000000",
          'bankjointholderrelation': this.isJointAccount ? this.paymentMethod['jointAccHolderRelationship']:"",
          'fedtaxelectioncode': this.getRegistrationData.fedTax.pensionTaxElectionCode,
          'fedtaxexemptionnumber': this.getRegistrationData.fedTax.numberOfExemptions,
          'fedtaxfillingstatus': this.getRegistrationData.fedTax.filingStatus,
          'fedtaxwithholdingamt': this.getRegistrationData.fedTax.withholdingAmount,
          'fedtaxaddnlwithholdingamt': this.getRegistrationData.fedTax.additionalWithholdingAmount,
          'statetaxelectioncode': this.getRegistrationData.stateTax.pensionTaxElectionCode,
          'statetaxexemption': this.getRegistrationData.stateTax.numberOfExemptions,
          'statetaxfillingstatus': this.getRegistrationData.stateTax.filingStatus,
          'statetaxwithholdingamt': this.getRegistrationData.stateTax.withholdingAmount,
          'statetaxaddlwithholdingamt': this.getRegistrationData.stateTax.additionalWithholdingAmount,
          'statetaxwithholdingPCT': this.getRegistrationData.stateTax.withholdingPCT,
          'statetaxaddlwithholdingPCT': this.getRegistrationData.stateTax.additionalWithholdingPCT,
          'splexemptiondesc1': [
            {
              'description': '',
              'value': ''
            },
            {
              'description': '',
              'value': ''
            }
          ]
        }
      }
    };
  }
  changeSubMenu(menuName) {
    this.activeScreen.changeMessage(menuName);
    this.titleService.setTitle('Large Case Pensions Payee Services - Update Payment Information');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Update Payment Information');
  }
}
