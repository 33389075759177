import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ActiveScreenService {
  private firstActiveScreen = new BehaviorSubject<string>('Home');
  private userName = new BehaviorSubject<string>('');
  private pensionMsg = new BehaviorSubject<string>('');
  private title = new BehaviorSubject<string>('');
  private isAppTimeout = new BehaviorSubject<boolean>(false);
  
  currentScreen = this.firstActiveScreen.asObservable();
  currentUser = this.userName.asObservable();
  currentMsg = this.pensionMsg.asObservable();
  currentTitle = this.title.asObservable();
  appTimeoutFlag = this.isAppTimeout.asObservable();
  constructor() { }

  changeMessage(message: string) {
    this.firstActiveScreen.next(message);
  }
  getUserName(message: string) {
    this.userName.next(message);
  }
  getPensionMsg(message: string) {
    this.pensionMsg.next(message);
  }
  setTitle(title: string) {
    this.title.next(title);
  }
  timeOutSession(message: boolean){
    this.isAppTimeout.next(message);
  }
}
