import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class FooterService {
  private _textUrl: string = '../../pensionportalUI/assets/textsData/footer.json';
  constructor(private _http: HttpClient) {}
    getFooterData() {
      return this._http.get(this._textUrl);
    }
}

