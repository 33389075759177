import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../global.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
    /*private employeeList: any;
    private authToken: string;
    private apiToken: string;
    constructor(private http: HttpClient, private _globalService: GlobalService) { }
    options: any = {};
    getData(url: string, isStatic: boolean) {
        if (isStatic) {
            //let token = this._globalService.getCookie('accessToken');
            let token = sessionStorage.getItem('accessToken');
            let clientId = "&X-IBM-Client-Id=" + environment.client_id;
            let id_token = this._globalService.getCookie('id_token');
            const headers = new HttpHeaders({
                //'X-IBM-Client-Id': environment.client_id,
                'Authorization': "Bearer " + JSON.parse(token).tokenValue,
                'Pragma': 'no-cache',
                'Cache-Control': 'no-cache',
                //'scope':'Public NonPII PII PHI openId',
                'Id_Token': JSON.parse(id_token)
            });
            //this.options.withCredentials = true;
            this.options = { headers: headers, withCredentials: true };
            //test
            //console.log("ID TOKEN" + id_token);
            let data: any;
            data = "Authorization=Bearer " + JSON.parse(token).tokenValue + clientId;
            url = url;
        }
        return this.http.get(url, this.options = isStatic ? this.options : {})
            .pipe(map(response => response));
        //      catchError(error=>error));
    }

    putData(url: string, payload, isStatic: boolean) {
        if (isStatic) {
            //let token = this._globalService.getCookie('accessToken');
            let token = sessionStorage.getItem('accessToken');
            //let clientId = "&X-IBM-Client-Id="+environment.client_id;
            let id_token = this._globalService.getCookie('id_token');
            //'X-IBM-Client-Id': environment.client_id,
            const headers = new HttpHeaders({
                'Authorization': "Bearer " + JSON.parse(token).tokenValue,
                'Id_Token': JSON.parse(id_token)
            });
            this.options.headers = headers;

            let data: any;
            //data = "Authorization=Bearer "+JSON.parse(token).tokenValue+clientId;
            url = url;
        }
        return this.http.put(url, payload, this.options = isStatic ? this.options : {});
        /*.pipe(map(response => response));((err) => {
            
            // Do messaging and error handling here
           console.log(err);
            return Observable.throw(err)
        })*/
    //}

    /*getTaxDocument(url: string, isStatic: boolean) {
        if (isStatic) {
            //let token = this._globalService.getCookie('accessToken');
            let token = sessionStorage.getItem('accessToken');
            let clientId = "&X-IBM-Client-Id=" + environment.client_id;
            let id_token = this._globalService.getCookie('id_token');
            let withCredentialsFlag = false;
            const headers = new HttpHeaders({
                'X-IBM-Client-Id': environment.client_id,
                'Authorization': "Bearer " + JSON.parse(token).tokenValue
                // 'Pragma': 'no-cache',
                //'Cache-Control': 'no-cache',
                // 'Access-Control-Allow-Origin' : '*'
                //'scope':'Public NonPII PII PHI openId',

                //'Id_Token': JSON.parse(id_token)
            });
            /* This is temporary fix until F5 team fix the CORS issue in production */
           /* if (environment.envName == "qa") {
                withCredentialsFlag = true;
            }
            else {
                withCredentialsFlag = false;
            }*/

            //this.options.withCredentials = true;
           // this.options = { headers: headers, withCredentials: withCredentialsFlag, useXDomain: true };
            //test
            //console.log("ID TOKEN" + id_token);
            //let data: any;
            //data = "Authorization=Bearer "+JSON.parse(token).tokenValue+clientId;
            // url = url;
      //  }
      //  return this.http.get(url, this.options = isStatic ? this.options : {});
        //.pipe(map(response => response));(error=>error);
   // }
}
