import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActiveScreenService } from '../services/activeScreen/active-screen.service';
import { GlobalService } from '../global.service';
import { ApiServiceCall } from '../shared/services/apiCall.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StoredResponseDataService } from '../services/storedResponseData/stored-response-data.service';

import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-download-forms',
  templateUrl: './download-forms.component.html',
  styleUrls: ['./download-forms.component.scss']
})
export class DownloadFormsComponent implements OnInit, AfterViewInit {

  registrationData;
  getUsernameData;

  private sharedLoc = environment.sharedLoc;

  downloadFormsList = [
    {'form': 'Electronic Funds Transfer Direct Deposit to Bank',
      'useThisFormTo': 'Sign up for Direct Deposit or to change your current Direct Deposit banking information',
      'action': this.sharedLoc + '/downloadForms/Electronic_Funds_Transfer_Authorization_Form_English.pdf'},
    {'form': 'Electronic Funds Transfer Direct Deposit to Bank - Spanish Version',
      'useThisFormTo': 'Sign up for Direct Deposit or to change your current Direct Deposit banking information',
      'action': this.sharedLoc + '/downloadForms/Electronic_Funds_Transfer_Authorization_Form_Spanish.pdf'},
    {'form': 'Direct Deposit Forms for Foreign/ Non-US Banks',
      'useThisFormTo':
        'Have Aetna mail your payment directly to your designated bank, if your bank is located outside of the United States',
      'action': this.sharedLoc + '/downloadForms/Foreign_Direct_Deposit_Authorization_Form.pdf'},
    {'form': 'Beneficiary Change', 'useThisFormTo': 'Designate or change your beneficiaries',
      'action': this.sharedLoc + '/downloadForms/Beneficiary_Change_Form.pdf'},
    {'form': 'Third Party Release Form', 'useThisFormTo': 'Authorize Aetna to communicate with a third party',
      'action': this.sharedLoc + '/downloadForms/Authorization_to_Release_Information.pdf'},
    {'form': 'IRS W-4P', 'useThisFormTo': 'Submit your Federal income tax withholding election for your periodic pension payments',
      'action': this.sharedLoc + '/downloadForms/IRS_W-4P_Form_w_Tagline.pdf'},
    {'form': 'CT W-4P', 'useThisFormTo': 'Submit your Connecticut income tax withholding election for your periodic pension payments',
      'action': this.sharedLoc + '/downloadForms/CT_W-4P_w_Tagline.pdf'},
    {'form': 'Substitute State Income Tax Withholding Election',
      'useThisFormTo': 'Submit your State income tax withholding election for your periodic pension payments (do not use for Michigan or Connecticut)',
      'action': this.sharedLoc + '/downloadForms/Substitute_State_Income_Tax_Withholding_Election_Form.pdf'},
    {'form': 'Michigan State Withholding Form',
      'useThisFormTo': 'Submit your Michigan income tax withholding election for your periodic pension payments',
      'action': this.sharedLoc + '/downloadForms/MI_W-4P_w_Tagline.pdf'}
];

  constructor(private activeScreen: ActiveScreenService,
    private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private spinnerService: Ng4LoadingSpinnerService,
    private storedResponseData: StoredResponseDataService, private _router: Router, private titleService: Title) {}

  ngOnInit() {
    this.activeScreen.changeMessage('Download Forms');
    this.titleService.setTitle('Large Case Pensions Payee Services - Download Forms');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Download Forms');
    if (this._globalService.getRegistrationData()) {
      this.loadData();
    } else {
      this.apiServiceCall.getAddressInfo().subscribe(addressData => {
        if(addressData["RegistrationResponse"].status.additionalStatus[0].statusCode === "55"){
          this._router.navigateByUrl("error");
         }
        this._globalService.setRegistrationData(addressData);
        this.loadData();
      } ,
      error => {
       let str=JSON.parse(error["_body"]).moreInformation;
       let errorCode=str.search("0055");
       if(errorCode != -1 ){
        this.spinnerService.hide();
        this._router.navigateByUrl("error");
       }
       else {
        this.spinnerService.hide();
        this._router.navigateByUrl("unexpectedErrorPage");
       }
       this.spinnerService.hide();
     });
    }
  }
  ngAfterViewInit() {
    $('.tableRow:nth-child(odd) img').attr('src', '../../pensionportalUI/assets/images/Download_Violet.svg');
    $('.tableRow:nth-child(even) img').attr('src', '../../pensionportalUI/assets/images/Download_Black.svg');
  }
  loadData() {
    this.spinnerService.show();
    this.registrationData = this._globalService.getRegistrationData();
      this.getUsernameData = this.registrationData['RegistrationResponse'].readRegistrationResponse;
      const individualFirstName = this.getUsernameData.individual.nameFirst;
      const individualLastName = this.getUsernameData.individual.nameLast;
      const individualName = individualFirstName + ' ' + individualLastName;
      this.storedResponseData.changeUser(individualName);
      this.spinnerService.hide();
  }
}

