import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { environment } from '../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './../views/terms-of-use.component.html',
  styleUrls: ['./../views/terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  private sharedLoc = environment.sharedLoc;
  qaEnv;
  constructor(private titleService: Title, private activeScreen: ActiveScreenService) { }

  ngOnInit() {
    this.titleService.setTitle('Large Case Pensions Payee Services - Terms Of Use');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Terms Of Use');
    if (this.sharedLoc === 'https://qamobile.aetna.com/appConfig/PensionPortal') {
      this.qaEnv = true;
    } else {
      this.qaEnv = false;
    }
  }

}
