import { InformationComponent } from './../information.component';
import { GlobalService } from './../../global.service';
import { Address } from './address';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import {LocationStrategy } from '@angular/common';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Contact } from '../contact-info/contact';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/shared/services/apiService.service';


declare var $: any;

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  resAdd = new Address();
  mailAdd = new Address();
  contact = new Contact();
  resPostalCode = '';
  mailPostalCode = '';
  addressData = {};
  //Restricted characters  for Address field
  addressAllowedPattern = '^[^<>~$^={}?]{0,49}$';
  cityAllowedPattern = '[a-zA-Z0-9 \'][^#&<>\\\\"~;$^%={}?]{0,19}$';

  /// for payload diffrence between domestic and foreign  
  resCity = '';
  resState = '';
  resCountry = '';
  mailCity = '';
  mailState = '';
  mailCountry = '';
  resForeignCity = '';
  resForeginState = '';
  resForeignPostal = '';
  resForeignCountry = '';
  mailForeignCity = '';
  mailForeginState = '';
  mailForeignPostal = '';
  mailForeignCountry = '';
  mailSameAsResident: boolean = false;
  updateCompnntSaveClicked: boolean = false;
  addressForm: FormGroup;
  countOfForeignAddress = 0;
  countOfMailForeignAddress = 0;
  showForeignMsg = 0;
  nonStandardMsg: boolean;
  registrationData = {};
  registrationPayload;
  stateList;
  mailAddObj = {};
  resAddObj = {};
  addressDataObj;
  flagAddress = "";
  errorMsg;
  nonResidentPayload;
  addressFinalListFlag : boolean = false;
  errorMsgFlag : boolean =false;
  backButtonFlag : boolean = false;
  sameAddressFlag : boolean =false;
  nonResFlag : boolean = false;
  nonMailFlag : boolean = false;
  constructor(private _formBuilder: FormBuilder, private _globalService: GlobalService,
    private _infoComponent: InformationComponent, private apiServiceCall: ApiServiceCall,
    private activeScreen: ActiveScreenService, private spinnerService: Ng4LoadingSpinnerService,
    private titleService: Title, private apiService: ApiService) {}

  showAddress() {
    this.resAdd = this._globalService.getResAdd();
    this.mailAdd = this._globalService.getMailAdd();
    this.stateList = this._globalService.getState();
    this.mailSameAsResident = this._globalService.getSameAddressFlag();
    this._globalService.setSameAddressFlag(false);
    if (!(this.resAdd.country == 'USA' || this.resAdd.country == '')) {
      this.countOfForeignAddress = 1;
    }
    if (!(this.mailAdd.country == 'USA' || this.mailAdd.country == '')) {
      this.countOfMailForeignAddress = 1;
    }
    if ((this.resAdd.country == 'USA' || this.resAdd.country == '')
      && (this.mailAdd.country == 'USA' || this.mailAdd.country == '')) {
      this.showForeignMsg = 1;
    }
    this.addressForm = this._formBuilder.group({
      address1: [{ value: this.resAdd.address1, disabled: this.countOfForeignAddress === 1 },
        [Validators.required, Validators.pattern(this.addressAllowedPattern)]],
      address2: [{ value: this.resAdd.address2, disabled: this.countOfForeignAddress === 1 },
        [  Validators.pattern(this.addressAllowedPattern)]],
      address3: [{ value: this.resAdd.address3, disabled: this.countOfForeignAddress === 1 },
        [  Validators.pattern(this.addressAllowedPattern)]],
      state: [{ value: this.resAdd.state, disabled: this.countOfForeignAddress === 1 }, Validators.required],
      country: [{ value: this.resAdd.country, disabled: true }],
      city: [{ value: this.resAdd.city, disabled: this.countOfForeignAddress === 1 }, [Validators.required,
          Validators.pattern(this.cityAllowedPattern)]],
      zipcode: [{ value: this.resAdd.zipcode.toString(), disabled: this.countOfForeignAddress === 1 }, [Validators.required, Validators.pattern('^([0-9]{5})?$')]],
      zip4: [{ value: this.resAdd.zip4, disabled: this.countOfForeignAddress === 1 }, [Validators.pattern('^([0-9]{4})?$')]],

      mAddress1: [{ value: this.mailAdd.address1, disabled: this.countOfMailForeignAddress === 1 },
        [Validators.required, Validators.pattern(this.addressAllowedPattern)]],
      mAddress2: [{ value: this.mailAdd.address2, disabled: this.countOfMailForeignAddress === 1 },
        [ Validators.pattern(this.addressAllowedPattern)]],
      mAddress3: [{ value: this.mailAdd.address3, disabled: this.countOfMailForeignAddress === 1 },
        [ Validators.pattern(this.addressAllowedPattern)]],
      mState: [{ value: this.mailAdd.state, disabled: this.countOfMailForeignAddress === 1 }, Validators.required],
      mCountry: [{ value: this.mailAdd.country, disabled: true }],
      mCity: [{ value: this.mailAdd.city, disabled: this.countOfMailForeignAddress === 1 },
         [Validators.required, Validators.pattern(this.cityAllowedPattern)]],
      mZipcode: [{ value: this.mailAdd.zipcode.toString(), disabled: this.countOfMailForeignAddress === 1 },
      [Validators.required, Validators.pattern('^([0-9]{5})?$')]],
      mZip4: [{ value: this.mailAdd.zip4, disabled: this.countOfMailForeignAddress === 1 }, [Validators.pattern('^([0-9]{4})?$')]],
      mailSameAsResident: [{ value: this.mailSameAsResident, disabled: this.countOfMailForeignAddress === 1 }]
    });
  }


  ngOnInit() {
    this.activeScreen.changeMessage('Update My Information');
    this.titleService.setTitle('Large Case Pensions Payee Services - Update Address');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Update Address');
    this.showAddress();
    history.pushState(null, null, location.href);
    $(window).on('popstate', function(event) {
        history.go(1);
        $('#backBtnModal').modal();
  });
}

  copyAddress() {
    this.resAddObj = new Address();
    this.mailAddObj = new Address();
    this.resAddObj['address1'] = this.addressForm.get('address1').value;
    this.resAddObj['address2'] = this.addressForm.get('address2').value;
    this.resAddObj['address3'] = this.addressForm.get('address3').value;
    this.resAddObj['city'] = this.addressForm.get('city').value;
    //this.resAddObj['state'] = this.addressForm.get('state').value;
    for (var i = 0; i < this.stateList.length; i++) {
      if (this.stateList[i].value == this.addressForm.get('state').value) {
        this.resAddObj['state'] = this.stateList[i].id;
      }
    }
    this.resAddObj['country'] = this.addressForm.get('country').value;
    this.resAddObj['zipcode'] = this.addressForm.get('zipcode').value;
    this.resAddObj['zip4'] = this.addressForm.get('zip4').value;
    this._globalService.setResAdd(this.resAddObj);
    this.mailAddObj['address1'] = this.addressForm.get('mAddress1').value;
    this.mailAddObj['address2'] = this.addressForm.get('mAddress2').value;
    this.mailAddObj['address3'] = this.addressForm.get('mAddress3').value;
    this.mailAddObj['city'] = this.addressForm.get('mCity').value;
    // this.mailAddObj['state'] = this.addressForm.get('mState').value;
    for (var i = 0; i < this.stateList.length; i++) {
      if (this.stateList[i].value == this.addressForm.get('mState').value) {
        this.mailAddObj['state'] = this.stateList[i].id;
      }
    }
    this.mailAddObj['country'] = this.addressForm.get('mCountry').value;
    this.mailAddObj['zipcode'] = this.addressForm.get('mZipcode').value;
    this.mailAddObj['zip4'] = this.addressForm.get('mZip4').value;
    this._globalService.setMailAdd(this.mailAddObj);
  }
  save() {
    this.updateCompnntSaveClicked = true;
    console.log(this.mailSameAsResident);
    if (this.mailSameAsResident === true) {
      this._globalService.setSameResMailFlag("Y");
      this.copy();
    }
    /*for resident address is foriegn */
    if(this.mailSameAsResident === true && this.addressForm.get('country').value != "USA"){
      console.log("inside true of message");
      this.copyAddress();
      this.resAdd = this._globalService.getResAdd();
      this.registrationPayload = this.setAddressPayload();
      this.apiServiceCall.updateAddressInfo(this.registrationPayload).subscribe(res => {
        this.updateCompnntSaveClicked = false;
        this._globalService.setCommonFlag(false);
        console.log("update address response", res);
        if(res == "67"){
          this.spinnerService.hide();
          console.log("inside res flag");
          this.nonResFlag = true;
          this.nonMailFlag = false;
          this.addressFinalListFlag = true; 
        }else if(res == "68"){
          console.log("inside mail flag");
          this.spinnerService.hide();
          this.nonResFlag = false;
          this.nonMailFlag = true;
          this.addressFinalListFlag = true;
        }
       if(res == "67" || res == "68"){
          this.spinnerService.hide();
          $('#addressModal').modal('show'); 
        }else if ( res == 0){
        for (var i = 0; i < this.stateList.length; i++) {
          if (this.stateList[i].id == this.resAdd['state']) {
            this.resAdd['state'] = this.stateList[i].value;
          }
          if (this.stateList[i].id == this.mailAdd['state']) {
            this.mailAdd['state'] = this.stateList[i].value;
          }
        }
        this._globalService.setResAdd(this.resAdd);
        this._globalService.setMailAdd(this.mailAdd);
        this._globalService.setUpdateSuccess(true);
        this.setGoTo('viewAddress');
        this.spinnerService.hide();
      }else{
        this.errorMsgFlag = true;
        this.errorMsg = res;
        this.spinnerService.hide();
      }
      }, error => {
        this.updateCompnntSaveClicked = false;
           console.log("inside service error");
           this._globalService.setUpdateSuccess(false);
           this._globalService.setNonResidentFlag(true);
           this._globalService.setIsFromUpdate(true);
           this.errorMsgFlag = true;
           this.errorMsg = "Your address has not been updated successfully.";
           //this.setGoTo('viewAddress');
           this.spinnerService.hide();
      })
    }

    if (this.addressForm.valid) {
      this.copyAddress();
      this.resAdd = this._globalService.getResAdd();
      /// setting the payload globally
      this.registrationPayload = this.setAddressPayload();
      this._globalService.setRegistrationPayload(this.registrationPayload);
      console.log("payload", this.registrationPayload);
      ///update service call
      this.spinnerService.show();
      this.apiServiceCall.updateAddressInfo(this.registrationPayload).subscribe(res => {
        if(res == "67"){
          this.spinnerService.hide();
          console.log("inside res flag");
          this.nonResFlag = true;
          this.nonMailFlag = false;
          this.addressFinalListFlag = true; 
        }else if(res == "68"){
          console.log("inside mail flag");
          this.spinnerService.hide();
          this.nonResFlag = false;
          this.nonMailFlag = true;
          this.addressFinalListFlag = true;
        }
       if(res == "67" || res == "68"){
          this.spinnerService.hide();
          $('#addressModal').modal('show'); 
        }else if ( res == 0){
        for (var i = 0; i < this.stateList.length; i++) {
          if (this.stateList[i].id == this.resAdd['state']) {
            this.resAdd['state'] = this.stateList[i].value;
          }
          if (this.stateList[i].id == this.mailAdd['state']) {
            this.mailAdd['state'] = this.stateList[i].value;
          }
        }
        this._globalService.setResAdd(this.resAdd);
        this._globalService.setMailAdd(this.mailAdd);
        this._globalService.setUpdateSuccess(true);
        this.setGoTo('viewAddress');
        this.spinnerService.hide();
      }else{
        this.errorMsgFlag = true;
        this.errorMsg = res;
        this.spinnerService.hide();
      }
      }, error => {
           console.log("inside service error");
           this._globalService.setUpdateSuccess(false);
           this._globalService.setNonResidentFlag(true);
           this._globalService.setIsFromUpdate(true);
           this.errorMsgFlag = true;
           this.errorMsg = "Your address has not been updated successfully.";
           //this.setGoTo('viewAddress');
           this.spinnerService.hide();
      })
    } else {
      this.validateAllFields(this.addressForm);
      $('.errorBorder:first').focus();
    }
  }

  ///field Validation
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  
  copyResidentToMailingAddress() {
    console.log("from untick to tick");
    this.mailSameAsResident = true;
    this._globalService.setSameResMailFlag("Y");
    this.copy();
  }
  updateMailSameAsResident(e) {
    if (!e.target.checked) {
      console.log("from tick to untick");
      this.addressForm.patchValue({
          mAddress1 : '',
          mAddress2 :'',
          mAddress3 :'',
          mCity :'',
          mZipcode : '',
          mZip4 : ''    
      });
      this.mailSameAsResident = false;
    } else { 
      $('#checkboxModal').modal('show');   
    }
  }
  changeCheckboxStatus() {
    console.log("from tick to untick");
    this.addressForm.patchValue({
      'mailSameAsResident': false,
      
        mAddress1 : '',
        mAddress2 :'',
        mAddress3 :'',
      
    });
  }

  ///copy addresses from resident to mailing
  copy() {
    this.addressForm.patchValue({
      'mAddress1': this.addressForm.get('address1').value,
      'mAddress2': this.addressForm.get('address2').value,
      'mAddress3': this.addressForm.get('address3').value,
      'mCity': this.addressForm.get('city').value,
      'mState': this.addressForm.get('state').value,
      'mCountry': this.addressForm.get('country').value,
      'mZipcode': this.addressForm.get('zipcode').value,
      'mZip4': this.addressForm.get('zip4').value,
    });

  }

  onSelect(e) {
    console.log(e);
  }

  setGoTo(value) {
    this._globalService.setGoTo(value);
    this._infoComponent.goTo = this._globalService.getGoTo();
  }

  //// this method called when IBSS throw error for non standard address
  updateAddress() {
    this.spinnerService.show();
    this.nonResidentPayload = this.setAddressPayload();
    this.nonResidentPayload['RegistrationRequest'].updateRegistrationRequest.addressfinalist = "Y"
    console.log(this.nonResidentPayload);
    this.apiServiceCall.updateAddressInfo(this.nonResidentPayload).subscribe(res => {
      console.log(res);
      if (res == 0){
        for (var i = 0; i < this.stateList.length; i++) {
          if (this.stateList[i].id == this.resAdd['state']) {
            this.resAdd['state'] = this.stateList[i].value;
          }
          if (this.stateList[i].id == this.mailAdd['state']) {
            this.mailAdd['state'] = this.stateList[i].value;
          }
        }
        this._globalService.setResAdd(this.resAdd);
        this._globalService.setMailAdd(this.mailAdd);
        this._globalService.setUpdateSuccess(true);
        this.setGoTo('viewAddress');
        this.spinnerService.hide();
      }else{
        this.errorMsgFlag = true;
        this.errorMsg = res;
        this.spinnerService.hide();
      }
    }, error => {
      console.log("inside service error");
      this._globalService.setUpdateSuccess(false);
      this._globalService.setNonResidentFlag(true);
      this._globalService.setIsFromUpdate(true);
      this.errorMsgFlag = true;
      this.errorMsg = "Your address has not been updated successfully.";
      //this.setGoTo('viewAddress');
      this.spinnerService.hide();
    
    })
  }


  setAddressPayload() {
    this.addressFinalListFlag = false;
    this.resAdd = this._globalService.getResAdd();
    this.mailAdd = this._globalService.getMailAdd();
    this.registrationData = this._globalService.getRegistrationData();
    this.addressDataObj = this.registrationData['RegistrationResponse'].readRegistrationResponse;
    console.log(this.resAdd, "inside set payload");
    if (!(this.resAdd.country == 'USA' || this.resAdd.country == '')) {
      this.resForeignCity = this.resAdd['city'];
      this.resForeginState = this.resAdd['state'];
      if (this.resAdd['zip4']) {
        this.resForeignPostal = this.resAdd['zipcode'].concat(this.resAdd['zip4']);
        this.resPostalCode="00000-0000";
      } else {
        this.resForeignPostal = this.resAdd['zipcode'];
        this.resPostalCode="00000-0000";
      }
      this.resForeignCountry = this.resAdd['country'];
    } else {
      this.resCity = this.resAdd['city'];
      this.resState = this.resAdd['state'];
      this.resCountry = this.resAdd['country'];
      if (this.resAdd['zip4']) {
        this.resPostalCode = this.resAdd['zipcode']+'-'+this.resAdd['zip4'];
      } else {
        this.resPostalCode = this.resAdd['zipcode']+'-'+"0000";
      }
    }
    if (!(this.mailAdd.country == 'USA' || this.mailAdd.country == '')) {
      this.mailForeignCity = this.mailAdd['city'];
      this.mailForeginState = this.mailAdd['state'];
      if (this.mailAdd['zip4']) {
        this.mailForeignPostal = this.mailAdd['zipcode'].concat(this.mailAdd['zip4']);
        this.mailPostalCode = "00000-0000"; 
      } else {
        this.mailForeignPostal = this.mailAdd['zipcode'];
        this.mailPostalCode = "00000-0000"; 
      }
      this.mailForeignCountry = this.mailAdd['country'];
    } else {
      this.mailCity = this.mailAdd['city'];
      this.mailState = this.mailAdd['state'];
      this.mailCountry = this.mailAdd['country'];
      if (this.mailAdd['zip4']) {
        this.mailPostalCode = this.mailAdd['zipcode']+'-'+this.mailAdd['zip4'];
      } else {
        this.mailPostalCode = this.mailAdd['zipcode']+'-'+"0000";
      }
    }
    return this.addressData =
      {
        "RegistrationRequest": {
          "updateRegistrationRequest": {
            "addressrules": "Y",
            "contactrules": "N",
            "paymentmethodrules": "N",
            "fedexrules": "N",
            "statetaxrules": "N",
            "addressfinalist": this.addressFinalListFlag === false ? "N" : "Y",
            "residentstreetline1": this.resAdd['address1'],
            "residentstreetline2": this.resAdd['address2'],
            "residentstreetline3": this.resAdd['address3'],
            "residentcity": this.resCity,
            "residentstate": this.resState,
            "residentpostalcode": this.resPostalCode,
            "foreigncountryname": this.resForeignCountry,
            "foreignprovincename": [
              {
                "name": this.resForeginState
              }],
            "foreigncityname": this.resForeignCity,
            "foreignmailcode": this.resForeignPostal,
            "requeststreetLine1": this.mailSameAsResident === false ? this.mailAdd['address1']: "",
            "requeststreetLine2": this.mailSameAsResident === false ? this.mailAdd['address2'] : "",
            "requeststreetLine3": this.mailSameAsResident === false ? this.mailAdd['address3'] : "",
            "requestcity": this.mailSameAsResident === false ? this.mailCity: "",
            "requeststate":this.mailSameAsResident === false ? this.mailState : "",
            "requestpostalCode": this.mailPostalCode,
            "requestcountryname": this.mailSameAsResident === false ? this.mailForeignCountry:"",
            "requestprovincename": [
              {
                "name": this.mailSameAsResident === false ? this.mailForeginState : ""
              }],
            "requestcityname": this.mailSameAsResident === false ? this.mailForeignCity : "",
            "requestforeignmailcode": this.mailForeignPostal,
            "foreigncountrycode": this.addressDataObj.contactForeignPhone.country.code,
            "foreignphonenumber": "65478-96547775",
            "requestphonenumber": "456-273-1234",
            "emailaddress": this.addressDataObj.email.address,
            "emaildomain": this.addressDataObj.email.domain,
            "bankroutingnumber": this.addressDataObj.pensionAccount.pensionholderBankAccount.routingNumber,
            "bankname": this.addressDataObj.pensionAccount.pensionholderBankAccount.bankName,
            "bankpaymenttype": this.addressDataObj.pensionAccount.preferredPaymentType,
            "bankaccountnumber": this.addressDataObj.pensionAccount.pensionholderBankAccount.accountNumber,
            "bankaccounttype": this.addressDataObj.pensionAccount.pensionholderBankAccount.accountType,
            "bankjointaccountind": this.addressDataObj.pensionAccount.pensionholderBankAccount.isJointAccount,
            "bankjointholderlastname": this.addressDataObj.jointAccountHolder.nameLast,
            "bankjointholderfirstname": this.addressDataObj.jointAccountHolder.nameFirst,
            "bankjointholdermiddlename": this.addressDataObj.jointAccountHolder.nameMiddle,
            "bankjointholderssn": this.addressDataObj.jointAccountHolder.socialSecurityNumber,
            "bankjointholderrelation": this.addressDataObj.jointaccountHolderRel.relationship,
            "fedtaxelectioncode": this.addressDataObj.fedTax.pensionTaxElectionCode,
            "fedtaxexemptionnumber": this.addressDataObj.fedTax.numberOfExemptions,
            "fedtaxfillingstatus": this.addressDataObj.fedTax.filingStatus,
            "fedtaxwithholdingamt": this.addressDataObj.fedTax.withholdingAmount,
            "fedtaxaddnlwithholdingamt": this.addressDataObj.fedTax.additionalWithholdingAmount,
            "statetaxelectioncode": this.addressDataObj.stateTax.pensionTaxElectionCode,
            "statetaxexemption": this.addressDataObj.stateTax.numberOfExemptions,
            "statetaxfillingstatus": this.addressDataObj.stateTax.filingStatus,
            "statetaxwithholdingamt": this.addressDataObj.stateTax.withholdingAmount,
            "statetaxaddlwithholdingamt": this.addressDataObj.stateTax.additionalWithholdingAmount,
            "statetaxwithholdingPCT": this.addressDataObj.stateTax.withholdingPCT,
            "statetaxaddlwithholdingPCT": this.addressDataObj.stateTax.additionalWithholdingPCT,
            "splexemptiondesc1": [
              {
                "description": "",
                "value": ""
              },
              {
                "description": "",
                "value": ""
              }
            ]
          }
        }
      }
    }

 
}
