import { Component, OnInit, ViewChild, AfterViewChecked, HostListener } from '@angular/core';
import { MatSort, MatSortable, MatTableDataSource } from '@angular/material';
import { ActiveScreenService } from '../services/activeScreen/active-screen.service';
import { ApiServiceCall } from '../shared/services/apiCall.service';
import { GlobalService } from '../global.service';
import { Payment, Paymentdeduction } from './payment';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { StoredResponseDataService } from './../services/storedResponseData/stored-response-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Title } from '@angular/platform-browser';
import { utils, write, WorkBook } from 'xlsx';
import { ExcelService } from '../shared/services/ExcelService';
import * as jsPDF from 'jspdf';
import { ErrorCheckService } from '../shared/services/errorCheck.service';
import { environment } from 'src/environments/environment';
import { ApiService } from '../shared/services/apiService.service';

declare var $: any;

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit, AfterViewChecked {

  $: any;
  dropDownYear = [];
  selectedYear;
  //paymentObjLength;
  paymentListLen;
  ddnLen;
  ascn;
  paymentObj;
  paymentHistoryArray = [];
  //paymentDeductionName =  [];
  paymentDeductionArray = [];
  grossAmtSum = 0.00;
  federalAmtSum = 0.00;
  stateAmtSum = 0.00;
  netAmtSum = 0.00;
  otherDeductionSum = 0.0;
  paymentMsg;
  paymentShow: boolean = false;
  count = 0;
  noPaymentFlag: boolean = false;
  userName;
  showValue: boolean = false;
  showFlag: boolean = false;
  paymentDedObj;
  mobile = false;
  chngIcon = true;
  plusIcon = true;
  hideIcon = false;
  safariBrowser = false;
  employees = {
    paymentData: []
  };
  selectedOption = ""
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
 
  
  @HostListener('window:resize', ['$event']) onResize(event) {
    // guard against resize before view is rendered
    if (event.target.innerWidth < 992) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
  constructor(private activeScreen: ActiveScreenService, private _globalService: GlobalService, private apiService: ApiService,
    private apiServiceCall: ApiServiceCall, private storedResponseData: StoredResponseDataService,
    private _router: Router, private spinnerService: Ng4LoadingSpinnerService, private titleService: Title
  /*private excelService: ExcelService*/, private excelService: ExcelService, private errorCheckService: ErrorCheckService) {
    if (window.screen.width < 992) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.excelService = excelService;
  }

  ngOnInit() {
    if (/android/i.test(navigator.userAgent.toLowerCase())) {
      let w: any = window.screen.width;
      let h: any = window.screen.height;
      if (window.devicePixelRatio < 1) {
        w = 'alter' + window.screen.width / window.devicePixelRatio;
        h = 'alter' + window.screen.height / window.devicePixelRatio;
      }
      if ( w < 992) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    } else {
      if (window.innerWidth < 992) {
        this.mobile = true;
       } else {
        this.mobile = false;
       }
    }
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); 
    if(isSafari) {
      this.safariBrowser = true;
      }else{
        this.safariBrowser = false;
      }
    if(this._globalService.getSystemNumber()){
      this.activeScreen.changeMessage('View My Payment History');
      this.titleService.setTitle('Large Case Pensions Payee Services - Payment History');
      this.activeScreen.setTitle('Large Case Pensions Payee Services - Payment History');
      this.userName = this._globalService.getUserName();
      this.storedResponseData.changeUser(this.userName);
      this.dropDownYears();
      this.getPaymentHistory();
    }else{
      setTimeout( () => {
    this.apiServiceCall.getSystemNumber().subscribe(res=>{

      if(res['PensionAccountResponse'].status.statusCode != 0){        
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");                              
      }else{
      this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
      let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
      this._globalService.setSystemNumber(systemNumber);
      this.spinnerService.hide();
    this.activeScreen.changeMessage('View My Payment History');
    this.titleService.setTitle('Large Case Pensions Payee Services - Payment History');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Payment History');
    this.userName = this._globalService.getUserName();
    this.storedResponseData.changeUser(this.userName);
    this.dropDownYears();
    this.getPaymentHistory();
    }});
  },1000);
  }
  }

  ngAfterViewChecked() {
    const lineHeightOfBank = $('.bankInput').css('height');
    $('.bankField').css('line-height', lineHeightOfBank);
    const lineHeightOfState = $('.stateField').css('height');
    $('.stateInput').css('line-height', lineHeightOfState);
    const lineHeightOfFed = $('.fedField').css('height');
    $('.fedInput').css('line-height', lineHeightOfFed);
  }

  dropDownYears() {
    const today = new Date();
    let count = 0;
    this.selectedYear = today.getFullYear();
    let year = today.getFullYear();
    while (count < 4) {
      this.dropDownYear.push(year);
      year = year - 1;
      count = count + 1;
    }
  }
  onSelect(event: any) {
    this.selectedYear = event.target.value;
    this.getPaymentHistory();
  }
  changIcon(index) {
    if ($('.icon' + index).hasClass('fa-plus-square')) {
      $('.icon' + index).removeClass('fa-plus-square');
      $('.icon' + index).addClass('fa-minus-square');
    } else {
      $('.icon' + index).removeClass('fa-minus-square');
      $('.icon' + index).addClass('fa-plus-square');
    }
  }

  getPaymentHistory() {
    let dateStr;
    let yyyy;
    let mm;
    let dd;
    let k = 0;
    let ddnListCard;
    this.spinnerService.show();
    this.apiServiceCall.getPaymentInfo(this.selectedYear).subscribe(res => {
      this.errorCheckService.checkErrors(res["PaymentsResponse"]);
      this.netAmtSum = 0.00;
      this.federalAmtSum = 0.00;
      this.stateAmtSum = 0.00;
      this.otherDeductionSum = 0.00;
      this.grossAmtSum = 0.00;
      this.paymentHistoryArray = [];
      if (res["PaymentsResponse"].status.additionalStatus[0].statusCode === "55") {
        this._router.navigateByUrl("error");
      }
      this.paymentObj = res['PaymentsResponse'].readPaymentsResponse.pymntList;
      this.paymentDedObj = res['PaymentsResponse'].readPaymentsResponse.ddnList;
      this.paymentListLen = res['PaymentsResponse'].readPaymentsResponse.pymntListCard;
      this.ddnLen = res['PaymentsResponse'].readPaymentsResponse.ddnList.length;
      ddnListCard = res['PaymentsResponse'].readPaymentsResponse.ddnListCard;
      for (let i = 0; i < this.paymentListLen; i++) {
        let paymentHistory = {};
        let paymentDeduction = {}
        paymentHistory = new Payment();
        this.paymentShow = true;
        dateStr = this.paymentObj[i].pensionAccount.pensionDistributions[0].effectivePeriod.datetimeAsOf;
        if (!(dateStr == 0)) {
          yyyy = dateStr.substring(0, 4);
          mm = dateStr.substring(4, 6); ///month(4,6)
          dd = dateStr.substring(6, 8);
          paymentHistory['dueDate'] = mm + "/" + dd + "/" + yyyy;
        } else {
          paymentHistory['dueDate'] = '';
        }
        if (this.paymentObj[i].pensionAccount.pensionDistributions[0].checkStatus == "I") {
          paymentHistory['outstandingFlag'] = true;
        }
        paymentHistory['grossAmt'] = this.paymentObj[i].pensionAccount.pensionDistributions[0].grossAmount;
        this.grossAmtSum = this.grossAmtSum + parseFloat(paymentHistory['grossAmt']);
        paymentHistory['federalAmt'] = this.paymentObj[i].pensionAccount.federalWithholding.withholdingAmount;
        this.federalAmtSum = this.federalAmtSum + parseFloat(paymentHistory['federalAmt']);
        paymentHistory['stateAmt'] = this.paymentObj[i].pensionAccount.stateWithholding.withholdingAmount;
        this.stateAmtSum = this.stateAmtSum + parseFloat(paymentHistory['stateAmt']);
        paymentHistory['otherDeductions'] = this.paymentObj[i].pensionAccount.pensionDistributions[0].otherDeductionsAmount;
        this.otherDeductionSum = this.otherDeductionSum + parseFloat(paymentHistory['otherDeductions']);
        for (let j = 0; j < this.ddnLen; j++) {
          if (this.paymentDedObj[j].ddnGrpKey != 0) {
            if (this.paymentDedObj[j].ddnGrpKey == i + 1) {
              paymentDeduction = new Paymentdeduction();
              paymentDeduction['otherDeductionName'] = this.paymentDedObj[k].otherDeductionsDetail.description;
              paymentDeduction['otherDeductionAmount'] = this.paymentDedObj[k].otherDeductionsDetail.amount;
              k++;
              paymentHistory['deductionArray'].push(paymentDeduction);
            }
          }
        }
        if (ddnListCard == '0') {
          this.hideIcon = true;
        }
        paymentHistory['netAmt'] = this.paymentObj[i].pensionAccount.pensionDistributions[0].netAmount;
        this.netAmtSum = this.netAmtSum + parseFloat(paymentHistory['netAmt']);
        paymentHistory['paymentType'] = this.paymentObj[i].pensionAccount.pensionDistributions[0].paymentType;
        paymentHistory['bankName'] = this.paymentObj[i].pensionAccount.pensionholderBankAccount.bankName;
        paymentHistory['check'] = this.paymentObj[i].pensionAccount.pensionDistributions[0].checkNumber;
        this.paymentHistoryArray.push(paymentHistory);
      }
      this.employees.paymentData = [];
      for (var i in this.paymentHistoryArray) {
        var data = this.paymentHistoryArray[i];
        var star = '';
        if(data.outstandingFlag == true){
          star = '*';
        }
        this.employees.paymentData.push({
          dueDate: star + data.dueDate,
          grossAmount: data.grossAmt,
          federalAmt: data.federalAmt,
          stateAmt: data.stateAmt,
          otherDeductions: data.otherDeductions,
          netAmt: data.netAmt,
          paymentType: data.paymentType,
          bankName: data.bankName,
          check: data.check
        })
      }
      this.employees.paymentData.push({
        dueDate: "Grand Total for the Year",
        grossAmount: '',
        federalAmt: '',
        stateAmt: '',
        otherDeductions: '',
        netAmt: '',
        paymentType: '',
        bankName: '',
        check: ''
      });
      this.employees.paymentData.push({
        dueDate: "",
        grossAmount: this.grossAmtSum,
        federalAmt: this.federalAmtSum,
        stateAmt: this.stateAmtSum,
        otherDeductions: this.otherDeductionSum,
        netAmt: this.netAmtSum,
        paymentType: '',
        bankName: '',
        check: ''
      });
      if (this.paymentListLen == 0) {
        this.noPaymentFlag = true;
        this.paymentShow = false;
        this.paymentMsg = "No payments available for the selected year.";
      } else {
        this.paymentShow = true;
        this.noPaymentFlag = false;
      }
      this.spinnerService.hide();
    },
      error => {
        let str = JSON.parse(error["_body"]).moreInformation;
        let errorCode = str.search("0055");
        if (errorCode != -1) {
          this.spinnerService.hide();
          this._router.navigateByUrl("error");
        }
        else {
          this.spinnerService.hide();
          this._router.navigateByUrl("unexpectedErrorPage");
         }
        this.spinnerService.hide();
      })
  }

  sort(order) {
    this.ascn = !this.ascn;
    if (order === 'asc') {
      this.paymentHistoryArray.sort((a: any, b: any) =>
        new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
    } else if (order === 'dsc') {
      this.paymentHistoryArray.sort((a: any, b: any) =>
        new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime());
    }
  }


  showdeductions(value) {
    this.showValue = !this.showValue;
    if (value == 'show') {
      this.showFlag = true;
    } else if (value == 'hide') {
      this.showFlag = false;
    }
  }
generatePDF(){
  let yCordinate = 45;
  var doc = new jsPDF('l');
  if(doc.internal.pageSize.height){
    var pageHeight= doc.internal.pageSize.height;
  }else {
    var pageHeight= doc.internal.pageSize.getHeight();
  }
  doc.setFontSize(12);
  doc.setFontType('bold');
  doc.text('Aetna Large Case Pensions', 12, 10);
  doc.text('Payee Name: ' + this._globalService.getUserName(), 12, 15);
  doc.text('Payment History For the Year: ' + this.selectedYear, 12, 20);
  doc.setFontSize(9);
  doc.text('Due Date', 12, 30);
  doc.text('Gross Amount', 32, 30);
  doc.text('Federal Witholding Amout', 65, 30);
  doc.text('State Witholding Amout', 115, 30);
  doc.text('Other Deductions',160 , 30);
  doc.text('Net Amount', 195, 30);
  doc.text('Payment Type', 220, 30);
  doc.text('Bank Name', 250, 30);
  doc.text('Check #', 273, 30);
  doc.line(12, 25, 285, 25);
  doc.line(12, 35, 285, 35);
  for(let i=0;i<this.paymentHistoryArray.length;i++){
    if(yCordinate>= pageHeight-10){
      yCordinate = 15;
      doc = doc.addPage();
      doc.text(this.paymentHistoryArray[i].dueDate, 12, yCordinate);
      doc.text('$'+(parseFloat(this.paymentHistoryArray[i].grossAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 37, yCordinate);
      doc.text('$'+(parseFloat(this.paymentHistoryArray[i].federalAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 80, yCordinate);
      doc.text('$'+(parseFloat(this.paymentHistoryArray[i].stateAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 132, yCordinate);
      doc.text('$'+(parseFloat(this.paymentHistoryArray[i].otherDeductions).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 170, yCordinate);
      doc.text('$'+(parseFloat(this.paymentHistoryArray[i].netAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 198, yCordinate);
      doc.text(this.paymentHistoryArray[i].paymentType, 227, yCordinate);
      doc.text(this.paymentHistoryArray[i].check, 273, yCordinate);
      if(this.paymentHistoryArray[i].bankName){
      var splitTitle = doc.splitTextToSize(this.paymentHistoryArray[i].bankName, 20);
     for(var c = 0; c < splitTitle.length ; c++){
         doc.text(254, yCordinate, splitTitle[c]);
         yCordinate = yCordinate + 5;
     }
    }
      yCordinate = yCordinate+5;
    }else{
    
    doc.text(this.paymentHistoryArray[i].dueDate, 12, yCordinate);
    doc.text('$'+(parseFloat(this.paymentHistoryArray[i].grossAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 37, yCordinate);
    doc.text('$'+(parseFloat(this.paymentHistoryArray[i].federalAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 80, yCordinate);
    doc.text('$'+(parseFloat(this.paymentHistoryArray[i].stateAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 132, yCordinate);
    doc.text('$'+(parseFloat(this.paymentHistoryArray[i].otherDeductions).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 170, yCordinate);
    doc.text('$'+(parseFloat(this.paymentHistoryArray[i].netAmt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 198, yCordinate);
    doc.text(this.paymentHistoryArray[i].paymentType, 227, yCordinate);
    doc.text(this.paymentHistoryArray[i].check, 273, yCordinate);
    if(this.paymentHistoryArray[i].bankName){
    var splitTitle = doc.splitTextToSize(this.paymentHistoryArray[i].bankName, 20);
   for(var c = 0; c < splitTitle.length ; c++){
       doc.text(254, yCordinate, splitTitle[c]);
       yCordinate = yCordinate + 5;
   }
  }
    yCordinate = yCordinate+5;
  }
}
  doc.line(12, yCordinate, 285, yCordinate);
  doc.setFontSize(10);
  doc.text("Grand Total for the Year", 12, yCordinate+5);
  yCordinate = yCordinate+5;
  doc.setFontSize(9);
  doc.text('$'+(this.grossAmtSum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')), 37, yCordinate+10);
  doc.text('$'+(this.federalAmtSum).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 80, yCordinate+10);
  doc.text('$'+(this.stateAmtSum).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 132, yCordinate+10);
  doc.text('$'+(this.otherDeductionSum).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 170, yCordinate+10);
  doc.text('$'+(this.netAmtSum).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 198, yCordinate+10);
  doc.line(12, yCordinate+15, 285, yCordinate+15);
  console.log(typeof(this.selectedYear));
  var yyear = this.selectedYear.toString().slice(-2);
  doc.save('Payment History'+yyear+'.pdf');
}

  generateCSV() {
    let file;
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: false,
      EOL: "\r\n",
      BOM: "\ufeff",
      headers: ['   Due Date ', '   Gross Amount  ', 'Federal Withholding Amount', 'State Withholding Amount', 'Other Deductions',
        'Net Amount', 'Payment Type', 'Bank Name', 'Check']
    };
    file = new Angular2Csv(this.employees.paymentData, 'Payment History', options);
    //this.ExcelService.exportAsExcelFile(this.employees.paymentData, 'Payment History');
    }

    generateExcel(){
      let title = 'app';
      const ws_name = 'SomeSheet';
      const wb: WorkBook = { SheetNames: [], Sheets: {} };
      const ws: any = utils.json_to_sheet(this.employees.paymentData);
      const headers = ['   Due Date ', '   Gross Amount  ', 'Federal Withholding Amount', 'State Withholding Amount', 'Other Deductions',
        'Net Amount', 'Payment Type', 'Bank Name', 'Check #']
      wb.SheetNames.push(ws_name);
      wb.Sheets[ws_name] = ws;
      var wscols = [{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15}];
      ws['!cols'] = wscols;
      const wbout = write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary', cellStyles:true  });

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xFF;
        };
        return buf;
      }
      this.excelService.exportAsExcelFile(this.employees.paymentData, 'Payment History'+ this.selectedYear.toString().slice(-2), headers, this._globalService.getUserName(), this.selectedYear);
    }

    generateXlsx(table, name){
                var uri = 'data:application/vnd.ms-excel;base64,'
                    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                    , base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }
                    , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
               
                    if (!table.nodeType) { table = document.getElementById(table) }
                    var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
                    var blob = new Blob([format(template, ctx)]);
                    var blobURL = window.URL.createObjectURL(blob);

                    if (this.ifIE()) {
                      let  csvData = table.innerHTML;
                        if (window.navigator.msSaveBlob) {
                            var blob = new Blob([format(template, ctx)], {
                                type: "text/html"
                            });
                            navigator.msSaveBlob(blob, '' + name + '.xlsx');
                        }
                    }
                    else
                    window.location.href = uri + base64(format(template, ctx))
                

            
    }
    ifIE() {
      var isIE11 = navigator.userAgent.indexOf(".NET CLR") > -1;
      var isIE11orLess = isIE11 || navigator.appVersion.indexOf("MSIE") != -1;
      return isIE11orLess;
  }

    downloadAsChanged(selectedOption){
      switch(selectedOption){
        case "XLSX file":
        this.generateExcel();
        break;
        case "PDF file":
        this.generatePDF();
        break;
        case "SafariBrowser":
        this.generateXlsx("testTable", "test Table");
        break;
      }
    }
    
  }