import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ApiServiceCall } from '../shared/services/apiCall.service';
import { StateElection } from './stateElection';
import { InformationComponent } from '../information/information.component';
import { StoredResponseDataService } from '../services/storedResponseData/stored-response-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import {ActiveScreenService} from './../services/activeScreen/active-screen.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {
  goTo: string;
  stateElectionData;
  stateElection: StateElection;
  electedInFlag: boolean = false;
  electedOutFlag: boolean = false;
  multipleElectionFlag: boolean = false;
  stateEditFlag = false;
  showElectionFlag: boolean;
  withholdingPercentage;
  flatWithholdingAmt;
  addWithholdingPercentage;
  addWithholdingAmt;
  registrationData;
  stateElectionResMsg;
  stateTaxRuleMgFlag : boolean=false;
  updateFlag = false;
  errorFlag = false;
  detailResMsg;
  taxReductionFlag : boolean =false;
  addAmtFlag : boolean =false;
  isUnsuccessMsg = false;
  substituteFlag = false;
  michigenFlag = false;
   sharedLoc = environment.sharedLoc;
  constructor(private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,private activeScreen: ActiveScreenService,
    private storedResponseData: StoredResponseDataService, private spinnerService: Ng4LoadingSpinnerService,
    private route: ActivatedRoute, private _router: Router, private _infoComponent: InformationComponent, private titleService: Title) { }

  ngOnInit() {
    this.activeScreen.changeMessage('View My Information');
    this.titleService.setTitle('Large Case Pensions Payee Services - View State Election');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - View State Election');
    this.stateElection = this._globalService.getStateElection();
    this.displayStateElection();
    //this.getWithHoldingData();

  }

  displayStateElection() {
      this.spinnerService.show();
     /* if(this._globalService.getCommonflag() === true){
        this.updateFlag = this._globalService.getUpdateStateStatus();
        this._globalService.setUpdateStateStatus(false);
        this._globalService.setStateErrorFlag(false);
        this.loadData();
        this.spinnerService.hide();
      }else{*/
    this.apiServiceCall.getAddressInfo().subscribe(addressData => {
      this.updateFlag = this._globalService.getUpdateStateStatus();
      //this.errorFlag = this._globalService.getStateErrorFlag();
      this._globalService.setUpdateStateStatus(false);
      this._globalService.setStateErrorFlag(false);
      this._globalService.setRegistrationData(addressData);
      this.loadData();
      this.spinnerService.hide();
    },
      error => {
        let str = JSON.parse(error["_body"]).moreInformation;
        let errorCode = str.search("0055");
        if (errorCode != -1) {
          this.spinnerService.hide();
          this.spinnerService.hide();
          this._router.navigateByUrl("error");
        }
        else {
          this.spinnerService.hide();
          this._router.navigateByUrl("unexpectedErrorPage");
         }
      });
    //}
  }
  //}

  loadData() {
    let editShow;
    this.registrationData = this._globalService.getRegistrationData();
    if(this.registrationData["RegistrationResponse"].status.additionalStatus[0].statusCode === "55"){
      
      this.spinnerService.hide();
      this._router.navigateByUrl("error");
     }
    //this.apiServiceCall.getStateElection().subscribe(registrationData => {
    this.stateElectionData = this.registrationData['RegistrationResponse'].readRegistrationResponse;
    editShow = this.stateElectionData.stateTaxRules.isEnabled;
    if (editShow == 1) {
      this.substituteFlag = true;
      this.stateEditFlag = true;
    } else if (editShow == 2) {
      this.michigenFlag = true;
      this.stateEditFlag = true;
    } else if (editShow == 'Y') {
      this.stateEditFlag = false;
    } else {
      this.stateEditFlag = true;
    }
    if (!(this.stateElectionData.stateTaxRules.message == "Service completed successfully.")){
    this._globalService.setStateEditMsg(this.stateElectionData.stateTaxRules.message);
    }
    // username
    const individualFirstName = this.stateElectionData.individual.nameFirst;
    const individualLastName = this.stateElectionData.individual.nameLast;
    const individualName = individualFirstName + ' ' + individualLastName;
    this.storedResponseData.changeUser(individualName);

    ///Elected IN
    if (this.stateElectionData.stateTaxRules.currentValue == "Y") {
      if (this.stateElectionData.stateTax.pensionTaxElectionCode == "O") { 
        this.electedOutFlag = true;
        this.stateElection['taxState'] = this.stateElectionData.stateTax.stateNameFull;
        this.stateElection['stateWithholdingStatus'] = "Elected Out";
      } else if (this.stateElectionData.stateTax.pensionTaxElectionCode == "L") {//locked out state
        this.multipleElectionFlag = true;
      } else if (this.stateElectionData.stateTax.pensionTaxElectionCode == "I") { ///change with "O" if (this.stateElectionData.stateTax.pensionTaxElectionCode == "I")
        this.electedInFlag = true;
        this.stateElection['taxState'] = this.stateElectionData.stateTax.stateNameFull;
        this.stateElection['stateWithholdingStatus'] = "Elected In";
        this.stateElection['maritalStatus'] =this.stateElectionData.stateTax.filingStatus;
        this.stateElection['noOfExemptions'] = this.stateElectionData.stateTax.numberOfExemptions;
        this.stateElection['withholdingPercentage']='';
        this.stateElection['flatWithholdingAmt']='';
        this.stateElection['addWithholdingAmt']='';
        this.stateElection['addWithholdingPercentage']='';
       /* this.stateElection['dropdownLabel1'] ='';
        this.stateElection['dropdownLabel2']='';
        this.stateElection['dropdownLabel3']='';
        this.stateElection['dropdownLabel4']='';
        this.stateElection['dropdownLabel1Value'] ='';
        this.stateElection['dropdownLabel2Value']='';
        this.stateElection['dropdownLabel3Value']='';
        this.stateElection['dropdownLabel4Value']='';*/
        //this.stateElection['noOfExemptions'] = this.stateElectionData.stateTax.numberOfExemptions;
        //this.stateElection['withholdingPercentage'] = this.stateElectionData.stateTax.withholdingPCT;
        //this.withholdingPercentage = this.stateElection['withholdingPercentage'].concat("% Per Payment");
        /*if (this.stateElectionData.stateTax.withholdingPCT == 0) {
          this.stateElection['flatWithholdingAmt'] = this.stateElectionData.stateTax.withholdingAmount;
          this.flatWithholdingAmt = "$ " + this.stateElection['flatWithholdingAmt'].concat(" Per Payment");
          this.stateElection['withholdingPercentage'] = '';
        } else if(this.stateElectionData.stateTax.withholdingPCT == 1){
          this.stateElection['withholdingPercentage'] = this.stateElectionData.stateTax.withholdingPCT;
          this.withholdingPercentage = this.stateElection['withholdingPercentage'].concat("% Per Payment");
          this.stateElection['flatWithholdingAmt'] = '';
        }else{
          this.stateElection['flatWithholdingAmt'] = '';
          this.stateElection['withholdingPercentage'] = '';
        }
        //this.stateElection['flatWithholdingAmt'] = this.stateElectionData.stateTax.withholdingAmount;
        //this.flatWithholdingAmt = "$ " + this.stateElection['flatWithholdingAmt'].concat(" Per Payment");
        this.addAmtFlag = true;
        if (this.stateElectionData.stateTax.additionalWithholdingPCT == 0) {
          this.addAmtFlag = true;
          this.stateElection['addWithholdingAmt'] = this.stateElectionData.stateTax.additionalWithholdingAmount;
          this.addWithholdingAmt = "$ " + this.stateElection['addWithholdingAmt'].concat(" Per Payment");
          this.stateElection['addWithholdingPercentage'] = '';
        } else if(this.stateElectionData.stateTax.additionalWithholdingPCT == 1){
          this.stateElection['addWithholdingPercentage'] = this.stateElectionData.stateTax.additionalWithholdingPCT;
          this.addWithholdingPercentage = this.stateElection['addWithholdingPercentage'].concat("% Per Payment");
          this.stateElection['addWithholdingAmt'] = '';
        }else{
          this.stateElection['addWithholdingAmt'] = '';
          this.stateElection['addWithholdingPercentage'] = '';
        }
        //this.stateElection['addWithholdingPercentage'] = this.stateElectionData.stateTax.additionalWithholdingPCT;
       // this.addWithholdingPercentage = this.stateElection['addWithholdingPercentage'].concat("% Per Payment");
        //this.stateElection['addWithholdingAmt'] = this.stateElectionData.stateTax.additionalWithholdingAmount;
        //this.addWithholdingAmt = "$ " + this.stateElection['addWithholdingAmt'].concat(" Per Payment");
        */if (this.stateElectionData.specialExemptions[0]) {
          this.stateElection['dropdownLabel1'] = this.stateElectionData.specialExemptions[0].description;
          this.stateElection['dropdownLabel1Value'] = this.stateElectionData.specialExemptions[0].value;
        }
        if (this.stateElectionData.specialExemptions[1]) {
          this.stateElection['dropdownLabel2'] = this.stateElectionData.specialExemptions[1].description;
          this.stateElection['dropdownLabel2Value'] = this.stateElectionData.specialExemptions[1].value;
        }
        if (this.stateElectionData.specialExemptions[2]) {
          this.stateElection['dropdownLabel3'] = this.stateElectionData.specialExemptions[2].description;
          this.stateElection['dropdownLabel3Value'] = this.stateElectionData.specialExemptions[2].value;
        }
        if (this.stateElectionData.specialExemptions[3]) {
          this.stateElection['dropdownLabel4'] = this.stateElectionData.specialExemptions[3].description;
          this.stateElection['dropdownLabel4Value'] = this.stateElectionData.specialExemptions[3].value;
        }
      } else {
        console.log(this.stateElectionData.stateTax);
        this.stateElection['taxState'] = this.stateElectionData.stateTax.stateNameFull;
        this.stateElection['stateWithholdingStatus'] = "Elected In";
        this.stateElection['maritalStatus'] = this.stateElectionData.stateTax.filingStatus; //filling status =marital status
        this.stateElection['noOfExemptions'] = this.stateElectionData.stateTax.numberOfExemptions;
        if (this.stateElectionData.stateTax.pensionTaxElectionCode == "F") {
          this.showElectionFlag = true;
          this.stateElection['addWithholdingAmt'] = '';
          this.stateElection['addWithholdingPercentage'] = '';
          if (this.stateElectionData.stateTax.withholdingPCT == 0) {
            this.stateElection['flatWithholdingAmt'] = this.stateElectionData.stateTax.withholdingAmount;
            this.flatWithholdingAmt = "$ " + this.stateElection['flatWithholdingAmt'].concat(" Per Payment");
            this.stateElection['withholdingPercentage'] = '';
          } else if(this.stateElectionData.stateTax.withholdingPCT == 1){
            this.stateElection['withholdingPercentage'] = this.stateElectionData.stateTax.withholdingAmount;
            this.withholdingPercentage = this.stateElection['withholdingPercentage'].concat("% Per Payment");
            this.stateElection['flatWithholdingAmt'] = '';
          }else{
            this.stateElection['flatWithholdingAmt'] = '';
            this.stateElection['withholdingPercentage'] = '';
          }
           //dropDown 1-4
          if (this.stateElectionData.specialExemptions[0]) {
            this.stateElection['dropdownLabel1'] = this.stateElectionData.specialExemptions[0].description;
            this.stateElection['dropdownLabel1Value'] = this.stateElectionData.specialExemptions[0].value;
          }
          if (this.stateElectionData.specialExemptions[1]) {
            this.stateElection['dropdownLabel2'] = this.stateElectionData.specialExemptions[1].description;
            this.stateElection['dropdownLabel2Value'] = this.stateElectionData.specialExemptions[1].value;
          }
          if (this.stateElectionData.specialExemptions[2]) {
            this.stateElection['dropdownLabel3'] = this.stateElectionData.specialExemptions[2].description;
            this.stateElection['dropdownLabel3Value'] = this.stateElectionData.specialExemptions[2].value;
          }
          if (this.stateElectionData.specialExemptions[3]) {
            this.stateElection['dropdownLabel4'] = this.stateElectionData.specialExemptions[3].description;
            this.stateElection['dropdownLabel4Value'] = this.stateElectionData.specialExemptions[3].value;
          }
        } else if (this.stateElectionData.stateTax.pensionTaxElectionCode == "A") {
          this.showElectionFlag = true;
          this.stateElection['flatWithholdingAmt'] = '';
          this.stateElection['withholdingPercentage'] = '';          
          if (this.stateElectionData.stateTax.additionalWithholdingPCT == 0) {
            this.addAmtFlag = true;
            this.stateElection['addWithholdingAmt'] = this.stateElectionData.stateTax.additionalWithholdingAmount;
            this.addWithholdingAmt = "$ " + this.stateElection['addWithholdingAmt'].concat(" Per Payment");
            this.stateElection['addWithholdingPercentage'] = '';
          } else if(this.stateElectionData.stateTax.additionalWithholdingPCT == 1){
            this.stateElection['addWithholdingPercentage'] = this.stateElectionData.stateTax.additionalWithholdingAmount;
            this.addWithholdingPercentage = this.stateElection['addWithholdingPercentage'].concat("% Per Payment");
            this.stateElection['addWithholdingAmt'] = '';
          }else{
            this.stateElection['addWithholdingAmt'] = '';
            this.stateElection['addWithholdingPercentage'] = '';
          }
            //dropDown 1-4
          if (this.stateElectionData.specialExemptions[0]) {
            this.stateElection['dropdownLabel1'] = this.stateElectionData.specialExemptions[0].description;
            this.stateElection['dropdownLabel1Value'] = this.stateElectionData.specialExemptions[0].value;
          }
          if (this.stateElectionData.specialExemptions[1]) {
            this.stateElection['dropdownLabel2'] = this.stateElectionData.specialExemptions[1].description;
            this.stateElection['dropdownLabel2Value'] = this.stateElectionData.specialExemptions[1].value;
          }
          if (this.stateElectionData.specialExemptions[2]) {
            this.stateElection['dropdownLabel3'] = this.stateElectionData.specialExemptions[2].description;
            this.stateElection['dropdownLabel3Value'] = this.stateElectionData.specialExemptions[2].value;
          }
          if (this.stateElectionData.specialExemptions[3]) {
            this.stateElection['dropdownLabel4'] = this.stateElectionData.specialExemptions[3].description;
            this.stateElection['dropdownLabel4Value'] = this.stateElectionData.specialExemptions[3].value;
          }
        } else if (this.stateElectionData.stateTax.pensionTaxElectionCode == "R") {
          this.showElectionFlag = true;
          this.taxReductionFlag =true;
          this.stateElection['taxReductionFlag'] = "R";
          this.stateElection['addWithholdingAmt'] = this.stateElectionData.stateTax.additionalWithholdingAmount;
          this.addWithholdingAmt = "-$ " + this.stateElection['addWithholdingAmt'].concat(" Per Payment");
          this.stateElection['flatWithholdingAmt'] = '';
          this.stateElection['withholdingPercentage'] = '';
          this.stateElection['addWithholdingPercentage'] = '';
          if (this.stateElectionData.specialExemptions[0]) {
            this.stateElection['dropdownLabel1'] = this.stateElectionData.specialExemptions[0].description;
            this.stateElection['dropdownLabel1Value'] = this.stateElectionData.specialExemptions[0].value;
          }
          if (this.stateElectionData.specialExemptions[1]) {
            this.stateElection['dropdownLabel2'] = this.stateElectionData.specialExemptions[1].description;
            this.stateElection['dropdownLabel2Value'] = this.stateElectionData.specialExemptions[1].value;
          }
          if (this.stateElectionData.specialExemptions[2]) {
            this.stateElection['dropdownLabel3'] = this.stateElectionData.specialExemptions[2].description;
            this.stateElection['dropdownLabel3Value'] = this.stateElectionData.specialExemptions[2].value;
          }
          if (this.stateElectionData.specialExemptions[3]) {
            this.stateElection['dropdownLabel4'] = this.stateElectionData.specialExemptions[3].description;
            this.stateElection['dropdownLabel4Value'] = this.stateElectionData.specialExemptions[3].value;
          }
        } else {
          this.stateTaxRuleMgFlag = true;
          this.stateElection['taxState'] = this.stateElectionData.stateTax.stateNameFull;
         // this.stateElectionResMsg = this.stateElectionData.stateTaxRules.message;
        }
      }
    } else {
      // pensionelectionCode= "";
      this.stateTaxRuleMgFlag = true;
          this.stateElection['taxState'] = this.stateElectionData.stateTax.stateNameFull;
          //this.stateElectionResMsg = this.stateElectionData.stateTaxRules.message;
    }
    ///edit message
    if (this.stateElectionData.stateTaxRules.message !== 'Service completed successfully.') {
      this.isUnsuccessMsg = true;
      this.detailResMsg= '';
      this.stateElection['unsuccessMsg'] = this.stateElectionData.stateTaxRules.message;
    }
    //service error message
    if(!(this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail == "Service completed successfully.")){
      this.detailResMsg = this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail;
      this.stateElection['unsuccessMsg'] = '';
      this.isUnsuccessMsg = false;
    }
    this._globalService.setStateElection(this.stateElection);
    console.log(this.stateElection);
  }

  setGoTo(value) {
    this._globalService.setGoTo(value);
    this._infoComponent.goTo = this._globalService.getGoTo();
  }

}
