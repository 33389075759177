import { Component, OnInit } from '@angular/core';
import { NondiscriminationNoticeService } from '../services/nondiscrimination-notice.service';
import { GlobalService } from '../../global.service';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-nondiscrimination-notice',
  templateUrl: './../views/nondiscrimination-notice.component.html',
  styleUrls: ['./../views/nondiscrimination-notice.component.scss'],
  providers: [NondiscriminationNoticeService]
})
export class NondiscriminationNoticeComponent implements OnInit {
  nondiscriminationNoticeData :any= [];
  registrationData;
  getUsernameData;
  qaEnv;
  private sharedLoc = environment.sharedLoc;
  constructor(private _nondiscriminationNoticeService: NondiscriminationNoticeService,
    private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private storedResponseData: StoredResponseDataService, private titleService: Title,
    private spinnerService: Ng4LoadingSpinnerService, private _router: Router, private activeScreen: ActiveScreenService) { }

  ngOnInit() {
    this.titleService.setTitle('Large Case Pensions Payee Services - Nondiscrimination Notice');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Nondiscrimination Notice');
    this._nondiscriminationNoticeService.getNondiscriminationNoticeData()
    .add(resNondiscriminationData => this.nondiscriminationNoticeData = resNondiscriminationData);
    if (this.sharedLoc === 'https://qamobile.aetna.com/appConfig/PensionPortal') {
      this.qaEnv = true;
    } else {
      this.qaEnv = false;
    }
    const individualName = this._globalService.getUserName();
    this.storedResponseData.changeUser(individualName);
  }
  checkArray(parameter) {
    return parameter instanceof Array;
  }
}
