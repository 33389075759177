import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class NondiscriminationNoticeService {
  private _textUrl: string = '../../pensionportalUI/assets/textsData/nondiscrimination-notice.json';
  constructor(private _http: HttpClient) { }
    getNondiscriminationNoticeData() {
        return this._http.get(this._textUrl)
        .subscribe((response: Response) => response);
    }
}
