import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../global.service';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './../views/contact-us.component.html',
  styleUrls: ['./../views/contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsData = [];
  contentData = [];
  registrationData;
  getUsernameData;
  qaEnv;
  private sharedLoc = environment.sharedLoc;

  constructor(private activeScreen: ActiveScreenService,
    private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private storedResponseData: StoredResponseDataService, private _router: Router, private titleService: Title) { }

  ngOnInit() {
    this.activeScreen.changeMessage('Contact Us');
    this.titleService.setTitle('Large Case Pensions Payee Services - Contact Us');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Contact Us');
    if (this.sharedLoc === 'https://qamobile.aetna.com/appConfig/PensionPortal') {
      this.qaEnv = true;
    } else {
      this.qaEnv = false;
    }
    const individualName = this._globalService.getUserName();
    this.storedResponseData.changeUser(individualName);
  }
}
