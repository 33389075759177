export class StateElection {
    taxState: string;
    stateWithholdingStatus: string;
    maritalStatus: string;
    noOfExemptions: string;
    withholdingPercentage: string;
    flatWithholdingAmt: string;
    addWithholdingPercentage: string;
    addWithholdingAmt: string;
    dropdownLabel1?: string;
    dropdownLabel2?: string;
    dropdownLabel3?: string;
    dropdownLabel4?: string;
    dropdownLabel1Value?: string;
    dropdownLabel2Value?: string;
    dropdownLabel3Value?: string;
    dropdownLabel4Value?: string;
    unsuccessMsg: string;
    taxReductionFlag? : string;
}