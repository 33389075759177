import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class StoredResponseDataService {

  private username = new BehaviorSubject<string>('');
  currentUser = this.username.asObservable();

  constructor() {}

  changeUser(message: string) {
    this.username.next(message);
  }
}
