import { RouterModule } from '@angular/router';
import { TermsOfUseComponent } from './components/terms-of-use.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [TermsOfUseComponent],
    imports: [CommonModule],
    exports: [],
    bootstrap: [TermsOfUseComponent]
})
export class TermsOfUseModule { }
