
import {AbstractControl} from '@angular/forms';
export class EmailValidation {

    static MatchEmail(AC: AbstractControl) {
       let newEmail = AC.get('newEmail').value; // to get value in input tag
       let retypeEmail = AC.get('retypeEmail').value; // to get value in input tag
        if(newEmail != retypeEmail) {
            AC.get('retypeEmail').setErrors( {MatchEmail: true} )
        } else {
            return null
        }
    }
}