import { Component, OnInit, HostListener } from '@angular/core';
import { Contact } from './contact';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EmailValidation } from '../../equalValueValidator';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { HttpClient } from '@angular/common/http';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { GlobalService } from '../../global.service';
import { Address } from '../update/address';
import { InformationComponent } from '../information.component';
import { UpdateComponent } from '../update/update.component';
import { RootObject, ReadRegistrationResponse } from './contact.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Title } from '@angular/platform-browser';
import { ErrorCheckService } from '../../shared/services/errorCheck.service';

declare var $: any;

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  addressData = {};
  contactData: {};
  getRegistrationData;
  mask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phoneMask = [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  contact = new Contact();
  registrationData: any;
  private mobile = false;
  contactInfo: Contact;
  contactForm: FormGroup;
  goTo;
  currentEmail = '';
  phone = '';
  foreignPhone = '';
  updateSuccess = false;
  updateEmailSuccess = false;
  updateUnsuccess = false;
  isUnsuccessMsg = false;
  updateUnsuccessMsg;
  unsuccessMsg;
  countrySplitValue = [];
  contactPayload;
  countrySplitValue1;
  emailAddress;
  emailDomain;
  emailValue = [];
  editButtonFlag: boolean = false;
  resendButtonFlag: boolean = false;
  emailResendFlag: boolean = false;
  emailResendFailureFlag: boolean = false;
  emailResendMsg;
  accountState;
  oldEmailAddress;
  oldEmailPayload;
  ctInfoSaveClicked: boolean = false;
  // get registraion data
  regdata: any;
  @HostListener('window:resize', ['$event']) onResize(event) {
    // guard against resize before view is rendered
    if (event.target.innerWidth < 992) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
  constructor(private _contactForm: FormBuilder, private apiCallService: ApiServiceCall, private _infoComponent: InformationComponent,
    private _globalService: GlobalService, private spinnerService: Ng4LoadingSpinnerService, private _router: Router,
    private activeScreen: ActiveScreenService, private authSer: AuthService, private titleService: Title, private errorCheck: ErrorCheckService,
    private errorCheckService: ErrorCheckService) {
    if (window.screen.width < 992) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngOnInit() {
    this.titleService.setTitle('Large Case Pensions Payee Services - View Contact Information');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - View Contact Information');
    this.goTo = 'contactInfo';
    this.spinnerService.show();
    this.ctInfoSaveClicked = false;
    this.apiCallService.getSystemNumber().subscribe(res => {


      if (res['PensionAccountResponse'].status.statusCode != 0) {
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");

      } else {
        this.errorCheck.checkErrors(res["PensionAccountResponse"]);
        this.accountState = res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus;
        this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
        let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
        this._globalService.setSystemNumber(systemNumber);
        let accountReason = res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason;
        this._globalService.setAccountStatusChangeReason(accountReason);

        this.contactDataShow();
        this.spinnerService.hide();
      }
    });
    this.updateSuccess = this._globalService.getUpdateSuccess();
    this.updateEmailSuccess = this._globalService.getUpdateSuccess();
  }

  contactDataShow() {
    let editShow;
    this.spinnerService.show();
    this.contactInfo = this._globalService.getContactInfo();
    /*if (this._globalService.getCommonflag() === true) {
      this.loadData();
      this.spinnerService.hide();
    } else {*/
    this.apiCallService.getSystemNumber().subscribe(res => {


      if (res['PensionAccountResponse'].status.statusCode != 0) {
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");

      } else {
        this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
        this.accountState = res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus;
        this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
        let accountReason = res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason;
        this._globalService.setAccountStatusChangeReason(accountReason);
        console.log("contact infor ", this._globalService.getAccountState());
        console.log("system numebr response ", res);
        this.apiCallService.getAddressInfo().subscribe(         
          
          addressData => {
            if (addressData["RegistrationResponse"].status.additionalStatus[0].statusCode === "55") {
              this._router.navigateByUrl("error");
            }
            return new Promise((resolve, reject) => {
              this._globalService.setRegistrationData(addressData);
              resolve();
          this.spinnerService.hide();
            }).then(() => {
              this.loadData();
          this.spinnerService.hide();
            });
            //this.loadData();
            //this.spinnerService.hide();
          } ,
        error => {
            let str = JSON.parse(error["_body"]).moreInformation;
            let errorCode = str.search("0055");
            if (errorCode != -1) {
              this.spinnerService.hide();
              this._router.navigateByUrl("error");
            }
            else {
              this.spinnerService.hide();
              this._router.navigateByUrl("unexpectedErrorPage");
            }
            this.spinnerService.hide();
          });
      }
    });
  }

  setRegistration(addressData) {
    return new Promise((resolve, reject) => {
      this._globalService.setRegistrationData(addressData);
    });
  }

  loadData() {
    let editShow;
    this.registrationData = this._globalService.getRegistrationData();
    this.getRegistrationData = this.registrationData['RegistrationResponse'].readRegistrationResponse;
    editShow = this.getRegistrationData.contactRules.isEnabled;
    console.log(this._globalService.getAccountState());
    if (editShow == "Y") {
      this.editButtonFlag = false;
    } else {
      this.editButtonFlag = true;
    }
    if (this.accountState == "PENDING") {
      this.resendButtonFlag = true;
    }
    if (this.getRegistrationData.email.address && this.getRegistrationData.email.domain) {
      this.contactInfo['currentEmail'] = this.getRegistrationData.email.address + '@' + this.getRegistrationData.email.domain;
    }
    this.oldEmailAddress = this.contactInfo['currentEmail']
    this.contactInfo['phone'] = this.formatPhone(this.getRegistrationData.phone.number.split('-')[0], -3) + '-' +
      this.formatPhone(this.getRegistrationData.phone.number.split('-')[1], -3) + '-' +
      this.formatPhone(this.getRegistrationData.phone.number.split('-')[2], -4);

    const convertForeignPhone = this.formatPhone(this.getRegistrationData.contactForeignPhone.number.split('-')[0], -5) + '-' +
      this.formatPhone(this.getRegistrationData.contactForeignPhone.number.split('-')[1], -8);
    if (this.getRegistrationData.contactForeignPhone.country.code) {
      this.contactInfo['foreignPhone'] = this.formatPhone(this.getRegistrationData.contactForeignPhone.country.code, -3) +
        '-' + convertForeignPhone;
    } else {
      this.contactInfo['foreignPhone'] = convertForeignPhone;
    }
    this.contactInfo['foreignPhone'] = this.formatForeignPhone(this.contactInfo['foreignPhone']);
    if (this.getRegistrationData.contactRules.message !== 'Service completed successfully.') {
      this.isUnsuccessMsg = true;
      this.unsuccessMsg = this.getRegistrationData.contactRules.message;
    }
  }


  formatPhone(phoneNo, length) {
    if (phoneNo.length > 0) {
      return ('0000000000' + phoneNo).slice(length);
    }
    return phoneNo;
  }
  formatForeignPhone(foreignNo) {
    if (foreignNo.length > 0) {
      foreignNo = foreignNo.replace(/-/g, '');
      foreignNo = ('00000000000000000' + foreignNo).slice(-16);
      return foreignNo.substring(0, 3) + '-' + foreignNo.substring(3, 8) + '-' + foreignNo.substring(8, 16);
    }
    return foreignNo;
  }


  editContactInfo() {
    this.titleService.setTitle('Large Case Pensions Payee Services - Update Contact Information');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Update Contact Information');
    this.goTo = 'updateContact';
    this.emailResendFlag = false;
    this.updateSuccess = false;
    this.updateEmailSuccess = false;
    this.contactInfo = this._globalService.getContactInfo();
    this.contactForm = this._contactForm.group({
      currentEmail: [this.contactInfo['currentEmail']],
      newEmail: ['', [Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}')]],
      retypeEmail: ['', [Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}')]],
      phone: [this.contactInfo['phone'], [Validators.pattern('^[0-9]{3}[-]?\s?[0-9]{3}[-]?[0-9]{4}$')]],
      foreignPhone: [this.contactInfo['foreignPhone'], [Validators.pattern('^[0-9]{3}[-]?\s?[0-9]{5}[-]?[0-9]{8}$')]],
    },
      { validator: EmailValidation.MatchEmail });
  }

  save() {
    this.ctInfoSaveClicked = true;
    if (this.contactForm.valid) {
      this.spinnerService.show();
      if (this.contactForm.value.newEmail === '') {
        this.contactInfo['currentEmail'] = this.contactForm.value.currentEmail;
        this.updateEmailSuccess = false;
        this._globalService.setEmailPhoneFlag("PhoneUpdate");
      } else if ((this.contactInfo['currentEmail'] == this.contactForm.value.newEmail) && (this.accountState != "PENDING")) {
        this._globalService.setEmailPhoneFlag("PhoneUpdate");
        this.updateEmailSuccess = false;
      } else {
        this.contactInfo['currentEmail'] = this.contactForm.value.newEmail;
        this.updateEmailSuccess = true;
      }
      this.contactInfo['phone'] = this.contactForm.value.phone;
      this.contactInfo['foreignPhone'] = this.contactForm.value.foreignPhone;
      this.contactPayload = this.setContactPayLoad(this.contactInfo);

      if (this._globalService.getEmailPhoneFlag() != "PhoneUpdate") {
        if (this.oldEmailAddress) {
          this.apiCallService.sendMailToOldUser(this.sendMailToOldRecipient()).subscribe(res => {
            // console.log("mail sent to old user");
            this.contactSave();
          })
        }
      } else {
        this.contactSave();
      }
    } else {
      this.validateAllFields(this.contactForm);
      $('.errorBorder:first').focus();
    }
  }

  contactSave() {
    this.apiCallService.updateContactInfo(this.contactPayload).subscribe(res => {
      this.ctInfoSaveClicked = false;
      if (res === "55") {
        this._router.navigateByUrl("error");
      }
      console.log('success', res);
      if (res !== '0') {
        this.updateUnsuccess = true;
        this.updateUnsuccessMsg = res;
        this.spinnerService.hide();
      } else {
        this.spinnerService.show();
        this.apiCallService.getSystemNumber().subscribe(res => 
          {if (res['PensionAccountResponse'].status.statusCode != 0) {
          //this._globalService.deleteCookie('accessToken');
          sessionStorage.removeItem('accessToken');
          this._router.navigateByUrl("unexpectedErrorPage");
  
        } else {
          // console.log(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
          this.accountState = res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus;
          this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
          let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
          this._globalService.setSystemNumber(systemNumber);
          let accountReason = res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason;
          this._globalService.setAccountStatusChangeReason(accountReason);
          this.spinnerService.hide();

          this.goTo = 'contactInfo';
          this.activeScreen.changeMessage('View My Information');
          this.titleService.setTitle('Large Case Pensions Payee Services - View Contact Information');
          this.activeScreen.setTitle('Large Case Pensions Payee Services - View Contact Information');
          this._globalService.setCommonFlag(false);
          this.contactDataShow();
          this.spinnerService.hide();
          this.updateSuccess = true;
          /* if (this.contactForm.value.newEmail !== '') {
             this.updateEmailSuccess = true;
           }*/
        }}
        );
      }
  }, error => {
    // TODO: need to remove once API starts working
    this.goTo = 'contactInfo';
    
    this.ctInfoSaveClicked = false;
    this.activeScreen.changeMessage('View My Information');
    this.titleService.setTitle('Large Case Pensions Payee Services - View Contact Information');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - View Contact Information');
    let str = JSON.parse(error['_body']).moreInformation;
    let resErrorCode = str.search('67');
    let mailErrorCode = str.search('68');
    if ((resErrorCode != -1) || mailErrorCode != -1) {
      this.spinnerService.hide();
      $('#contactModal');
    } else {
      console.log('inside service error');
      let errorCode = str.search("0055");
      if (errorCode != -1) {
        this.spinnerService.hide();
        this._router.navigateByUrl("error");
      }
      else {
        this.spinnerService.hide();
        this._router.navigateByUrl("unexpectedErrorPage");
       }
      this.spinnerService.hide();
    }
    this.spinnerService.hide();
  });
  }

  setGoTo(value) {
    this._globalService.setGoTo(value);
    this._infoComponent.goTo = this._globalService.getGoTo();
    this.titleService.setTitle('Large Case Pensions Payee Services - View Contact Information');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - View Contact Information');
    this.activeScreen.changeMessage('View My Information');
  }


  setContactPayLoad(contactData) {
    if (!contactData.foreignPhone) {
      contactData.foreignPhone = '000-00000-00000000';
    }
    if (!contactData.phone) {
      contactData.phone = '000-000-0000';
    }
    this.countrySplitValue = contactData.foreignPhone.split('-');
    this.countrySplitValue1 = this.countrySplitValue[0];
    const foriegnPhoneNum = this.countrySplitValue[1] + '-' + this.countrySplitValue[2];
    if (contactData.currentEmail) {
      this.contactInfo['currentEmail'] = contactData.currentEmail;
      this.emailValue = contactData.currentEmail.split('@');
      this.emailAddress = this.emailValue[0];
      this.emailDomain = this.emailValue[1];
    } else {
      this.contactInfo['currentEmail'] = '';
      this.emailAddress = '';
      this.emailDomain = '';
    }
    return this.addressData = {
      'RegistrationRequest': {
        'updateRegistrationRequest': {
          'addressrules': 'N',
          'contactrules': 'Y',
          'paymentmethodrules': 'N',
          'fedexrules': 'N',
          'statetaxrules': 'N',
          'addressfinalist': 'Y',
          'residentstreetline1': '1 MAIN ST',
          'residentstreetline2': 'Test',
          'residentstreetline3': '',
          'residentcity': 'HARTFORD',
          'residentstate': 'CO',
          'residentpostalcode': '06120-1006',
          'foreigncountryname': '',
          'foreignprovincename': [
            {
              'name': ''
            }
          ],
          'foreigncityname': '',
          'foreignmailcode': '',
          'requeststreetLine1': '',
          'requeststreetLine2': '',
          'requeststreetLine3': '',
          'requestcity': '',
          'requeststate': '',
          'requestpostalCode': '06120-1006',
          'requestcountryname': '',
          'requestprovincename': [
            {
              'name': ''
            }
          ],
          'requestcityname': '',
          'requestforeignmailcode': '',
          'foreigncountrycode': this.countrySplitValue1,
          'foreignphonenumber': foriegnPhoneNum,
          'requestphonenumber': contactData.phone,
          'emailaddress': this.emailAddress,
          'emaildomain': this.emailDomain,
          'bankroutingnumber': '',
          'bankname': '',
          'bankpaymenttype': 'D',
          'bankaccountnumber': '',
          'bankaccounttype': '',
          'bankjointaccountind': '',
          'bankjointholderlastname': '',
          'bankjointholderfirstname': '',
          'bankjointholdermiddlename': '',
          'bankjointholderssn': '0',
          'bankjointholderrelation': '',
          'fedtaxelectioncode': 'I',
          'fedtaxexemptionnumber': '3',
          'fedtaxfillingstatus': 'M',
          'fedtaxwithholdingamt': '0.00',
          'fedtaxaddnlwithholdingamt': '0.00',
          'statetaxelectioncode': 'O',
          'statetaxexemption': '0',
          'statetaxfillingstatus': '',
          'statetaxwithholdingamt': '0.00',
          'statetaxaddlwithholdingamt': '0.00',
          'statetaxwithholdingPCT': '0',
          'statetaxaddlwithholdingPCT': '0',
          'splexemptiondesc1': [
            {
              'description': '',
              'value': ''
            },
            {
              'description': '',
              'value': ''
            }
          ]
        }
      }
    }
  }
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  changeSubMenu(menuName) {
    this.activeScreen.changeMessage(menuName);
  }

  resendEmail() {
    //this.authSer.getEmail();
    let payload = {
      "RegistrationRequest": {
        "updateRegistrationRequest": {
          "addressrules": "N",
          "contactrules": "N",
          "paymentmethodrules": "N",
          "fedexrules": "N",
          "statetaxrules": "N",
          "addressfinalist": "Y",
          "residentstreetline1": "1 MAIN STREET",
          "residentstreetline2": "",
          "residentstreetline3": "",
          "residentcity": "HARTFORD",
          "residentstate": "CO",
          "residentpostalcode": "06120-1006",
          "foreigncountryname": "",
          "foreignprovincename": [
            {
              "name": ""
            }
          ],
          "foreigncityname": "",
          "foreignmailcode": "",
          "requeststreetLine1": "",
          "requeststreetLine2": "",
          "requeststreetLine3": "",
          "requestcity": "",
          "requeststate": "",
          "requestpostalCode": "06120-1006",
          "requestcountryname": "",
          "requestprovincename": [
            {
              "name": ""
            }
          ],
          "requestcityname": "",
          "requestforeignmailcode": "",
          "foreigncountrycode": "011",
          "foreignphonenumber": "65478-96547775",
          "requestphonenumber": "456-273-1234",
          "emailaddress": "",
          "emaildomain": this.contactInfo['currentEmail'],
          "bankroutingnumber": "072400052",
          "bankname": "",
          "bankpaymenttype": "E",
          "bankaccountnumber": "852369741258",
          "bankaccounttype": "S",
          "bankjointaccountind": "N",
          "bankjointholderlastname": "PATTERSON",
          "bankjointholderfirstname": "LARRY",
          "bankjointholdermiddlename": "L",
          "bankjointholderssn": "742583569",
          "bankjointholderrelation": "O",
          "fedtaxelectioncode": "I",
          "fedtaxexemptionnumber": "1",
          "fedtaxfillingstatus": "DI",
          "fedtaxwithholdingamt": "0.00",
          "fedtaxaddnlwithholdingamt": "0.00",
          "statetaxelectioncode": "I",
          "statetaxexemption": "1",
          "statetaxfillingstatus": "M",
          "statetaxwithholdingamt": "0.00",
          "statetaxaddlwithholdingamt": "0.00",
          "statetaxwithholdingPCT": "0",
          "statetaxaddlwithholdingPCT": "0",
          "splexemptiondesc1": [
            {
              "description": "",
              "value": ""
            },
            {
              "description": "",
              "value": ""
            }
          ]
        }
      }
    }
    console.log(payload);
    this.apiCallService.resendContactInfo(payload).subscribe(res => {
      console.log(res);
      if (res == "0") {
        this.emailResendFlag = true;
        this.emailResendMsg = "Email Sent Successfully.";
      } else {
        this.emailResendFailureFlag = true;
        this.emailResendMsg = res;
      }
    })
  }

  sendMailToOldRecipient() {
    let email = this.oldEmailAddress;
    this.emailValue = email.split('@');
    let emailAddress = this.emailValue[0];
    let emailDomain = this.emailValue[1];
    return this.oldEmailPayload = {
      "RegistrationRequest": {
        "updateRegistrationRequest": {
          "addressrules": "N",
          "contactrules": "N",
          "paymentmethodrules": "N",
          "fedexrules": "N",
          "statetaxrules": "N",
          "addressfinalist": "Y",
          "residentstreetline1": "1 MAIN STREET",
          "residentstreetline2": "",
          "residentstreetline3": "",
          "residentcity": "HARTFORD",
          "residentstate": "CO",
          "residentpostalcode": "06120-1006",
          "foreigncountryname": "",
          "foreignprovincename": [
            {
              "name": ""
            }
          ],
          "foreigncityname": "",
          "foreignmailcode": "",
          "requeststreetLine1": "",
          "requeststreetLine2": "",
          "requeststreetLine3": "",
          "requestcity": "",
          "requeststate": "",
          "requestpostalCode": "06120-1006",
          "requestcountryname": "",
          "requestprovincename": [
            {
              "name": ""
            }
          ],
          "requestcityname": "",
          "requestforeignmailcode": "",
          "foreigncountrycode": "011",
          "foreignphonenumber": "65478-96547775",
          "requestphonenumber": "456-273-1234",
          "emailaddress": emailAddress,
          "emaildomain": emailDomain,
          "bankroutingnumber": "072400052",
          "bankname": "",
          "bankpaymenttype": "E",
          "bankaccountnumber": "852369741258",
          "bankaccounttype": "S",
          "bankjointaccountind": "N",
          "bankjointholderlastname": "PATTERSON",
          "bankjointholderfirstname": "LARRY",
          "bankjointholdermiddlename": "L",
          "bankjointholderssn": "742583569",
          "bankjointholderrelation": "O",
          "fedtaxelectioncode": "I",
          "fedtaxexemptionnumber": "1",
          "fedtaxfillingstatus": "DI",
          "fedtaxwithholdingamt": "0.00",
          "fedtaxaddnlwithholdingamt": "0.00",
          "statetaxelectioncode": "I",
          "statetaxexemption": "1",
          "statetaxfillingstatus": "M",
          "statetaxwithholdingamt": "0.00",
          "statetaxaddlwithholdingamt": "0.00",
          "statetaxwithholdingPCT": "0",
          "statetaxaddlwithholdingPCT": "0",
          "splexemptiondesc1": [
            {
              "description": "",
              "value": ""
            },
            {
              "description": "",
              "value": ""
            }
          ]
        }
      }
    }
  }
}

