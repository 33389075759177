import { Component, OnInit } from '@angular/core';
import { GlobalService } from './../global.service';
import { ActiveScreenService } from './../services/activeScreen/active-screen.service';
import { AuthService } from './../shared/services/auth.service';
import { HostListener } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-timeout-error-page',
  templateUrl: './timeout-error-page.component.html',
  styleUrls: ['./timeout-error-page.component.scss']
})
export class TimeoutErrorPageComponent implements OnInit {
  pensionErrorFlag = false;

  constructor(private _globalService: GlobalService, private activeScreen: ActiveScreenService, private authService: AuthService) { }

  ngOnInit() {
    this.pensionErrorFlag =  false;
    this.activeScreen.changeMessage('Session Timed Out');
    this._globalService.setPensionErrorFlag(false);
    
  }
  logoutFromApp(){
    //this._globalService.setCookie('logged_in', Date.now().toString());
    localStorage.setItem('logged_in', Date.now().toString());
      $('#mainContainer').removeClass('menuSlide');
      $('body').removeClass('overHide');
      //this._globalService.deleteCookie("accessToken");
      sessionStorage.removeItem("accessToken");
      this._globalService.deleteCookie("id_token");
      this._globalService.deleteCookie("sessionStorage");
      this.authService.getAuthToken();
    }
    
    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      this.logoutFromApp();
    }
}
