import { GetSystemNumberComponent } from './components/getSystemNumber.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationComponent } from '../information/information.component';
import { AuthService } from '../shared/services/auth.service';

@NgModule({
    declarations: [GetSystemNumberComponent],
    imports: [CommonModule],
    exports: [],
    bootstrap: [GetSystemNumberComponent],
    providers:[AuthService]
})
export class GetSystemNumberModule { }
