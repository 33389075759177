import { Router, ActivatedRoute } from '@angular/router';
import { GetSystemNumberService } from './../services/getSystemNumber.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../../shared/services/auth.service'

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { GlobalService } from '../../global.service';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { ErrorCheckService } from '../../shared/services/errorCheck.service';
import { timer } from 'rxjs/internal/observable/timer';
@Component({
  selector: 'app-getSystemNumber',
  templateUrl: './../views/getSystemNumber.component.html',
  styleUrls: ['./../views/getSystemNumber.component.scss'],
  providers: [GetSystemNumberService, Location]
})
export class GetSystemNumberComponent implements OnInit {
  private systemNumber: string;
  individualName;
  constructor(private _getSystemNumberService: GetSystemNumberService, private route: ActivatedRoute, private _router: Router, 
    private authService: AuthService, private spinner: Ng4LoadingSpinnerService, private apiServiceCall: ApiServiceCall,
    private storedResponseData: StoredResponseDataService,  private _globalService: GlobalService,
    private activeScreen: ActiveScreenService, private errorCheckService: ErrorCheckService) {}

  ngOnInit() {
      this.spinner.show();
      this.setTimer();
  }

  navigate(systemNumber) {
    this.systemNumber = systemNumber;
    // console.log(systemNumber);
    //this._globalService.setCookie('systemNumber', systemNumber);
    this._router.navigateByUrl('home');
  }
  getSystemNumber(){
    this.spinner.show();
    this.apiServiceCall.getSystemNumber().subscribe(res=>{
      if(res['PensionAccountResponse'].status.statusCode != 0){        
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");                              
      }else{
      //this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
      // console.log(res,"inside getSystemNumber");
      this.spinner.show();
      if(res["PensionAccountResponse"]){
      this.systemNumber = res["PensionAccountResponse"]["readPensionAccountResponse"]["pensionHolder"]["individualIdentifier"]["idValue"];
     // this._globalService.setCookie('systemNumber', this.systemNumber);
     this._globalService.setSystemNumber(this.systemNumber);
      this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
      this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
      const individualFirstName = res["PensionAccountResponse"]["readPensionAccountResponse"].individual.nameFirst;      ;
      const individualLastName = res["PensionAccountResponse"]["readPensionAccountResponse"].individual.nameLast;
      this.individualName = individualFirstName + ' ' + individualLastName;
      this.storedResponseData.changeUser(this.individualName);
      this._globalService.setUserName(this.individualName);
      this.activeScreen.getUserName(this.individualName);
      //this.spinner.hide();   
    } }
    });
  }
  setTimer(){
    const source = timer(200,1000)
    const subscribe = source.subscribe(
         (x)=>{
            if(sessionStorage.getItem("accessToken")){
              if(!this._globalService.getCookie("systemNumber")){
                this.getSystemNumber();
                this._router.navigateByUrl('home');
              }
              else{
                this._router.navigateByUrl('home');
              }
              subscribe.unsubscribe();
              //this.spinner.hide();
              //this._router.navigateByUrl('home');
            }
        },
        function (err) {
            console.log('Error in timer: ' + err);
        },
        function () {
            console.log('Completed');
        });
    
  }

  

}
