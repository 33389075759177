import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class DownloadPdfServiceService {
  private sharedLoc = environment.sharedLoc;

  constructor(private http: HttpClient) {}
  downloadFile() {
  //   const options = {
  //     headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/pdf'
  //  })
  // };
  return this.http
   .get(this.sharedLoc + '/lcpforms')
    .subscribe(response => {
      // Removed checking of valid response
      // let fileBlob = response.blob();
      // let blob = new Blob([fileBlob], {
      //    type: 'application/pdf' // must match the Accept type
      // });
   });

}


}
