import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insertSpace'
})
export class InsertSpacePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return value.split('').join(' ');
    }
    return value;
  }

}
