import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-error',
  templateUrl: './../views/error.component.html',
  styleUrls: ['./../views/error.component.scss'],
  providers: []
})
export class ErrorComponent implements OnInit {
  constructor() {}
  ngOnInit() {
  }
}
