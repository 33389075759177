import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class PrivacyService {
  private _textUrl: string = '../../pensionportalUI/assets/textsData/privacyCenter.json';
  constructor(private _http: HttpClient) { }
    getPrivacyData() {
        return this._http.get(this._textUrl)
        .subscribe((response: Response) => response);
    }
}
