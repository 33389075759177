import { Injectable } from "@angular/core";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { GlobalService } from '../../global.service';

const MINUTES_UNITL_AUTO_LOGOUT = 15 // in minutes
const CHECK_INTERVAL = 10000 // in miliseconds - (10000 = 1 sec)
const STORE_KEY =  'lastAction';
declare var $: any;
@Injectable()
export class ClientActiveService {
 public getLastAction() {
  //console.log(1);
    return parseInt(this._globalService.getCookie(STORE_KEY));

  }
 public setLastAction(lastAction: number) {
  //console.log(2);
    this._globalService.setCookie(STORE_KEY, lastAction.toString());
  }

  constructor(private spinner: Ng4LoadingSpinnerService, private _router: Router, private activeScreen: ActiveScreenService, private _globalService: GlobalService) {
    this._globalService.setCookie(STORE_KEY,Date.now().toString());    
    this.initListener();
    this.initInterval();
    this.check();
    
  }

  initListener() {
    //console.log(4);
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    //console.log(5);
    this.setLastAction(Date.now());
  }

  initInterval() {
    //console.log(6);
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    //console.log(7);
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    console.log(diff);
    console.log(isTimeout);

    if (isTimeout)  {
      this.spinner.hide();
        this.activeScreen.timeOutSession(true);
        this._router.navigateByUrl("timeoutErrorPage");
    }
  }
}