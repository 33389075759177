import { Component, OnInit } from '@angular/core';
import { DownloadPdfServiceService } from '../services/downloadPdf/download-pdf-service.service';
import { ActiveScreenService } from './../services/activeScreen/active-screen.service';
import { ApiServiceCall } from './../shared/services/apiCall.service';
import { GlobalService } from './../global.service';
import { Title } from '@angular/platform-browser';
import { TaxStatement } from './tax-statements';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FileSaver, saveAs } from 'file-saver/FileSaver';
import { ErrorCheckService } from '../shared/services/errorCheck.service';
declare var $: any;

@Component({
  selector: 'app-tax-statements',
  templateUrl: './tax-statements.component.html',
  styleUrls: ['./tax-statements.component.scss']
})
export class TaxStatementsComponent implements OnInit {
  ascn = true;
  taxStatement = [];
  systemNumber;
  taxErrorFlag: boolean = false;
  taxErrorMsg;
  mobile;
  pdfData;
  constructor(private dwnld: DownloadPdfServiceService, private activeScreen: ActiveScreenService,
    private apiCallService: ApiServiceCall, private _globalService: GlobalService,
    private titleService: Title, private spinnerService: Ng4LoadingSpinnerService,
    private errorCheckService: ErrorCheckService) { }

  ngOnInit() {
    this.activeScreen.changeMessage('View and Print Tax Statements');
    this.titleService.setTitle('Large Case Pensions Payee Services - Tax Statements');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Tax Statements');
    //this.dwnld.downloadFile();
    this.taxErrorFlag = false;
    this.spinnerService.show();
    this.apiCallService.getSystemNumber().subscribe(response => {
      this.errorCheckService.checkErrors(response["PensionAccountResponse"]);
      this.systemNumber = response["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
      setTimeout( () => {
      this.apiCallService.getIncomeData().subscribe(incomeRes => {
        let  ctrNumber = [];
        let  contractNumber = [];
        this.spinnerService.show();
        let incomeDataList = incomeRes['IncomeResponse'].readIncomeResponse.incomeDataListCard;
        //console.log(incomeDataList);
        if(!incomeRes['IncomeResponse'].readIncomeResponse.incomeDataList[0].pensionHolder.otherPensionAccounts[0].accountNumber){
          this.spinnerService.hide();
        }
        for (let j = 0; j < incomeDataList; j++) {
         let ctNumber = incomeRes['IncomeResponse'].readIncomeResponse.incomeDataList[j].pensionHolder.otherPensionAccounts[0].accountNumber; 
         ctrNumber.push(ctNumber); 
        }
        contractNumber = Array.from(new Set(ctrNumber));
        for (let i = this.systemNumber.length; i < 10; i++) {
          this.systemNumber = '0' + this.systemNumber;
        }
        for (let k = 0; k < contractNumber.length; k++) {
          this.taxStatement = [];
          let taxId = '';
          taxId = contractNumber[k] + '  ' + this.systemNumber;
          this.spinnerService.show();
          this.apiCallService.getTaxDetails(taxId).subscribe(res => {
            let commLength;
            let commObj;
            let taxStateObj;
            taxStateObj = this._globalService.getTaxStatement();
            commObj = res['readDocumentsResponse'].communicationContent;
            commLength = commObj.length;
            let currentYear = new Date().getFullYear();
            let currentDate = new Date();
            let taxDate = new Date(currentYear , 0 , 20);
            for (let i = 0; i < commLength; i++) {
              taxStateObj = new TaxStatement();
              if (commObj[i].cMSContent.cmsContentOutput[0] && commObj[i].cMSContent.cmsContentOutput[1]) {
                if(commObj[i].cMSContent.cmsContentOutput[2].infoValues.value !=1){
                let taxYear = commObj[i].cMSContent.cmsContentOutput[1].infoValues.value;
                if(currentDate.getTime() >= taxDate.getTime()){
                  /*
                  E.g.
                  Begin date	  End date	    Tax years
                  2018-08-23    2019-01-19	  2014, 2015, 2016, 2017
                  2019-01-20	  2020-01-19	  2015, 2016, 2017, 2018
                  2020-01-20	  2021-01-19	  2016, 2017, 2018, 2019

                  */ 
                  if(taxYear < currentYear  && taxYear > currentYear-5){
                    // if tax statement year is less than current year and greater than 5 years back year
                    taxStateObj['taxYear'] = taxYear;
                    taxStateObj['taxForm'] = commObj[i].cMSContent.cmsContentOutput[0].infoValues.value;
                    taxStateObj['downloadLink'] = encodeURIComponent(commObj[i].webHyperlink);
                    this.taxStatement.push(taxStateObj);
                  }                 
                }else{
                  if(taxYear < currentYear-1 && taxYear > currentYear-6){
                    //if tax statement year is less than last year and greater than 6 years back year
                    taxStateObj['taxYear'] = taxYear;
                    taxStateObj['taxForm'] = commObj[i].cMSContent.cmsContentOutput[0].infoValues.value;
                    taxStateObj['downloadLink'] = encodeURIComponent(commObj[i].webHyperlink);
                    this.taxStatement.push(taxStateObj);
                  }
                }   
                this.spinnerService.hide();
              }
              }else{
                this.taxErrorFlag = true;
                this.taxErrorMsg = "No tax statement available";
                console.log(this.taxErrorMsg,this.taxErrorFlag);
                this.spinnerService.hide();
              }
            }
            console.log("tax statement",this.taxStatement,"outside");
            this.sort('dsc');
            this.spinnerService.hide();
          }, error => {
            console.log("error");
            this.taxErrorFlag = true;
            this.taxErrorMsg = "Tax statements are not available at this time. Please try again later.";
            this.spinnerService.hide();
          });
        }
      }) 
    },2000);
    })
  }

  base64ToArrayBuffer(pdfContent) {
    var binaryString = window.atob(pdfContent);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };


  downloadTaxForm(link,formType) {
    this.spinnerService.show();
         ///for desktop
      this.apiCallService.getTaxDocument(link).subscribe(
        res => {
        if (res['statusCode'] == 200) {
          if (window.screen.width < 1024) { // for mobile and ipad
            let pdfContent = (res['documentContent']);
            var arrBuffer = this.base64ToArrayBuffer(pdfContent);
            var blob = new Blob([arrBuffer], { type: "application/pdf" });
            saveAs(blob, formType+" Tax Statement.pdf");
            this.spinnerService.hide();
          }else{
            let pdfContent = (res['documentContent']);
            var arrBuffer = this.base64ToArrayBuffer(pdfContent);
            var newBlob = new Blob([arrBuffer], { type: "application/pdf" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob,formType+" Tax Statement.pdf");
              this.spinnerService.hide();
              return;
            }
            var file = new Blob([arrBuffer], {type: 'application/pdf'});
            var filename = formType+" Tax Statement.pdf";
            //var tabWindow = window.open('', '_blank');
            /*var a = tabWindow.document.createElement('a');
            //a.textContent = 'Loading ' + "tax statement" + '..';
           tabWindow.document.body.appendChild(a);
            tabWindow.document.body.style.cursor = 'wait';
            tabWindow.document.body.style.cursor = 'auto';*/
            //a.download = filename;
            var url: string =  window.URL.createObjectURL(file); //a.href =
           /* a.click();
            a.download = formType+" Tax Statement.pdf";*/

            //var win = window.open(URL.createObjectURL(file)); win.onload = function(){ this.document.title = 'someFile.pdf';}

            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();        

              document.body.removeChild(a);
             //url.revokeObjectURL(url);

           /* let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfContent) + "'></iframe>")*/
            this.spinnerService.hide();
          }
        } else {
          this.taxErrorFlag = true;
          //this.taxErrorMsg = res['moreInformation'].reasonText;
          this.taxErrorMsg = "No tax statement available";
          console.log(this.taxErrorMsg,this.taxErrorFlag);
          this.spinnerService.hide();
        }
      }, error => {
        console.log("error");
        this.spinnerService.hide();
      });
  }



  sort(order) {
    let taxYearArray = []
    this.ascn = !this.ascn;
    if (order === 'asc') {
      for (let i = 0; i < this.taxStatement.length - 1; i++) {
        for (let j = i + 1; j < this.taxStatement.length; j++) {
          if (parseInt(this.taxStatement[i].taxYear) > parseInt(this.taxStatement[j].taxYear)) {
            let swap = this.taxStatement[i];
            this.taxStatement[i] = this.taxStatement[j];
            this.taxStatement[j] = swap;
          }
        }
      }      
    } else if (order === 'dsc') {
      for (let i = 0; i < this.taxStatement.length - 1; i++) {
        for (let j = i + 1; j < this.taxStatement.length; j++) {
          if (parseInt(this.taxStatement[i].taxYear) < parseInt(this.taxStatement[j].taxYear)) {
            let swap = this.taxStatement[i];
            this.taxStatement[i] = this.taxStatement[j];
            this.taxStatement[j] = swap;
          }
        }
      }

      for (let i = 0; i < this.taxStatement.length - 1; i++) {
        for (let j = i + 1; j < this.taxStatement.length; j++) {
          if (parseInt(this.taxStatement[i].taxYear) === parseInt(this.taxStatement[j].taxYear)) {
         var check= this.taxStatement[i].taxForm.localeCompare(this.taxStatement[j].taxForm)
          if(check == 1){
            let swap = this.taxStatement[i];
            this.taxStatement[i] = this.taxStatement[j];
            this.taxStatement[j] = swap;
          }/*else if(check == -1){
            console.log(check,this.taxStatement[i].taxForm,this.taxStatement[j].taxForm);
          }*/
          }
        }
      }
       /* this.taxStatement.sort(function(a, b) {
          if(a.taxYear === b.taxYear){
            console.log(a.taxYear,"tax year check",b.taxYear);
            if(b.taxForm > a.taxForm){
              return -1;
            }else if (b.taxForm<a.taxForm){
              return 1;
            }else{
              return 0;
            }
          }                    
      })*/

      console.log("inside taxstatement",this.taxStatement); 
    }
  }
}
