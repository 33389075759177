import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FederalElection } from '../federalElection';
import { GlobalService } from '../../global.service';
import { InformationComponent } from '../../information/information.component';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import {ActiveScreenService} from './../../services/activeScreen/active-screen.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'update-fedral',
  templateUrl: './update-fedral.component.html',
  styleUrls: ['./update-fedral.component.scss']
})
export class UpdateFedralComponent implements OnInit {

  federalEditMsg; 
  goTo: string;
  federalElection: FederalElection;
  fedForm: FormGroup;
  showFederalElectionFlag: boolean;
  electedInFlag: boolean = false;
  federalPayload;
  payload;
  registrationData;
  addressDataObj;
  errorMsg = '';
  flatWithholdingAmt;
   /*change for dynamic form */
  witholdingData: any[] = [];  
  form: any[];
  updateFedForm: FormGroup;
  group = {};
  displayList: any[] = [];
  invalidForm: boolean = false;
  fedSaveClicked: boolean = false;


  constructor(private _formBuilder: FormBuilder, private _globalService: GlobalService,
    private _infoComponent: InformationComponent, private apiServiceCall: ApiServiceCall, private _http: HttpClient,
    private spinnerService: Ng4LoadingSpinnerService, private activeScreen: ActiveScreenService, private titleService: Title) { }


  ngOnInit() {
    this.activeScreen.changeMessage('Update My Information');
    this.titleService.setTitle('Large Case Pensions Payee Services - Update Fedral Election');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Update Fedral Election');
    this.federalElection = this._globalService.getFederalElection();
   
    this.federalEditMsg = this._globalService.getFederalEditMsg();
   
    this._globalService.setFederalEditMsg('');
    this.updateFedralForm(); 
    history.pushState(null, null, location.href);
    $(window).on('popstate', function (event) {
      history.go(1);
      $('#backBtnModal').modal();
    });
  }
 
  updateFedralForm() {
    this.spinnerService.show();
    this.witholdingData = this._globalService.getFedWithholdingData();
    this.witholdingData.forEach(inputTemplate => {
      if ((inputTemplate.isMultiple == true || inputTemplate.isMultiple == "true")  && (inputTemplate.displayFieldDropDown.length > 0)) {       
          inputTemplate.displayFieldType = 'select'; }        
          this.group[inputTemplate.seqNumber] = new FormControl('');    
    })
    this.showFederal();
  }
  showFederal() {
    this.federalElection = this._globalService.getFederalElection();
    if (this.federalElection.federalWithholdingStatus === "Elected In") {
      this.showFederalElectionFlag = true;
    } else {
      this.electedInFlag = true;
    }
    /* Withholding - FormGroup */
    this.fedForm = new FormGroup({
      federalWithholdingStatus: new FormControl(this.federalElection.federalWithholdingStatus),
      electedOptions: new FormControl(this.electedInFlag),
      updateFedForm: new FormGroup(this.group)
    });

    //console.log("update fed form", this.fedForm.controls.updateFedForm);
    this.spinnerService.hide();
  }
  /* Elect In - displayList  for response */
  displayListElectIn() {
    this.displayList.length = 0;
    for (let displayItem of this.witholdingData) {
      var parts = {};
      parts['description'] = displayItem.displayField;
      parts['value'] = displayItem.seqNumber;
      this.displayList.push(parts);
    }
  }
  /* Elect Out - displayList  for response */
  displayListElectOut() {
    this.displayList.length = 0;
    for (let displayItem of this.witholdingData) {
      var parts = {};
      if (displayItem.displayFieldType == "INTEGER") {
        parts['description'] = '0';
      }
      else if (displayItem.displayFieldType == "DECIMAL") {
        parts['description'] = '0.0';
      }
      else {
        parts['description'] = '';
      }
      parts['value'] = displayItem.seqNumber;
      this.displayList.push(parts);
    }
  }

  save() {     
      this.fedSaveClicked = true;
      this.setFederalFieldFromUI();
      this.payload = this.federalPayloadStructure();
      console.log(this.payload)
      this.spinnerService.show();
      this.apiServiceCall.updateFederal(this.payload).subscribe(res => {
        console.log(res);
        if (res == 0) {
          this._globalService.setCommonFlag(false);
          this._globalService.setUpdateFederalStatus(true);
          this._globalService.setFederalErrorFlag(false);
          this.setGoTo('federalElec');
          this.spinnerService.hide();
          this.fedSaveClicked = false;
        } else {
          this.errorMsg = res;
          this._globalService.setFederalErrorFlag(true);
          this._globalService.setUpdateFederalStatus(false);
          this.federalElection = this._globalService.getFederalElection();
          this.spinnerService.hide();
          this.fedSaveClicked = false;
        }
      })       
  }
  setFederalFieldFromUI() {
    this.federalElection = new FederalElection();
    if (this.fedForm.get('electedOptions').value === false) {
      this.federalElection['federalWithholdingStatus'] = "I";
      this.displayListElectIn();
    } else {
      this.federalElection['federalWithholdingStatus'] = "O";
      this.displayListElectOut();

    }
    console.log("data coming from federal edit UI", this.federalElection);
  }
 
  setGoTo(value) {
    this._globalService.setGoTo(value);
    this._infoComponent.goTo = this._globalService.getGoTo();
  }
  electedOptions(e) {
    if (!e.target.checked) {
      $('#checkboxModal').modal('show');
    } else {
      $('#checkboxModal2').modal('show');
    }
  }
  changeCheckboxStatus() {
    this.showFederalElectionFlag = false;
    this.fedForm.patchValue({
      'electedOptions': true
    });
  }
  changeCheckboxStatus2() {
    this.showFederalElectionFlag = true;
    this.fedForm.patchValue({
      'electedOptions': false
    });
  }
  showElectOutField() {
    this.showFederalElectionFlag = true;
    this.fedForm.patchValue({
      'electedOptions': false
    });
  }

  showElectInField() {
    this.showFederalElectionFlag = false;   
    this.errorMsg = '';
    this.fedForm.patchValue({
      'electedOptions': true
    });
  }
  federalPayloadStructure() {
    this.registrationData = this._globalService.getRegistrationData();
    this.addressDataObj = this.registrationData['RegistrationResponse'].readRegistrationResponse;
    console.log(this.federalElection);
    return {
      "RegistrationRequest": {
        "updateRegistrationRequest": {
          "addressrules": "N",
          "contactrules": "N",
          "paymentmethodrules": "N",
          "fedexrules": "Y",
          "statetaxrules": "N",
          "addressfinalist": "N",
          "residentstreetline1": this.addressDataObj.residentAddress.streetLine1,
          "residentstreetline2": this.addressDataObj.residentAddress.streetLine2,
          "residentstreetline3": this.addressDataObj.residentAddress.streetLine3,
          "residentcity": this.addressDataObj.residentAddress.city,
          "residentstate": "CT",
          "residentpostalcode": "06120-1006",
          "foreigncountryname": this.addressDataObj.residentForeignAddress.country.fullName,
          "foreignprovincename": [
            {
              "name": ""
            }
          ],
          "foreigncityname": this.addressDataObj.residentForeignAddress.city,
          "foreignmailcode": this.addressDataObj.residentForeignAddress.postalCode,
          "requeststreetLine1": this.addressDataObj.mailingAddress.streetLine1,
          "requeststreetLine2": this.addressDataObj.mailingAddress.streetLine2,
          "requeststreetLine3": this.addressDataObj.mailingAddress.streetLine3,
          "requestcity": this.addressDataObj.mailingAddress.city,
          "requeststate": "CT",
          "requestpostalCode": "06120-1006",
          "requestcountryname": "",
          "requestprovincename": [
            {
              "name": ""
            }
          ],
          "requestcityname": this.addressDataObj.mailingForeignAddress.city,
          "requestforeignmailcode": "00000000",
          "foreigncountrycode": this.addressDataObj.contactForeignPhone.country.code,
          "foreignphonenumber": "12345-74474744",
          "requestphonenumber": "456-273-1234",
          "emailaddress": this.addressDataObj.email.address,
          "emaildomain": this.addressDataObj.email.domain,
          "bankroutingnumber": this.addressDataObj.pensionAccount.pensionholderBankAccount.routingNumber,
          "bankname": this.addressDataObj.pensionAccount.pensionholderBankAccount.bankName,
          "bankpaymenttype": this.addressDataObj.pensionAccount.preferredPaymentType,
          "bankaccountnumber": this.addressDataObj.pensionAccount.pensionholderBankAccount.accountNumber,
          "bankaccounttype": this.addressDataObj.pensionAccount.pensionholderBankAccount.accountType,
          "bankjointaccountind": this.addressDataObj.pensionAccount.pensionholderBankAccount.isJointAccount,
          "bankjointholderlastname": this.addressDataObj.jointAccountHolder.nameLast,
          "bankjointholderfirstname": this.addressDataObj.jointAccountHolder.nameFirst,
          "bankjointholdermiddlename": this.addressDataObj.jointAccountHolder.nameMiddle,
          "bankjointholderssn": this.addressDataObj.jointAccountHolder.socialSecurityNumber,
          "bankjointholderrelation": this.addressDataObj.jointaccountHolderRel.relationship,
          "fedtaxelectioncode": this.federalElection['federalWithholdingStatus'],
          "fedtaxexemptionnumber": "0",
          "fedtaxfillingstatus": this.federalElection['federalWithholdingStatus'] =="O" ? "":"M",
          "fedtaxwithholdingamt": "0.00",
          "fedtaxaddnlwithholdingamt": "0.00",
          "displayListCount": this.witholdingData.length,          
          "displayList": this.displayList,
          "statetaxelectioncode": "O",
          "statetaxexemption": "0",
          "statetaxfillingstatus": "",
          "statetaxwithholdingamt": "0.00",
          "statetaxaddlwithholdingamt": "0.00",
          "statetaxwithholdingPCT": "0",
          "statetaxaddlwithholdingPCT": "0",
          "splexemptiondesc1": [
            {
              "description": "",
              "value": ""
            },
            {
              "description": "",
              "value": ""
            }
          ]
        }
      }
    }

  }
}
