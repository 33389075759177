import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NondiscriminationNoticeComponent } from './components/nondiscrimination-notice.component';


@NgModule({
    declarations: [NondiscriminationNoticeComponent],
    imports: [CommonModule],
    exports: [],
    bootstrap: [NondiscriminationNoticeComponent],
    providers: [HttpClient, HttpClientModule]
})
export class NondiscriminationNoticeModule { }
