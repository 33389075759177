import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';
import { ApiServiceCall } from '../shared/services/apiCall.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FederalElection } from './federalElection';
import { Router, ActivatedRoute } from '@angular/router';
import { InformationComponent } from '../information/information.component';
import { StoredResponseDataService } from '../services/storedResponseData/stored-response-data.service';


import { ActiveScreenService } from '../services/activeScreen/active-screen.service';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-federal',
  templateUrl: './federal.component.html',
  styleUrls: ['./federal.component.scss']
})
export class FederalComponent implements OnInit {
  fedAddWithholdingAmt;
  showUpdatePanel: boolean = false;
  federalElectionData;
  federalElection: FederalElection;
  electedInFlag: boolean = false;
  electedOutFlag: boolean = false;;
  noChangeElection: boolean = false;
  multipleElectionFlag: boolean = false;
  
  showElectionFlag: boolean;
  registrationData;
  federalElectionResMsg;
  fedTaxRuleMgFlag : boolean =false;
  detailResMsg;
  federalMsg;
  flatAmt;
  addAmt;
  displayListCard: number;
  witholdingData: any[];
  updateSuccess : boolean = false;
  updateErrorMsg : boolean = false;
  isUnsuccessMsg : boolean =false;
  constructor(private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private storedResponseData: StoredResponseDataService, private spinnerService: Ng4LoadingSpinnerService,
    private route: ActivatedRoute, private _router: Router, private _infoComponent: InformationComponent,
    private activeScreen: ActiveScreenService, private titleService: Title,private _http: HttpClient) {
  }

  ngOnInit() {
    this.activeScreen.changeMessage('View My Information');
    this.titleService.setTitle('Large Case Pensions Payee Services - View Fedral Election');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - View Fedral Election');
   this.federalElection = this._globalService.getFederalElection();  
    this.getWithHoldingData();
  }


  getWithHoldingData() {
    let a: any;
    let b: any;
    this.spinnerService.show();
    this.apiServiceCall.getWithholdingData().subscribe(res => {
      this.witholdingData = res['WithholdingsResponse'].readWithHoldingsResponse.displayList;
      this.displayListCard = res['WithholdingsResponse'].readWithHoldingsResponse.displayListCard;
      this.witholdingData.sort((a, b) => parseInt(a.seqNumber) - parseInt(b.seqNumber));       
      this.witholdingData = this.witholdingData.slice(0, this.displayListCard );              
      this.displayFederalElection();       
      this.spinnerService.hide();
    })
  }

  displayFederalElection() {

    this.spinnerService.show();    
    if(this._globalService.getCommonflag() === true){    
      this.updateSuccess=this._globalService.getUpdateFederalStatus();    
      this._globalService.setUpdateFederalStatus(false);
      this._globalService.setFederalErrorFlag(false);
      this.loadData();
      this.spinnerService.hide();
    }else{
    this.apiServiceCall.getAddressInfo().subscribe(addressData => {
    if(addressData["RegistrationResponse"].status.additionalStatus[0].statusCode === "55"){
        this._router.navigateByUrl("error");
       }
      this.updateSuccess=this._globalService.getUpdateFederalStatus();
      this.updateErrorMsg = this._globalService.getFederalErrorFlag();
      this._globalService.setUpdateFederalStatus(false);
      this._globalService.setFederalErrorFlag(false);
      this._globalService.setRegistrationData(addressData);
      this.loadData();
      this.spinnerService.hide();
    } ,
      error => {
        let str = JSON.parse(error["_body"]).moreInformation;
        let errorCode = str.search("0055");
        if (errorCode != -1) {
          this.spinnerService.hide();
          this._router.navigateByUrl("error");
        }
        else {
          this.spinnerService.hide();
          this._router.navigateByUrl("unexpectedErrorPage");
         }
      });
    }
  }

  loadData() {
    this.registrationData = this._globalService.getRegistrationData();
    this.federalElectionData = this.registrationData['RegistrationResponse'].readRegistrationResponse;
    if(!(this.federalElectionData.fedexRules.message == "Service completed successfully.")){
      this.federalMsg = this.federalElectionData.fedexRules.message;
    this._globalService.setFederalEditMsg(this.federalElectionData.fedexRules.message);
    //this.isUnsuccessMsg = true;
    }
   if(this.federalElectionData.fedexRules.isEnabled == "N"){
      this.isUnsuccessMsg = true;
    }
    // username
    const individualFirstName = this.federalElectionData.individual.nameFirst;
    const individualLastName = this.federalElectionData.individual.nameLast;
    const individualName = individualFirstName + ' ' + individualLastName;
    this.storedResponseData.changeUser(individualName);

       ///Elected Out
      if (this.federalElectionData.fedTax.pensionTaxElectionCode == "O") {
        this.electedOutFlag = true;
        if(!(this.federalElectionData.fedexRules.message == "Service completed successfully.")){
          this.federalMsg=this.federalElectionData.fedexRules.message;
          this.detailResMsg = '';
        }else if(!(this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail == "Service completed successfully.")){
          this.detailResMsg = this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail;
          this.federalMsg = '';
        }else{
          this.detailResMsg = '';
          this.federalMsg = '';
        }
        this.federalElection['federalWithholdingStatus'] = "Elected Out";
        /*this.federalElection['fedMaritalStatus'] = '';
        this.federalElection['fedNoOfExemptions']="0";
        this.federalElection['flatWithholdingAmt']="0";
        this.federalElection['fedAddWithholdingAmt']="0";*/
      } else if (this.federalElectionData.fedTax.pensionTaxElectionCode == "L") {//locked out federal
        this.multipleElectionFlag = true;
      } else if (this.federalElectionData.fedTax.pensionTaxElectionCode == "I") { ///change with "O" if (this.federalElectionData.fedTax.pensionTaxElectionCode == "I")
        this.electedInFlag = true;
        if(!(this.federalElectionData.fedexRules.message == "Service completed successfully.")){
          this.federalMsg=this.federalElectionData.fedexRules.message;
          this.detailResMsg = '';
        }else if(!(this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail == "Service completed successfully.")){
          this.detailResMsg = this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail;
          this.federalMsg = '';
        }else{
          this.detailResMsg = '';
          this.federalMsg = '';
        }
        this.federalElection['federalWithholdingStatus'] = "Elected In";
     
      } else {
        if (this.federalElectionData.fedTax.pensionTaxElectionCode == "F") {
          this.showElectionFlag = true;
          if(!(this.federalElectionData.fedexRules.message == "Service completed successfully.")){
            this.federalMsg=this.federalElectionData.fedexRules.message;
            this.detailResMsg = '';
          }else if(!(this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail == "Service completed successfully.")){
            this.detailResMsg = this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail;
            this.federalMsg = '';
          }else{
            this.detailResMsg = '';
            this.federalMsg = '';
          }
          this.federalElection['federalWithholdingStatus'] = "Elected In";          
        }
         else if (this.federalElectionData.fedTax.pensionTaxElectionCode == "A") {
          this.showElectionFlag = true;
          if(!(this.federalElectionData.fedexRules.message == "Service completed successfully.")){
            this.federalMsg=this.federalElectionData.fedexRules.message;
            this.detailResMsg = '';
          }else if(!(this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail == "Service completed successfully.")){
            this.detailResMsg = this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail;
            this.federalMsg = '';
          }else{
            this.detailResMsg = '';
            this.federalMsg = '';
          }
          this.federalElection['federalWithholdingStatus'] = "Elected In";          
        }else {
          this.fedTaxRuleMgFlag = true;        
          if(!(this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail == "Service completed successfully.")){
            this.detailResMsg = this.registrationData['RegistrationResponse'].status.additionalStatus[0].detail;
            //this.federalElectionResMsg = this.federalElectionData.fedexRules.message;
            this.federalMsg = '';
          }else if(!(this.federalElectionData.fedexRules.message == "Service completed successfully.")){
            //this.federalElectionResMsg = this.federalElectionData.fedexRules.message;
            this.detailResMsg = '';
            this.federalMsg = this.federalElectionData.fedexRules.message;;
          }else{
            //this.federalElectionResMsg = "No Data Available";
            this.detailResMsg = '';
            this.federalMsg = ''; 
          }
        }
      } 
    this._globalService.setFedWithholdingData(this.witholdingData);  
    this._globalService.setFederalElection(this.federalElection);
    // console.log(this.federalElection);
  }
  setGoTo(value) {
    this._globalService.setGoTo(value);
    this._infoComponent.goTo = this._globalService.getGoTo();
  }
 changeSubMenu(menuName) {
    this.activeScreen.changeMessage(menuName);
  }

}
