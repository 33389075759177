import { Component, OnInit, HostListener } from '@angular/core';
import { ActiveScreenService } from '../services/activeScreen/active-screen.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  goTo;
  mobile;
  currentPwd = true;
  newPwd = true;
  retypePwd = true;
  answer = true;

  usernameForm: FormGroup;
  passwordForm: FormGroup;
  securityQueForm: FormGroup;

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth < 992) {
     this.mobile = true;
    } else {
     this.mobile = false;
    }
  }
  constructor(private activeScreen: ActiveScreenService,
    private _formBuilder: FormBuilder, private titleService: Title) {
    if (window.screen.width < 992) {
      this.mobile = true;
     } else {
      this.mobile = false;
     }
   }

  ngOnInit() {
    this.activeScreen.changeMessage('Profile');
    this.titleService.setTitle('Large Case Pensions Payee Services - Profile');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Profile');
    this.goTo = 'passwordForm';

    this.usernameForm = this._formBuilder.group({
      currentUsername: ['dummy@mail.com', [Validators.required]],
      newUsername: ['', [Validators.required]],
      retypeUsername: ['', [Validators.required]]
    });
    this.passwordForm = this._formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      retypePassword: ['', [Validators.required]]
    });
    this.securityQueForm = this._formBuilder.group({
      currentPassword: ['', [Validators.required]],
      question: ['', [Validators.required]],
      answer: ['', [Validators.required]]
    });
  }

  tabClick(getFormId) {
    this.usernameForm.reset();
    this.passwordForm.reset();
    this.securityQueForm.reset();
    this.goTo =  getFormId;
  }


}
