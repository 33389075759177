export class PaymentMethod {
    sendMyPeriodPaymentTo: string;
    bankName: string;
    routingNo: number;
    accNo: number;
    accType: string;
    jointAccHolder: string;
    jointAccHolderFName: string;
    jointAccHolderLName: string;
    jointAccHolderSSNMask: string;
    jointAccHolderSSN: string;
    jointAccHolderRelationship: string;
    bankAddress: string;
    bankCity: string;
    bankState: string;
    bankZip: string;
    multiPayDiffPayMethods: boolean;
    multiPayDiffMethodsMsg: string;
}
