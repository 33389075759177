import { NgModule, EventEmitter, Output, AfterContentChecked } from '@angular/core';
import { RouterModule, Router } from '@angular/router';

import { Component, OnInit, HostListener } from '@angular/core';
import { GlobalService } from '../global.service';

import { ActiveScreenService } from '../services/activeScreen/active-screen.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ApiServiceCall } from './../shared/services/apiCall.service';

declare var $: any;
@NgModule({
  declarations: [],
  imports: []
})
@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit, AfterContentChecked {  
  @Output() loaded = new EventEmitter();

  ngAfterViewInit() {
    this.loaded.emit();
  } 
  mobile = false;
  registrationData;
  stateElectionDisp: boolean = false;
  selectedInd = 0;
  goTo = this._globalService.getGoTo();
  @HostListener('window:resize', ['$event']) onResize(event) {
    // guard against resize before view is rendered
    if (event.target.innerWidth < 992) {
      this.mobile = true;
    } else {
      this.mobile = false;
     }
     $('.mat-tab-label-container').addClass('container');
    $('.mat-tab-list').addClass('row');
    $('.mat-tab-labels').addClass('col-lg-12 col-md-12 col-sm-12 clearPadding');
   }
   constructor(private _globalService: GlobalService, private activeScreen: ActiveScreenService,
    private apiServiceCall: ApiServiceCall, private spinnerService: Ng4LoadingSpinnerService, private _router: Router,) {}

   ngOnInit() {
    let flag;
    this.activeScreen.changeMessage('View My Information');
    $('.mat-tab-label-container').addClass('container');
    $('.mat-tab-list').addClass('row');
    $('.mat-tab-labels').addClass('col-lg-12 col-md-12 col-sm-12 clearPadding');

    if (/android/i.test(navigator.userAgent.toLowerCase())) {
      let w: any = window.screen.width;
      let h: any = window.screen.height;
      if (window.devicePixelRatio < 1) {
        w = 'alter' + window.screen.width / window.devicePixelRatio;
        h = 'alter' + window.screen.height / window.devicePixelRatio;
      }
      if ( w < 992) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    } else {
      if (window.innerWidth < 992) {
        this.mobile = true;
       } else {
        this.mobile = false;
       }
    }
    this.spinnerService.show();
    setTimeout( () => {
    this.apiServiceCall.getSystemNumber().subscribe(res => {
      
      if(res['PensionAccountResponse'].status.statusCode != 0){        
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");
        
      }else{
      this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
      this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
      let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
      this._globalService.setSystemNumber(systemNumber);
      this.spinnerService.hide();
      return new Promise((resolve, reject) => {
        this.verifyUser();
        resolve();
        this.spinnerService.hide();
        }).then(()=>{
        //this.registrationData = this._globalService.getRegistrationData();
     /* if (this.registrationData) {
        console.log(this.registrationData['RegistrationResponse'].readRegistrationResponse.stateTaxRules.currentValue);
        flag = this.registrationData['RegistrationResponse'].readRegistrationResponse.stateTaxRules.currentValue;
        if (flag == "N") {
          this.stateElectionDisp = true;
        } else {
          this.stateElectionDisp = false;
        }
      } else {*/
        this.apiServiceCall.getAddressInfo().subscribe(res => {
          this._globalService.setRegistrationData(res);
          flag = res['RegistrationResponse'].readRegistrationResponse.stateTaxRules.currentValue;
          if (flag == "N") {
            this.stateElectionDisp = true;
          } else {
            this.stateElectionDisp = false;
          }
          this.goTo = 'viewAddress'; /// viewAddress stateElec
         // this.spinnerService.hide();
        },
        error => {
          let str=JSON.stringify(error["moreInformation"]);          
          console.log(str);
          let errorCode=str.search("401");
         if(errorCode != -1 ){
         //this._globalService.deleteCookie('accessToken');
         sessionStorage.removeItem('accessToken');
         this._globalService.deleteCookie("sessionStorage");
         }
         this.goTo = 'viewAddress'; /// viewAddress stateElec
         this.spinnerService.hide();
       });
     // }
        this.spinnerService.hide();
        });
      
      
    }});
  }, 2000);
  }

  verifyUser(){
    if (this._globalService.getCookie('email_ID')) {
      console.log("verify user", this._globalService.getAccountStatusChangeReason());
      if (this._globalService.getAccountStatusChangeReason() != "CONFIRMD") {
        this.verifyEmail();
      } else {
        this._globalService.deleteCookie("email_ID");
      }
    } else {
      this._globalService.deleteCookie("email_ID");
    }
  }

  accountStatusReason;
  verifyEmail() {
    //this.authSer.getEmail();
    let payload = {
      "RegistrationRequest": {
        "updateRegistrationRequest": {
          "addressrules": "N",
          "contactrules": "N",
          "paymentmethodrules": "N",
          "fedexrules": "N",
          "statetaxrules": "N",
          "addressfinalist": "Y",
          "residentstreetline1": "1 MAIN STREET",
          "residentstreetline2": "",
          "residentstreetline3": "",
          "residentcity": "HARTFORD",
          "residentstate": "CO",
          "residentpostalcode": "06120-1006",
          "foreigncountryname": "",
          "foreignprovincename": [
            {
              "name": ""
            }
          ],
          "foreigncityname": "",
          "foreignmailcode": "",
          "requeststreetLine1": "",
          "requeststreetLine2": "",
          "requeststreetLine3": "",
          "requestcity": "",
          "requeststate": "",
          "requestpostalCode": "06120-1006",
          "requestcountryname": "",
          "requestprovincename": [
            {
              "name": ""
            }
          ],
          "requestcityname": "",
          "requestforeignmailcode": "",
          "foreigncountrycode": "011",
          "foreignphonenumber": "65478-96547775",
          "requestphonenumber": "456-273-1234",
          "emailaddress": "",
          "emaildomain": this._globalService.getCookie('email_ID'),
          "bankroutingnumber": "072400052",
          "bankname": "",
          "bankpaymenttype": "E",
          "bankaccountnumber": "852369741258",
          "bankaccounttype": "S",
          "bankjointaccountind": "N",
          "bankjointholderlastname": "PATTERSON",
          "bankjointholderfirstname": "LARRY",
          "bankjointholdermiddlename": "L",
          "bankjointholderssn": "742583569",
          "bankjointholderrelation": "O",
          "fedtaxelectioncode": "I",
          "fedtaxexemptionnumber": "1",
          "fedtaxfillingstatus": "DI",
          "fedtaxwithholdingamt": "0.00",
          "fedtaxaddnlwithholdingamt": "0.00",
          "statetaxelectioncode": "I",
          "statetaxexemption": "1",
          "statetaxfillingstatus": "M",
          "statetaxwithholdingamt": "0.00",
          "statetaxaddlwithholdingamt": "0.00",
          "statetaxwithholdingPCT": "0",
          "statetaxaddlwithholdingPCT": "0",
          "splexemptiondesc1": [
            {
              "description": "",
              "value": ""
            },
            {
              "description": "",
              "value": ""
            }
          ]
        }
      }
    }
    this.spinnerService.show();
    console.log(this._globalService.getAccountStatusChangeReason());
    this.accountStatusReason = this._globalService.getAccountStatusChangeReason();
    this.apiServiceCall.verifyFromEmail(payload).subscribe(res => {
      console.log("Verify email response:" + res);
      if (res == "0") {
        this.apiServiceCall.getSystemNumber().subscribe(res => {

          if(res['PensionAccountResponse'].status.statusCode != 0){        
            //this._globalService.deleteCookie('accessToken');
            sessionStorage.removeItem('accessToken');
            this._router.navigateByUrl("unexpectedErrorPage");
            
          }else{

          console.log(res, "inside auth service");
          this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
          this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
          this._globalService.deleteCookie("email_ID");
        }});
      } else {
        this._globalService.deleteCookie("email_ID");
      }
    })
  }

  address() {
    this.goTo = 'viewAddress';
    this.selectedInd = 0;
    this._globalService.setUpdateSuccess(false);
    this._globalService.setIsFromUpdate(false);
    this._globalService.setNonResidentFlag(false);
  }
  ngAfterContentChecked() {
    $('.mat-tab-label-container').addClass('container');
    $('.mat-tab-list').addClass('row');
    $('.mat-tab-labels').addClass('col-lg-12 col-md-12 col-sm-12 clearPadding');
  }

  changeOrientation(event) {
    $('.mat-tab-label-container').addClass('container');
    $('.mat-tab-list').addClass('row');
    $('.mat-tab-labels').addClass('col-lg-12 col-md-12 col-sm-12 clearPadding');
  }
  onTabSelectChange(event) {
    if (event.index === 0) {
      this.address();
    } else if (event.index === 1) {
      this.goTo = 'contactInfo';
      this.selectedInd = 1;
    } else if (event.index === 2) {
      this.goTo = 'paymentMethod';
      this.selectedInd = 2;
    } else if (event.index === 3) {
      this.goTo = 'federalElec';
      this.selectedInd = 3;
    } else if (event.index === 4) {
      this.goTo = 'stateElec';
      this.selectedInd = 4;
    }
    this.activeScreen.changeMessage('View My Information');
   }
  // addLabel(getId) {
  //   $('#' + getId).setAttribute('aria-selected', true);
  // }

  // removeLabel(getId) {
  //   $('#' + getId).removeAttribute('aria-selected', false);
  // }


}
