import { Injectable } from "@angular/core";
import { environment } from "./../environments/environment";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Injectable()
export class AppInitService {
  constructor(private spinnerService: Ng4LoadingSpinnerService) {}

  Init() {
    return new Promise<void>((resolve, reject) => {
      console.log("AppInitService.init() called");
      this.spinnerService.show();
      setTimeout(() => {
        this.setEnvVariables();
        resolve();
      }, 1000);
      //this.spinnerService.hide();
    });
  }
  setEnvVariables() {
    const hostname = window.location.hostname;
    if (
      hostname.includes("qaintaz") ||
      hostname.includes("qapension") ||
      hostname.includes("localhost")
    ) {
      this.setQaEnvironment();
    } else if (
      hostname.includes("devintaz") ||
      hostname.includes("devpensions")
    ) {
      this.setDevEnvironment();
    }
  }
  setQaEnvironment() {
    this.spinnerService.show();

    // Below is the V10 block that was in-progress for V10 on QA
    // environment.production=false;
    // environment.envName='qa';
    // environment.Env="https://qaapi10.cvshealth.com";
    // environment.path="/healthcare/qapath1/v3/pensionholder/";
    // environment.Env_new="https://qaapi10.cvshealth.com";
    // environment.path_new="/healthcare/qapath1/v3/lcp_pensionholder/";
    // environment.getTaxDoc_Path ="https://qawww30.aetna.com";
    // environment.getTaxDoc_Path_External ="https://qawww30.aetna.com";
    // environment.getNewTax_Path="/DocumentInquiryII/V01.00/restservices/getDocument?doc=";
    // environment.taxDoc_Path ="/healthcare/qapath1/v3/lcp_communicationcontents";
    // environment.redirectURI="https://qapensions.aetna.com";
    // environment.authURL="https://qaapi10.cvshealth.com/healthcare/qapath1/v1/auth/oauth2/imp/authorize";
    // environment.tokenURL="https://qaapi10.cvshealth.com/healthcare/qapath1/v5/auth/oauth2/token";
    // environment.client_id="dd2e5d3f-c993-456c-8392-7e3d056efbd4";
    // environment.client_secret="gX1lT2tB3hP5lI7sS6qN7jA4bV0fA6tK4bC6wE8xX1uS6bI4aV";
    // environment.sharedLoc='https://qamobile.aetna.com/appConfig/PensionPortal';
    // environment.logoLink='https://qa2member.aetna.com';
    // environment.httpTimeout=30

    // Below is the V10 block that will be used for V10 onprem in QA
    environment.production = false;
    environment.envName = "qa";
    environment.Env = "https://qaapi01.aetna.com";
    environment.path = "/healthcare/qapath1/v3/pensionholder/";
    environment.Env_new = "https://qaapi01.aetna.com";
    environment.path_new = "/healthcare/qapath1/v3/lcp_pensionholder/";
    environment.getTaxDoc_Path = "https://qawww30.aetna.com";
    environment.getTaxDoc_Path_External = "https://qawww30.aetna.com";
    environment.getNewTax_Path =
      "/DocumentInquiryII/V01.00/restservices/getDocument?doc=";
    environment.taxDoc_Path =
      "/healthcare/qapath1/v3/lcp_communicationcontents";
    environment.redirectURI = "https://qapensions.aetna.com";
    environment.authURL =
      "https://qaapi01.aetna.com/healthcare/qapath1/v1/auth/oauth2/imp/authorize";
    environment.tokenURL =
      "https://qaapi01.aetna.com/healthcare/qapath1/v5/auth/oauth2/token";
    environment.client_id = "dd2e5d3f-c993-456c-8392-7e3d056efbd4";
    environment.client_secret =
      "gX1lT2tB3hP5lI7sS6qN7jA4bV0fA6tK4bC6wE8xX1uS6bI4aV";
    environment.sharedLoc =
      "https://qamobile.aetna.com/appConfig/PensionPortal";
    environment.logoLink = "https://qa2member.aetna.com";
    environment.httpTimeout = 30;

    // Below is the V5 block that was working on v5 QA
    // environment.production = false;
    // environment.envName = "qa";
    // environment.Env = "https://qaapih1.aetna.com";
    // environment.path = "/healthcare/qapath1/v3/pensionholder/";
    // environment.Env_new = "https://qaapih1.aetna.com";
    // environment.path_new = "/healthcare/qapath1/v3/lcp_pensionholder/";
    // environment.getTaxDoc_Path = "https://qawww30.aetna.com";
    // environment.getTaxDoc_Path_External = "https://qawww30.aetna.com";
    // environment.getNewTax_Path =
    //   "/DocumentInquiryII/V01.00/restservices/getDocument?doc=";
    // environment.taxDoc_Path =
    //   "/healthcare/qapath1/v3/lcp_communicationcontents";
    // environment.redirectURI = "https://qapensions.aetna.com";
    // environment.authURL =
    //   "https://qaapih1.aetna.com/healthcare/qapath1/imp/auth";
    // environment.tokenURL =
    //   "https://qaapih1.aetna.com/healthcare/qapath1/v5/auth/oauth2/token";
    // environment.client_id = "dd2e5d3f-c993-456c-8392-7e3d056efbd4";
    // environment.client_secret =
    //   "gX1lT2tB3hP5lI7sS6qN7jA4bV0fA6tK4bC6wE8xX1uS6bI4aV";
    // environment.sharedLoc =
    //   "https://qamobile.aetna.com/appConfig/PensionPortal";
    // environment.logoLink = "https://qa2member.aetna.com";
    // environment.httpTimeout = 30;
  }
  setDevEnvironment() {
    environment.production = false;
    environment.envName = "dev";
    environment.Env = "https://devapi10.cvshealth.com";
    environment.path = "/healthcare/devpath1/v3/pensionholder/";
    environment.Env_new = "https://devapi10.cvshealth.com";
    environment.path_new = "/healthcare/devpath1/v3/lcp_pensionholder/";
    environment.getTaxDoc_Path = "https://devwww30.aetna.com";
    environment.getTaxDoc_Path_External = "https://devxwww30.aetna.com";
    environment.getNewTax_Path =
      "/DocumentInquiryII2/V01.00/restservices/getDocument?doc=";
    environment.taxDoc_Path =
      "/healthcare/devpath1/v3/lcp_communcationcontents";
    environment.redirectURI = "https://devpensions.aetna.com";
    environment.authURL =
      "https://devapi10.int.cvshealth.com/healthcare/qapath1/v1/auth/oauth2/imp/authorize";
    environment.tokenURL =
      "https://devapi10.int.cvshealth.com/healthcare/devpath1/v5/auth/oauth2/token";
    environment.client_id = "754245ea-8b9f-429f-a39b-d2ae971b854c";
    environment.client_secret =
      "xB5uT7kX7qV2uT2jF3qY5hI6fY6wX8jW5yO1jC5bL3qR5aU2jA";
    environment.sharedLoc =
      "https://qamobile.aetna.com/appConfig/PensionPortal";
    environment.logoLink = "https://dev2member.aetna.com";
    environment.httpTimeout = 30;
  }
}
