import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './apiService.service';
import { environment } from '../../../environments/environment';
import { GlobalErrorHandler } from '../../GlobalErrorHandler';
import { GlobalService } from '../../global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class ApiServiceCall {
  private systemNumber;
  constructor(private _http: HttpClient, private apiService: ApiService, private http: HttpClient,
    private _globalService: GlobalService, private _router: Router, private spinnerService: Ng4LoadingSpinnerService) { }


  /*url = '../../../pensionportalUI/assets/jsonResponse/registration.json';

  systemNumberUrl = "../../../pensionportalUI/assets/jsonResponse/pensionAccount.json";
  withholdingUrl = '../../../pensionportalUI/assets/jsonResponse/withholding.json';
  incomeURL = '../../../pensionportalUI/assets/jsonResponse/incomeResponse.json';
  paymentURL = '../../../pensionportalUI/assets/jsonResponse/payments.json';
  taxURL = '../../../pensionportalUI/assets/jsonResponse/taxResponse.json';
  doc2URL = '../../../pensionportalUI/assets/jsonResponse/doc2.json';
  taxError = '../../../pensionportalUI/assets/jsonResponse/taxError.json';*/
  successCode;
  options;
  stateURL = '../../../pensionportalUI/assets/jsonResponse/state.json';

  getAddressInfo() {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let isStatic = true;
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";

    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
      //'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
      'Id_Token': JSON.parse(id_token)
    });

    this.options = { headers: headers, withCredentials: true };

    return this.http.get(url, this.options = isStatic ? this.options : {}).pipe(
      catchError(this.handleError))

  }

  handleError(error: Response) {
    console.log("Handle error in response", error);

    return Observable.throw(error);
  }

  updateAddressInfo(addressData): Observable<any> {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    url = this.appendEmailParameter("addressUpdate", url);
    let isStatic = true;


    let token = sessionStorage.getItem('accessToken');
    //let clientId = "&X-IBM-Client-Id="+environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    //'X-IBM-Client-Id': environment.client_id,
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Id_Token': JSON.parse(id_token)
    });
    this.options.headers = headers;


    return this.http.put(url, addressData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode == 0 || this.successCode == 67 || this.successCode == 68) {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }),
        catchError(this.handleError))
  }



  getContactInfo() {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    let isStatic: boolean = true;
    //let token = this._globalService.getCookie('accessToken');
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
      //'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
      //'scope':'Public NonPII PII PHI openId',

      'Id_Token': JSON.parse(id_token)
    });
    //this.options.withCredentials = true;
    this.options = { headers: headers, withCredentials: true };


    return this.http.get(url, this.options = isStatic ? this.options : {})
      .pipe(catchError(this.handleError));


  }

  updateContactInfo(contactData) {
    let phoneFlag = this._globalService.getEmailPhoneFlag();
    let isStatic: boolean = true;
     //let token = this._globalService.getCookie('accessToken');
     let token = sessionStorage.getItem('accessToken');
     //let clientId = "&X-IBM-Client-Id="+environment.client_id;
     let id_token = this._globalService.getCookie('id_token');
     //'X-IBM-Client-Id': environment.client_id,
     const headers = new HttpHeaders({'Authorization': "Bearer " + JSON.parse(token).tokenValue, 
                      'Id_Token': JSON.parse(id_token)});
     this.options.headers = headers;

    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    if (phoneFlag == "PhoneUpdate") {
      url = this.appendEmailParameter("contactUpdate", url);
    } else {
      url = this.appendEmailParameter("emailUpdate", url);
    }
    return this.http.put(url, contactData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode === '0') {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }), catchError(this.handleError))

  }

  sendMailToOldUser(contactData) {
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    url = this.appendEmailParameter("contactUpdate", url);
    let isStatic: boolean = true;
     //let token = this._globalService.getCookie('accessToken');
     let token = sessionStorage.getItem('accessToken');
     //let clientId = "&X-IBM-Client-Id="+environment.client_id;
     let id_token = this._globalService.getCookie('id_token');
     //'X-IBM-Client-Id': environment.client_id,
     const headers = new HttpHeaders({'Authorization': "Bearer " + JSON.parse(token).tokenValue, 
                      'Id_Token': JSON.parse(id_token)});
     this.options.headers = headers;
    return this.http.put(url, contactData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode === '0') {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }), catchError(this.handleError))
  }

  updatePaymentMethod(paymentData) {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + '~' + this.systemNumber + '/registration';
    let isStatic: boolean = true;
    //let token = this._globalService.getCookie('accessToken');
    let token = sessionStorage.getItem('accessToken');
    //let clientId = "&X-IBM-Client-Id="+environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    //'X-IBM-Client-Id': environment.client_id,
    const headers = new HttpHeaders({
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Id_Token': JSON.parse(id_token)
    });
    this.options.headers = headers;

    url = this.appendEmailParameter("paymentUpdate", url);

    return this.http.put(url, paymentData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode === '0') {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }), catchError(this.handleError))


  }


  getState() {
    return this._http.get(this.stateURL).pipe(catchError(this.handleError));
  }


  // for federal update
  updateFederal(federalData) {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    let isStatic: boolean = true;
     //let token = this._globalService.getCookie('accessToken');
     let token = sessionStorage.getItem('accessToken');
     //let clientId = "&X-IBM-Client-Id="+environment.client_id;
     let id_token = this._globalService.getCookie('id_token');
     //'X-IBM-Client-Id': environment.client_id,
     const headers = new HttpHeaders({'Authorization': "Bearer " + JSON.parse(token).tokenValue, 
                      'Id_Token': JSON.parse(id_token)});
     this.options.headers = headers;

    url = this.appendEmailParameter("federalUpdate", url);

    return this.http.put(url, federalData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode === '0') {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }), catchError(this.handleError))


  }

  // for state updation
  updateState(stateData) {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    let isStatic: boolean = true;

     //let token = this._globalService.getCookie('accessToken');
     let token = sessionStorage.getItem('accessToken');
     //let clientId = "&X-IBM-Client-Id="+environment.client_id;
     let id_token = this._globalService.getCookie('id_token');
     //'X-IBM-Client-Id': environment.client_id,
     const headers = new HttpHeaders({'Authorization': "Bearer " + JSON.parse(token).tokenValue, 
                      'Id_Token': JSON.parse(id_token)});
     this.options.headers = headers;

    url = this.appendEmailParameter("stateUpdate", url);

    return this.http.put(url, stateData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode === '0') {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }),
        catchError(this.handleError))

  }
  // changed
  getIncomeData() {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path_new + this.systemNumber + "/income";
    let isStatic = true;
    //let token = this._globalService.getCookie('accessToken');
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
      //'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
      //'scope':'Public NonPII PII PHI openId',
      'Id_Token': JSON.parse(id_token)
    });
    //this.options.withCredentials = true;
    this.options = { headers: headers, withCredentials: true };


    return this.http.get(url, this.options = isStatic ? this.options : {})
      .pipe(catchError(this.handleError));


  }

  getWithholdingData() {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/withholdings";

    let isStatic = true;
    //let token = this._globalService.getCookie('accessToken');
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
      //'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
      //'scope':'Public NonPII PII PHI openId',

      'Id_Token': JSON.parse(id_token)
    });
    //this.options.withCredentials = true;
    this.options = { headers: headers, withCredentials: true };


    return this.http.get(url, this.options = isStatic ? this.options : {})
      .pipe(catchError(this.handleError));

  }

  resendContactInfo(contactData) {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    let reason = this._globalService.getAccountStatusChangeReason();
    if (reason === "DATACHG") {
      url = this.appendEmailParameter("ResendPayment", url);
    }
    else if (reason === "EMAILCHG") {
      url = this.appendEmailParameter("ResendEmail", url);
    }
    else if (reason === "NEWACCT") {
      url = this.appendEmailParameter("ResendRegistration", url);
    }
    // url = this.appendEmailParameter("contactUpdate", url);

    let isStatic: boolean = true;
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id="+environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
    //'X-IBM-Client-Id': environment.client_id,
    'Authorization': "Bearer " + JSON.parse(token).tokenValue, 
    'Pragma': 'no-cache',
    'Cache-Control': 'no-cache',
    //'scope':'Public NonPII PII PHI openId',
    
    'Id_Token': JSON.parse(id_token)});
    //this.options.withCredentials = true;
    this.options = {headers: headers, withCredentials : true};

    return this.http.put(url, contactData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode === '0') {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }),
        catchError(this.handleError))

  }

  updateEmail(contactData) {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    url = this.appendEmailParameter("emailUpdate", url);

    let isStatic: boolean = true;
     //let token = this._globalService.getCookie('accessToken');
     let token = sessionStorage.getItem('accessToken');
     //let clientId = "&X-IBM-Client-Id="+environment.client_id;
     let id_token = this._globalService.getCookie('id_token');
     //'X-IBM-Client-Id': environment.client_id,
     const headers = new HttpHeaders({'Authorization': "Bearer " + JSON.parse(token).tokenValue, 
                      'Id_Token': JSON.parse(id_token)});
     this.options.headers = headers;

    return this.http.put(url, contactData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode === '0') {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }),
        catchError(this.handleError))

  }

  verifyFromEmail(contactData) {
    // this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/registration";
    let reason = this._globalService.getAccountStatusChangeReason();
    if (reason === "DATACHG") {
      url = this.appendEmailParameter("verifyPayment", url);
      return this.returnVerifyPUTResponse(url, contactData);
    }
    else if (reason === "EMAILCHG") {
      url = this.appendEmailParameter("verifyEmail", url);
      return this.returnVerifyPUTResponse(url, contactData);
    }
    else if (reason === "NEWACCT") {
      url = this.appendEmailParameter("verifyRegistation", url);
      return this.returnVerifyPUTResponse(url, contactData);
    }
  }

  returnVerifyPUTResponse(url, contactData) {
    let isStatic: boolean = true;
    if (isStatic) {
      //let token = this._globalService.getCookie('accessToken');
      let token = sessionStorage.getItem('accessToken');
      //let clientId = "&X-IBM-Client-Id="+environment.client_id;
      let id_token = this._globalService.getCookie('id_token');
      //'X-IBM-Client-Id': environment.client_id,
      const headers = new HttpHeaders({
        'Authorization': "Bearer " + JSON.parse(token).tokenValue,
        'Id_Token': JSON.parse(id_token)
      });
      this.options.headers = headers;

    }
    return this.http.put(url, contactData, this.options = isStatic ? this.options : {})
      .pipe(map(response => {
        this.successCode = response['RegistrationResponse'].updateRegistrationResponse.status.statusCode;
        if (this.successCode == 0) {
          return this.successCode;
        } else {
          return response['RegistrationResponse'].updateRegistrationResponse.status.detail;
        }
      }),
        catchError(this.handleError))
  }

  
  getPaymentInfo(year) {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.path + this.systemNumber + "~" + this.systemNumber + "/payments?requestPaymentYear=" + year;
    let isStatic = true;
    //let token = this._globalService.getCookie('accessToken');
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
      //'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
      //'scope':'Public NonPII PII PHI openId',

      'Id_Token': JSON.parse(id_token)
    });
    //this.options.withCredentials = true;
    this.options = { headers: headers, withCredentials: true };

    return this.http.get(url, this.options = isStatic ? this.options : {})
      .pipe(catchError(this.handleError));

  }



  getTaxDetails(taxId) {
    //this.systemNumber = this._globalService.getCookie('systemNumber');
    this.systemNumber = this._globalService.getSystemNumber();
    let url = environment.Env + environment.taxDoc_Path + '?docClassType=TXDOC&docClassSubtype=TXDOTIR&customerid=' + taxId;
    let isStatic = true;
    //let token = this._globalService.getCookie('accessToken');
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
      //'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
      //'scope':'Public NonPII PII PHI openId',

      'Id_Token': JSON.parse(id_token)
    });
    //this.options.withCredentials = true;
    this.options = { headers: headers, withCredentials: true };

    return this.http.get(url, this.options = isStatic ? this.options : {})
      .pipe(catchError(this.handleError));

  }

  getTaxDocument(docLink) {
    this._globalService.getSystemNumber();
    let url = environment.getTaxDoc_Path_External + environment.getNewTax_Path + docLink;
    let isStatic = true;
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    let withCredentialsFlag = false;
    const headers = new HttpHeaders({
      'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue
      // 'Pragma': 'no-cache',
      //'Cache-Control': 'no-cache',
      // 'Access-Control-Allow-Origin' : '*'
      //'scope':'Public NonPII PII PHI openId',

      //'Id_Token': JSON.parse(id_token)
    });
    /* This is temporary fix until F5 team fix the CORS issue in production */
    if (environment.envName == "qa") {
      withCredentialsFlag = true;
    }
    else {
      withCredentialsFlag = false;
    }
    //this.options.withCredentials = true;
    this.options = { headers: headers, withCredentials: withCredentialsFlag, useXDomain: true };
    return this.http.get(url, this.options = isStatic ? this.options : {})
      .pipe(catchError(this.handleError));
  }
  /* // ** //getMobileTaxDocument(docLink): Observable<any> {
    this._globalService.getSystemNumber();
    let url=environment.getTaxDoc_Path+environment.getNewTax_Path+docLink;
    return this.apiService.getTaxDocument(url,true).map(response=>{
      console.log(response,"get doc 2 api call response");
      return response;
    }).catch((error) => {
      this.spinnerService.hide();
      return error;
    });
  }*/
 
  getSystemNumber() {
    let url = environment.Env_new + environment.path_new + "pensionaccount"
    let isStatic: boolean = true;

    //let token = this._globalService.getCookie('accessToken');
    let token = sessionStorage.getItem('accessToken');
    let clientId = "&X-IBM-Client-Id=" + environment.client_id;
    let id_token = this._globalService.getCookie('id_token');
    const headers = new HttpHeaders({
      //'X-IBM-Client-Id': environment.client_id,
      'Authorization': "Bearer " + JSON.parse(token).tokenValue,
      'Pragma': 'no-cache',
      'Cache-Control': 'no-cache',
      //'scope':'Public NonPII PII PHI openId',

      'Id_Token': JSON.parse(id_token)
    });
    //this.options.withCredentials = true;
    this.options = { headers: headers, withCredentials: true };

    return this.http.get(url, this.options = isStatic ? this.options : {})
      .pipe(

        catchError(this.handleGetSystemNumberError)
      );

  }
  handleGetSystemNumberError(error: Response) {

    console.log("Get system error response ", error);
    let str = JSON.parse(error["_body"]).moreInformation;

    let errorCode = str.search("0055");
    if (errorCode != -1) {
      this.spinnerService.hide();
      this._router.navigateByUrl("error");
    }
    else {
      this.spinnerService.hide();
      this._router.navigateByUrl("unexpectedErrorPage");
    }
    this.spinnerService.hide();
    return Error['PensionAccountResponse'].status.detail;

  }
  appendEmailParameter(case1, url) {
    console.log("adding parameter" + case1);
    switch (case1) {
      case "addressUpdate":
        url += "?emailAction=AddressInfoUpdate"
        return url;
      case "verifyRegistation":
        url += "?emailAction=VerifyRegistration"
        return url;
      case "verifyEmail":
        url += "?emailAction=VerifyEmailAddressUpdate"
        return url;
      case "verifyPayment":
        url += "?emailAction=VerifyPaymentUpdate"
        return url;
      case "emailUpdate":
        url += "?emailAction=ContactInfoEmailUpdate"
        return url;
      case "contactUpdate":
        url += "?emailAction=ContactInfoPhoneUpdate"
        return url;
      case "paymentUpdate":
        url += "?emailAction=PaymentMethodUpdate"
        return url;
      case "federalUpdate":
        url += "?emailAction=FederalElectionUpdate"
        return url;
      case "stateUpdate":
        url += "?emailAction=StateElectionUpdate"
        return url;
      case "ResendPayment":
        url += "?emailAction=ResendPaymentMethodUpdate"
        return url;
      case "ResendEmail":
        url += "?emailAction=ResendContactInfoEmailUpdate"
        return url;
      case "ResendRegistration":
        url += "?emailAction=ResendRegistration"
        return url;
      default: '';
    }
  }
  getEmail() {
    let url = "http://localhost:4200?&email_ID=dmVybWFzNA==@aetna.com&action=test";
    let results = new RegExp('[\\?&]' + 'email_ID' + '=([^&#]*)').exec(url);
    console.log(results[1], "email");
    if (results) {
      let email_ID = results[1];
      this._globalService.setCookie("email_ID", email_ID);
    }
    let payload = {
      "RegistrationRequest": {
        "updateRegistrationRequest": {
          "addressrules": "N",
          "contactrules": "N",
          "paymentmethodrules": "N",
          "fedexrules": "N",
          "statetaxrules": "N",
          "addressfinalist": "Y",
          "residentstreetline1": "1 MAIN STREET",
          "residentstreetline2": "",
          "residentstreetline3": "",
          "residentcity": "HARTFORD",
          "residentstate": "CO",
          "residentpostalcode": "061201006",
          "foreigncountryname": "",
          "foreignprovincename": [
            {
              "name": ""
            }
          ],
          "foreigncityname": "",
          "foreignmailcode": "",
          "requeststreetLine1": "",
          "requeststreetLine2": "",
          "requeststreetLine3": "",
          "requestcity": "",
          "requeststate": "",
          "requestpostalCode": "06120-1006",
          "requestcountryname": "",
          "requestprovincename": [
            {
              "name": ""
            }
          ],
          "requestcityname": "",
          "requestforeignmailcode": "",
          "foreigncountrycode": "011",
          "foreignphonenumber": "65478-96547775",
          "requestphonenumber": "456-273-1234",
          "emailaddress": "",
          "emaildomain": this._globalService.getCookie("email_ID"),
          "bankroutingnumber": "072400052",
          "bankname": "",
          "bankpaymenttype": "E",
          "bankaccountnumber": "852369741258",
          "bankaccounttype": "S",
          "bankjointaccountind": "N",
          "bankjointholderlastname": "PATTERSON",
          "bankjointholderfirstname": "LARRY",
          "bankjointholdermiddlename": "L",
          "bankjointholderssn": "742583569",
          "bankjointholderrelation": "POA",
          "fedtaxelectioncode": "I",
          "fedtaxexemptionnumber": "1",
          "fedtaxfillingstatus": "DI",
          "fedtaxwithholdingamt": "0.00",
          "fedtaxaddnlwithholdingamt": "0.00",
          "statetaxelectioncode": "I",
          "statetaxexemption": "1",
          "statetaxfillingstatus": "M",
          "statetaxwithholdingamt": "0.00",
          "statetaxaddlwithholdingamt": "0.00",
          "statetaxwithholdingPCT": "0",
          "statetaxaddlwithholdingPCT": "0",
          "splexemptiondesc1": [
            {
              "description": "",
              "value": ""
            },
            {
              "description": "",
              "value": ""
            }
          ]
        }
      }
    }
    /*this.updateAddressInfo(payload).add(res=>{
      console.log(res);
      if (res == "0") {

      } else {

      }
     })*/
  }
}
