import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { ClientActiveService } from './shared/services/clientActiveService.service';
import { GlobalService } from './global.service';
import { ApiServiceCall } from './shared/services/apiCall.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActiveScreenService } from './services/activeScreen/active-screen.service';
import { environment } from './../environments/environment';
import { ErrorCheckService } from './shared/services/errorCheck.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ClientActiveService]
})

export class AppComponent implements OnInit {
  template: string = `<img src="../pensionportalUI/assets/images/loading_spinner.gif" alt="Loading" />`;
  constructor(private authService: AuthService, private clientActiveService: ClientActiveService,
    private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private spinnerService: Ng4LoadingSpinnerService, private titleName: ActiveScreenService, private _router: Router,
  private errorCheckService: ErrorCheckService) {}

 
  title = 'app';
  qaEnv;
  isSessionLoaded=false;
  private sharedLoc = environment.sharedLoc;

  ngOnInit() {
	//debugger;    //debug - for QA only 
    if (window.parent && window.parent.location.hostname !== environment.redirectURI){
    this.titleName.currentTitle.subscribe(title => {
      this.title = title;
      $('#firstFocus').attr('tabIndex', '-1');
      $('#firstFocus').focus();
      if (this.sharedLoc === 'https://qamobile.aetna.com/appConfig/PensionPortal') {
      this.qaEnv = true;
    } else {
      this.qaEnv = false;
    }
    });
    
    //if (!sessionStorage.length) {
      // Ask other tabs for session storage
    //this._globalService.setCookie('getSessionStorage', Date.now().toString());
    localStorage.setItem('getSessionStorage', Date.now().toString());
    //};

    // get the href of the url here only, as this gets modified later
    let urlS = window.location.href;
    // changes done for safari issue 10/29
    let resultsSafari = new RegExp('[\\?&]' + 'email' + '=([^&#]*)').exec(urlS);
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    if(resultsSafari && isSafari){
      let email_ID= resultsSafari[1];
      this._globalService.emailflow = true;
      this._globalService.setCookie("email_ID",email_ID);
    }
    if(resultsSafari && this._globalService.getCookie('sessionStorage') && isSafari){
       return new Promise((resolve, reject) => {
            var data = JSON.parse(this._globalService.getCookie('sessionStorage'));
            // console.log(data,"line 58 sss :  data");
            for (var key in data) {
                sessionStorage.setItem(key, data[key]);
            }
            resolve();
          }).then(()=>{
            setTimeout( () => { 
            this.isSessionLoaded = true;
                  // take the email data from the url
                  let resultsS = new RegExp('[\\?&]' + 'email' + '=([^&#]*)').exec(urlS);
                  //if email data exists then proceed
                  if(resultsS){
                    this._globalService.emailflow = true;
                    //this._globalService.setCookie('getSessionStorage', Date.now().toString());
                    localStorage.setItem('getSessionStorage', Date.now().toString());
                    let email_ID= resultsS[1];
                    this._globalService.setCookie("email_ID",email_ID);
                      setTimeout( () => {
                          this.apiServiceCall.getSystemNumber().subscribe(res => {
                            if(res['PensionAccountResponse'].status.statusCode != 0){        
                              //this._globalService.deleteCookie('accessToken');
                              sessionStorage.removeItem('accessToken');
                              this._router.navigateByUrl("unexpectedErrorPage");                              
                            }else{
                            this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
                            this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
                            let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
                            this._globalService.setSystemNumber(systemNumber);
                            this.spinnerService.hide();
                            return new Promise((resolve, reject) => {
                              this._globalService.emailflow = false;
                              this.verifyUser();
                              resolve();
                              this.spinnerService.hide();
                              }).then(()=>{
                              })
                           } }); //sub end 
                      }, 5000);
                  }
          }, 2000); 
        });
    }
    window.addEventListener('storage', (event) => {
      console.log("storage event called")
        if (event.key == 'getSessionStorage') {
            // Some tab asked for the sessionStorage -> send it
           this._globalService.setCookie('sessionStorage', JSON.stringify(sessionStorage));
        } else if (event.key == 'sessionStorage' ) { //&& !sessionStorage.length
            // sessionStorage is empty -> fill it
            return new Promise((resolve, reject) => {
            var data = JSON.parse(event.newValue);
            for (var key in data) {
                sessionStorage.setItem(key, data[key]);
            }
            resolve();
          }).then(()=>{
            setTimeout( () => { 
            this.isSessionLoaded = true;
            //this.authService.checkToken(1);
          }, 2000);
          });
        } else if(event.key === 'logged_in') {
          //this._globalService.deleteCookie("logged_in");
          localStorage.removeItem('logged_in');
          $('#mainContainer').removeClass('menuSlide');
          $('body').removeClass('overHide');
          //this._globalService.deleteCookie("accessToken");
          sessionStorage.removeItem("accessToken");
          this._globalService.deleteCookie("id_token");
          this._globalService.deleteCookie("Nonce");
          this.authService.getAuthToken();
        }
    });
    
    let url = window.location.href;
      let results = new RegExp('[\\?&]' + 'email' + '=([^&#]*)').exec(url);
      if(results && !isSafari){ // sss added  "&& !isSafari"
        this._globalService.emailflow = true;
        //this._globalService.setCookie('getSessionStorage', Date.now().toString());
        localStorage.setItem('getSessionStorage', Date.now().toString());
        if(this._globalService.getCookie('sessionStorage')){
          var data = JSON.parse(this._globalService.getCookie('sessionStorage'));
            // console.log(data,"line 58 sss :  data");
            for (var key in data) {
                sessionStorage.setItem(key, data[key]);
            }
        }
        // console.log(sessionStorage.access_token);
        // console.log(results[1],"email");
       let email_ID= results[1];
       this._globalService.setCookie("email_ID",email_ID);
          setTimeout( () => {
                //console.log("Email confirmation flow changes");
              this.apiServiceCall.getSystemNumber().subscribe(res => {
                if(res['PensionAccountResponse'].status.statusCode != 0){        
                  //this._globalService.deleteCookie('accessToken');
                  sessionStorage.removeItem('accessToken');
                  this._router.navigateByUrl("unexpectedErrorPage");
                  
                }else{
                this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
                this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
                let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
                this._globalService.setSystemNumber(systemNumber);
                this.spinnerService.hide();
                return new Promise((resolve, reject) => {
                  this._globalService.emailflow = false;
                  this.verifyUser();
                  resolve();
                  this.spinnerService.hide();
                  }).then(()=>{
              //this.authService.removeTokenFromStorage();
              //this.authService.getAuthToken();
                  })
               } });
          }, 5000);
      }
      var hash = window.location.hash.substr(1);
          if(hash.indexOf("access_token") >= 0){
       this.authService.checkToken(1);
      }
      setTimeout( () => { 
      this.isSessionLoaded = true;
    this.authService.checkToken(1);
    
    this.authService.setTimer();
    this.spinnerService.show();
    
    setTimeout( () => {
    this.apiServiceCall.getSystemNumber().subscribe(res=>{

      if(res['PensionAccountResponse'].status.statusCode != 0){        
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");
        
      }else{

      this.titleName.timeOutSession(false);
      this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
      this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
      this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
       let systemNumber = res["PensionAccountResponse"].readPensionAccountResponse.pensionHolder.individualIdentifier.idValue;
      this._globalService.setSystemNumber(systemNumber);
      this.spinnerService.hide();
      }
      error => {
       let str=JSON.parse(error["_body"]).moreInformation;
       let errorCode=str.search("0055");
       if(errorCode != -1 ){
        this.spinnerService.hide();
        this._router.navigateByUrl("error");
       }
       else{
        this.spinnerService.hide();
        this._router.navigateByUrl("unexpectedErrorPage");
       }
       this.spinnerService.hide();
      }  }); //sub end 
    }, 2000);
  }, 3000);
}
  }

  onActivateRoute(event) {
    window.scroll(0, 0);
  }
  goBack() {
    history.go();
  }
  verifyUser(){
    if (this._globalService.getCookie('email_ID')) {
      // console.log(this._globalService.getAccountStatusChangeReason());
      if (this._globalService.getAccountStatusChangeReason() != "CONFIRMD") {
        this.verifyEmail();
      } else {
        this._globalService.deleteCookie("email_ID");
      }
    } else {
      this._globalService.deleteCookie("email_ID");
    }
  }

  accountStatusReason
  verifyEmail() {
    //this.authSer.getEmail();
    let payload = {
      "RegistrationRequest": {
        "updateRegistrationRequest": {
          "addressrules": "N",
          "contactrules": "N",
          "paymentmethodrules": "N",
          "fedexrules": "N",
          "statetaxrules": "N",
          "addressfinalist": "Y",
          "residentstreetline1": "1 MAIN STREET",
          "residentstreetline2": "",
          "residentstreetline3": "",
          "residentcity": "HARTFORD",
          "residentstate": "CO",
          "residentpostalcode": "06120-1006",
          "foreigncountryname": "",
          "foreignprovincename": [
            {
              "name": ""
            }
          ],
          "foreigncityname": "",
          "foreignmailcode": "",
          "requeststreetLine1": "",
          "requeststreetLine2": "",
          "requeststreetLine3": "",
          "requestcity": "",
          "requeststate": "",
          "requestpostalCode": "06120-1006",
          "requestcountryname": "",
          "requestprovincename": [
            {
              "name": ""
            }
          ],
          "requestcityname": "",
          "requestforeignmailcode": "",
          "foreigncountrycode": "011",
          "foreignphonenumber": "65478-96547775",
          "requestphonenumber": "456-273-1234",
          "emailaddress": "",
          "emaildomain": this._globalService.getCookie('email_ID'),
          "bankroutingnumber": "072400052",
          "bankname": "",
          "bankpaymenttype": "E",
          "bankaccountnumber": "852369741258",
          "bankaccounttype": "S",
          "bankjointaccountind": "N",
          "bankjointholderlastname": "PATTERSON",
          "bankjointholderfirstname": "LARRY",
          "bankjointholdermiddlename": "L",
          "bankjointholderssn": "742583569",
          "bankjointholderrelation": "O",
          "fedtaxelectioncode": "I",
          "fedtaxexemptionnumber": "1",
          "fedtaxfillingstatus": "DI",
          "fedtaxwithholdingamt": "0.00",
          "fedtaxaddnlwithholdingamt": "0.00",
          "statetaxelectioncode": "I",
          "statetaxexemption": "1",
          "statetaxfillingstatus": "M",
          "statetaxwithholdingamt": "0.00",
          "statetaxaddlwithholdingamt": "0.00",
          "statetaxwithholdingPCT": "0",
          "statetaxaddlwithholdingPCT": "0",
          "splexemptiondesc1": [
            {
              "description": "",
              "value": ""
            },
            {
              "description": "",
              "value": ""
            }
          ]
        }
      }
    }
    this.spinnerService.show();
    // console.log(this._globalService.getAccountStatusChangeReason());
    this.accountStatusReason = this._globalService.getAccountStatusChangeReason();
    this.apiServiceCall.verifyFromEmail(payload).subscribe(res => {
      // console.log("Verify email response:" + res);
      if (res == "0") {
        this.apiServiceCall.getSystemNumber().subscribe(res => {

          if(res['PensionAccountResponse'].status.statusCode != 0){       
            //this._globalService.deleteCookie('accessToken');
            sessionStorage.removeItem('accessToken');
            this._router.navigateByUrl("unexpectedErrorPage");
            
          }else{  
          
          // console.log(res, "inside auth service");
          this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
          this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
          this._globalService.deleteCookie("email_ID");
         } });
      } else {
        this._globalService.deleteCookie("email_ID");
      }
    })
  }
}
