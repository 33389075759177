import { FooterService } from './../services/footer.service';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../global.service';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { ApiServiceCall } from '../services/apiCall.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ErrorCheckService } from '../services/errorCheck.service';
@Component({
  selector: 'app-footer',
  templateUrl: './../views/footer.component.html',
  styleUrls: ['./../views/footer.component.scss'],
  providers: [FooterService]})
export class FooterComponent implements OnInit {
  footerData = {};
  userIsAuthorized = false;
  pensionErrorFlag = false;
  constructor(private _footerService: FooterService, private currLoc: Location, private _router: Router, private spinner: Ng4LoadingSpinnerService, private activeScreen: ActiveScreenService, private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
  private errorCheckService: ErrorCheckService) {}
  ngOnInit() {

    
    setTimeout( () => {
    this.userIsAuthorized = this._globalService.getIsAuthorizedUser();
    this.activeScreen.appTimeoutFlag.subscribe(pensionErrorFlag => this.pensionErrorFlag = pensionErrorFlag);
   /* this.apiServiceCall.getSystemNumber().subscribe(res=>{
    this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
      });*/
    this._footerService.getFooterData()
    .subscribe(resFooterData => this.footerData = resFooterData);
  }, 5000);
  }

  userIsLogged() {
    const currentPath = this.currLoc.path();
    if (sessionStorage.getItem('accessToken') && (this.currLoc.path() !== "''" && this.currLoc.path().search('/?code') === -1)) {
    // if(this._globalService.getCookie("accessToken")){
    return true;
  } else  {
    return false;
  }
  }
}
