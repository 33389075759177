import { ContactUsComponent } from './components/contact-us.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [ContactUsComponent],
    imports: [CommonModule],
    exports: [],
    bootstrap: [ContactUsComponent]
})
export class ContactUsModule { }
