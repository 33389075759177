import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/apiService.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/global.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable()
export class DashboardService {
     private _url: string = '../../pensionportalUI/assets/textsData/dashboard.json';

     options;
     constructor(private apiService: ApiService, private http: HttpClient, private _globalService: GlobalService) { }
     getDashboardData() {          
          
          let isStatic: boolean = false;

          let token = sessionStorage.getItem('accessToken');
          let clientId = "&X-IBM-Client-Id=" + environment.client_id;
          let id_token = this._globalService.getCookie('id_token');
          const headers = new HttpHeaders({
               //'X-IBM-Client-Id': environment.client_id,
               'Authorization': "Bearer " + JSON.parse(token).tokenValue,
               'Pragma': 'no-cache',
               'Cache-Control': 'no-cache',               
               'Id_Token': JSON.parse(id_token)
          });
          //this.options.withCredentials = true;
          this.options = { headers: headers, withCredentials: true };
          return this.http.get(this._url, this.options = isStatic ? this.options : {})
               .pipe(catchError(this.handleError));
     }

     handleError(error: Response) {
          console.log("Handle error in response", error);
          return Observable.throw(error);
     }

}
