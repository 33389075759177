import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatPhone',
})
export class FormatPhonePipe implements PipeTransform {

    transform(value: string): any {
        if (!!value && value.length === 10) {
           return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6, 10);
        }
        if (!!value && value.length === 16) {
            return value.substring(0, 3) + '-' + value.substring(3, 8) + '-' + value.substring(8, 16);
        }
        if (!!value && value.length === 12) {
            value = value.replace(/-/g, '');
            return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6, 12);
        }
        if (!!value && value.length === 9) {
            value = value.replace(/-/g, '');
            return  value.substring(0, 3) + '-' + value.substring(3, 5) + '-' + value.substring(5, 9);
        }
        return value;
    }

}

