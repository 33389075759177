import {Injectable} from '@angular/core';

import { ApiService } from '../../shared/services/apiService.service';
@Injectable()
export class GetSystemNumberService {
     
     constructor() {}
     
     
}
