import { PrivacyCenterComponent } from './components/privacy-center.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [PrivacyCenterComponent],
    imports: [CommonModule],
    exports: [],
    bootstrap: [PrivacyCenterComponent]
})
export class PrivacyCenterModule { }
