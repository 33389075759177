import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { HeaderService } from '../services/header.service';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { ApiServiceCall } from '../services/apiCall.service';
import { GlobalService } from '../../global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from '../../../environments/environment';
import { ErrorCheckService } from '../services/errorCheck.service';

declare var $: any;

import { FooterService } from '../services/footer.service';
import { ApiService } from '../services/apiService.service';

@Component({
  selector: 'app-header',
  templateUrl: './../views/header.component.html',
  styleUrls: ['./../views/header.component.scss'],
  providers: [HeaderService]
})

@Injectable()
export class HeaderComponent implements OnInit {
  addressDataObj; 
  individualName;
  apiSuccess = false;
  screen;
  openNav: boolean;
  menuSelected: string;
  route: string;
  showSubHeader = false;
  headerData: any = [];
  dashboardLinks: any = [];
  userloggedIn;
  userloggedInObj;
  userIsAuthorized = false;
  pensionErrorFlag = true;
  systemNumber;
  logoLink = environment.logoLink;

  constructor(private _headerData: HeaderService,
    private currLoc: Location,
    private router: Router,
    private activeScreen: ActiveScreenService,
    private _globalService: GlobalService,
    private apiServiceCall: ApiServiceCall,
    private storedResponseData: StoredResponseDataService,
    private spinner: Ng4LoadingSpinnerService, private _router: Router,
    private authService: AuthService, private errorCheckService: ErrorCheckService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.activeScreen.currentUser.subscribe(screen => this.individualName = screen);
    this.pensionErrorFlag = true;
    setTimeout(() => {
      this.pensionErrorFlag = this._globalService.getPensionErrorFlag(); //
      this.activeScreen.appTimeoutFlag.subscribe(pensionErrorFlag => this.pensionErrorFlag = pensionErrorFlag);

      if (this._globalService.getIndividualName()) {
        this.storedResponseData.changeUser(this.individualName);
        this._globalService.setUserName(this.individualName);
        this.activeScreen.getUserName(this.individualName);
      } else {
        setTimeout(() => {
          this.apiServiceCall.getSystemNumber().subscribe(res => {

            if(res['PensionAccountResponse'].status.statusCode != 0){        
              //this._globalService.deleteCookie('accessToken');
              sessionStorage.removeItem('accessToken');
              this._router.navigateByUrl("unexpectedErrorPage");
              
            }else{

            this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
            this._globalService.setAccountState(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatus);
            this._globalService.setAccountStatusChangeReason(res["PensionAccountResponse"].readPensionAccountResponse.pensionAccount.accountStatusChangeReason);
            if (res["PensionAccountResponse"].status.additionalStatus[0].statusCode != 0) {//
              this._globalService.setPensionErrorMsg(res["PensionAccountResponse"].status.additionalStatus[0].detail);
              this.pensionErrorFlag = true;
              this._globalService.setPensionErrorFlag(this.pensionErrorFlag);
              this.activeScreen.getPensionMsg(res["PensionAccountResponse"].status.additionalStatus[0].detail);
              //this.activeScreen.getPensionMsg("We are sorry - we do not recognize your user name. Please click logout to return to the login page and enter a user name that has been registered specifically for use with the Large Case Pension Portal. From the login page you can register a Pension Portal user name if you have not already done so.")
              this.spinner.hide();
            }//
            if (res["PensionAccountResponse"]) {
              this.systemNumber = res["PensionAccountResponse"]["readPensionAccountResponse"]["pensionHolder"]["individualIdentifier"]["idValue"];
              //this._globalService.setCookie('systemNumber', this.systemNumber);
              this._globalService.setSystemNumber(this.systemNumber);
              const individualFirstName = res["PensionAccountResponse"]["readPensionAccountResponse"].individual.nameFirst;;
              const individualLastName = res["PensionAccountResponse"]["readPensionAccountResponse"].individual.nameLast;
              this.individualName = individualFirstName + ' ' + individualLastName;
              this.storedResponseData.changeUser(this.individualName);
              this._globalService.setUserName(this.individualName);
              this.activeScreen.getUserName(this.individualName);
              this.spinner.hide();
            }
          }});
        }, 1000);
      }
    }, 3000);

    this.storedResponseData.currentUser.subscribe(username => this.individualName = username);
    this.openNav = false;
    this.activeScreen.currentScreen.subscribe(screen => this.screen = screen);
    this._headerData.getHeaderData()
      .subscribe(resHeaderData => this.headerData = resHeaderData);

    this._headerData.getDashboardLinks()
      .subscribe(resDashboardLinks => {
        this.dashboardLinks = resDashboardLinks;
        console.log("dahsboard link", this.dashboardLinks)
      });
    this.router.events.subscribe((val) => {
      if (this.currLoc.path() === '' || this.currLoc.path() === '/Dashboard' || this.currLoc.path() === '/home' ||
        this.currLoc.path().indexOf('?code=') === 0 || this.currLoc.path() === '/nondiscriminationNotice'
        || this.currLoc.path() === '/privacyCenter' || this.currLoc.path() === '/terms'
        || this.currLoc.path() === '/language-assistance' || this.currLoc.path() === '/unauthorized-user') {
        this.showSubHeader = false;
      } else {
        this.showSubHeader = true;
      }
    });
  }

  logoutFromApp() {
    //this._globalService.setCookie('logged_in', Date.now().toString());
    localStorage.setItem('logged_in', Date.now().toString());
    $('#mainContainer').removeClass('menuSlide');
    $('body').removeClass('overHide');
    //this._globalService.deleteCookie("accessToken");
    sessionStorage.removeItem("accessToken");
    this._globalService.deleteCookie("id_token");
    this._globalService.deleteCookie("sessionStorage");
    this.authService.getAuthToken();
  }

  navigate(to) {
    if (to || to === '') {
      this.router.navigateByUrl(to);
      this.menuHighlight(to);
      this.openNav = false;
      $('#mainContainer').removeClass('menuSlide');
      $('body').removeClass('overHide');
    } else {
    }
  }

  menuHighlight(menu: string) {
    if (menu === '' || menu.indexOf('?code=') >= 0) { menu = 'home'; }
    $('.menuList').removeClass('menuActive');
    $('#' + menu).addClass('menuActive');
  }

  openSideNav(e) {
    this.openNav ? this.openNav = false : this.openNav = true;
    if (this.openNav) {
      $('#mainContainer').addClass('menuSlide');
      this.menuHighlight(this.currLoc.path().replace('/', ''));
      $('body').addClass('overHide');
    } else {
      $('#mainContainer').removeClass('menuSlide');
      $('body').removeClass('overHide');
    }
  }

  font14(event, fontchng) { (document.body).setAttribute('class', 'font14'); this.underline('font14'); }
  font16(event) { (document.body).setAttribute('class', 'font16'); this.underline('font16'); }
  font18(event) { (document.body).setAttribute('class', 'font18'); this.underline('font18'); }

  underline(fontValue) {
    $('.fonts').addClass('underline');
    $('#' + fontValue).removeClass('underline');
  }
  userIsLogged() {
    let currentPath = this.currLoc.path();
    if (sessionStorage.getItem("accessToken") && (this.currLoc.path() !== "''" && this.currLoc.path().search("/?code") == -1)) {
      //if(this._globalService.getCookie("accessToken")){
      return true;
    } else {
      return false;
    }
  }

  skipToMainContent() {
    $('.maincontent').focus();
  }
}

