import { Component, OnInit } from '@angular/core';
import { PrivacyService } from '../services/privacy.service';
import { GlobalService } from '../../global.service';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { environment } from '../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-privacy-center',
  templateUrl: './../views/privacy-center.component.html',
  styleUrls: ['./../views/privacy-center.component.scss'],
  providers: [PrivacyService]
})
export class PrivacyCenterComponent implements OnInit {
  privacyData: any = [];
  registrationData;
  getUsernameData;
  qaEnv;
  private sharedLoc = environment.sharedLoc;
  constructor(private _privacyService: PrivacyService,
    private _globalService: GlobalService, private apiServiceCall: ApiServiceCall,
    private storedResponseData: StoredResponseDataService, private titleService: Title,
    private spinnerService: Ng4LoadingSpinnerService, private _router: Router, private activeScreen: ActiveScreenService) { }

  ngOnInit() {
    this.titleService.setTitle('Large Case Pensions Payee Services - Privacy Center');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Privacy Center');
    if (this.sharedLoc === 'https://qamobile.aetna.com/appConfig/PensionPortal') {
      this.qaEnv = true;
    } else {
      this.qaEnv = false;
    }
    this._privacyService.getPrivacyData()
    .add(resPrivacyData => this.privacyData = resPrivacyData);
    const individualName = this._globalService.getUserName();
    this.storedResponseData.changeUser(individualName);
  }
}
