import { Component, OnInit } from '@angular/core';
import { ActiveScreenService } from './../services/activeScreen/active-screen.service';
import { AuthService } from './../shared/services/auth.service';
import { GlobalService } from './../global.service';

declare var $: any;
@Component({
  selector: 'app-unexpected-error-page',
  templateUrl: './unexpected-error-page.component.html',
  styleUrls: ['./unexpected-error-page.component.scss']
})
export class UnexpectedErrorPageComponent implements OnInit {
  pensionErrorMsg = "";
  pensionErrorFlag;
  constructor(private _globalService: GlobalService, private activeScreen: ActiveScreenService, private authService: AuthService) { }

  ngOnInit() {
    //this.activeScreen.changeMessage('Unexpected Error Occurred');
    this.activeScreen.currentMsg.subscribe(pensionErrorMsg => this.pensionErrorMsg = pensionErrorMsg);
    this.pensionErrorFlag =  true;
    this.activeScreen.changeMessage('Unexpected Error Occurred');
    this._globalService.setPensionErrorFlag(true);
    this.activeScreen.timeOutSession(true);
  }
  logoutFromApp(){
  //this._globalService.setCookie('logged_in', Date.now().toString());
  localStorage.setItem('logged_in', Date.now().toString());
    $('#mainContainer').removeClass('menuSlide');
    $('body').removeClass('overHide');
    //this._globalService.deleteCookie("accessToken");
    sessionStorage.removeItem("accessToken");
    this._globalService.deleteCookie("id_token");
    this._globalService.deleteCookie("sessionStorage");
    this.authService.getAuthToken();
  }
}
