import { FormGroup } from '@angular/forms';

export class CompareRoutingNoValidator {
    static validate(routingFormGroup: FormGroup) {
        const routingNo = routingFormGroup.controls.routingNo.value;
        const retypeRoutingNo = routingFormGroup.controls.retypeRoutingNo.value;

        if (retypeRoutingNo.length <= 0) {
            return null;
        }

        if (retypeRoutingNo !== routingNo) {
            return {
                doesMatchRoutingNo: true
            };
        }

        return null;

    }
}
export class CompareAccountNoValidator {
    static validate(accountNoFormGroup: FormGroup) {
        const accNo = accountNoFormGroup.controls.accNo.value;
        const retypeAccNo = accountNoFormGroup.controls.retypeAccNo.value;

        if (retypeAccNo.length <= 0) {
            return null;
        }

        if (retypeAccNo !== accNo) {
            return {
                doesMatchAccNo: true
            };
        }

        return null;

    }
}
