import { Component, OnInit } from '@angular/core';
import { AuthService } from './../shared/services/auth.service';
import { HostListener } from '@angular/core';
import { GlobalService } from './../global.service';
declare var $: any;

@Component({
  selector: 'app-unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrls: ['./unauthorized-user.component.scss']
})
export class UnauthorizedUserComponent implements OnInit {

  constructor(private authService: AuthService, private _globalService: GlobalService) { }
  ngOnInit(){}
  loginClick() {
    sessionStorage.clear();
  }

  logoutFromApp(){
    $('#mainContainer').removeClass('menuSlide');
    $('body').removeClass('overHide');
    //this._globalService.deleteCookie("accessToken");
    sessionStorage.removeItem("accessToken");
    this._globalService.deleteCookie("id_token");
    this._globalService.deleteCookie("sessionStorage");
    this.authService.getAuthToken();
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.logoutFromApp();
  }
}
