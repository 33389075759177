import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-language-assistance',
  templateUrl: './language-assistance.component.html',
  styleUrls: ['./language-assistance.component.scss']
})
export class LanguageAssistanceComponent implements OnInit {
  private sharedLoc = environment.sharedLoc;
  qaEnv;
  constructor() { }

  ngOnInit() {
    if (this.sharedLoc === 'https://qamobile.aetna.com/appConfig/PensionPortal') {
      this.qaEnv = true;
    } else {
      this.qaEnv = false;
    }
  }

}
