// Below is the V10 block that will be used for V10 onprem in PROD
export const environment = {
  production: true,
  envName: "prod",
  Env: "https://api01.aetna.com",
  path: "/healthcare/prod/v3/pensionholder/",
  Env_new: "https://api01.aetna.com",
  path_new: "/healthcare/prod/v3/lcp_pensionholder/",
  redirectURI: "https://pensions.aetna.com",
  getTaxDoc_Path: "https://www30.aetna.com",
  getTaxDoc_Path_External: "https://www30.aetna.com",
  getNewTax_Path: "/DocumentInquiryII/V01.00/restservices/getDocument?doc=",
  taxDoc_Path: "/healthcare/prod/v3/lcp_communicationcontents",
  authURL:
    "https://api01.aetna.com/healthcare/prod/v1/auth/oauth2/imp/authorize",
  tokenURL: "https://api01.int.aetna.com/healthcare/prod/v5/auth/oauth2/token",
  client_id: "dea3dc15-2c3b-489f-bdb4-b47e0969b302",
  client_secret: "nL5fL2xE0nY1vL3uM5eH2mJ4lH1vF0nN7kU8uL5iE1rM3xO4cR",
  sharedLoc: "https://mobile.aetna.com/appConfig/PensionPortal",
  logoLink: "https://member.aetna.com",
  httpTimeout: 15,
};

// export const environment = {
//   production: true,
//   envName: "prod",
//   Env: "https://apih1.aetna.com",
//   path: "/healthcare/prod/v3/pensionholder/",
//   Env_new: "https://apih1.aetna.com",
//   path_new: "/healthcare/prod/v3/lcp_pensionholder/",
//   redirectURI: "https://pensions.aetna.com",
//   getTaxDoc_Path: "https://www30.aetna.com",
//   getTaxDoc_Path_External: "https://www30.aetna.com",
//   getNewTax_Path: "/DocumentInquiryII/V01.00/restservices/getDocument?doc=",
//   taxDoc_Path: "/healthcare/prod/v3/lcp_communicationcontents",
//   //authURL: "https://apih1.int.aetna.com/healthcare/path1/v5/auth/oauth2/authorize",
//   authURL:
//     "https://apih1.aetna.com/healthcare/prod/v1/auth/oauth2/imp/authorize",
//   tokenURL: "https://apih1.int.aetna.com/healthcare/prod/v5/auth/oauth2/token",
//   client_id: "dea3dc15-2c3b-489f-bdb4-b47e0969b302",
//   client_secret: "nL5fL2xE0nY1vL3uM5eH2mJ4lH1vF0nN7kU8uL5iE1rM3xO4cR",
//   sharedLoc: "https://mobile.aetna.com/appConfig/PensionPortal",
//   logoLink: "https://member.aetna.com",
//   httpTimeout: 15,
// };
