export class Address {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  zip4: string;
  unsuccessMsg: string;
}

export class Maddress {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  zip4: string;
}