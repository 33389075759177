import { Injectable } from '@angular/core';
import { GlobalService } from '../../global.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class ErrorCheckService {
  userIsAuthorized = false;
  pensionErrorFlag = true;

   constructor(private _globalService: GlobalService, private spinnerService: Ng4LoadingSpinnerService,
    private activeScreen: ActiveScreenService, private _router: Router) {
  }

  checkErrors(res){
  if(res.status.additionalStatus[0].statusCode === "13"){
    /* This check is for unauthorized user tries to login to application then user will see the unauthorized window */
    this.userIsAuthorized = false;
    this.pensionErrorFlag = true;
    this.activeScreen.timeOutSession(true);
    this._globalService.setIsAuthorizedUser(false);
    this._router.navigateByUrl("unauthorized-user");    
   }
   else if(res.status.additionalStatus[0].statusCode === "55"){
    /* This check is for system is down for routine maintanance */
    this._router.navigateByUrl("error");
   }else if(res.status.additionalStatus[0].statusCode != "0"){
    /* This check is for any unexpected error occurred */
    setTimeout(() => {
        this.spinnerService.hide();
        this.activeScreen.timeOutSession(true);
        this.activeScreen.getPensionMsg(res.status.additionalStatus[0].detail);
        this.pensionErrorFlag = true;
        this._router.navigateByUrl("unexpectedErrorPage");
    }, 1000);
   } else {
    /* If there is no error in the response i.e. response status code is 0 then application will load successfully */ 
    this.activeScreen.timeOutSession(false);
    this._globalService.setIsAuthorizedUser(true);
    this.userIsAuthorized = true;
   } 
}
}
