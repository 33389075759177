import { Router, ActivatedRoute } from '@angular/router';
import { DashboardService } from './../services/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../../shared/services/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { GlobalService } from '../../global.service';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { StoredResponseDataService } from '../../services/storedResponseData/stored-response-data.service';
import { ActiveScreenService } from '../../services/activeScreen/active-screen.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ErrorCheckService } from '../../shared/services/errorCheck.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './../views/dashboard.component.html',
  styleUrls: ['./../views/dashboard.component.scss'],
  providers: [DashboardService, Location]
})
export class DashboardComponent implements OnInit {
  addressDataObj;
  individualName;
  apiSuccess = false;
  dashboardData :any = [];
  registrationData: {};
  isUserLoggedin = false;
  userIsAuthorized = false;
  pensionErrorFlag : boolean = false;
  pensionErrorMsg;
  private sharedLoc = environment.sharedLoc;
  qaEnv;
  constructor(private _dashboardService: DashboardService, private route: ActivatedRoute, private _router: Router, 
    private authService: AuthService, private spinnerService: Ng4LoadingSpinnerService,
    private _globalService: GlobalService, private storedResponseData: StoredResponseDataService,
    private activeScreen: ActiveScreenService, private apiServiceCall: ApiServiceCall, private titleService: Title,
  private errorCheckService: ErrorCheckService) {}

  ngOnInit() {    
    
  setTimeout( () => {
    this.isUserLoggedin = this._globalService.userIsLogged();
    this.userIsAuthorized = this._globalService.getIsAuthorizedUser();
    
    this.apiServiceCall.getSystemNumber().subscribe(res=>{

      console.log("dashboard response is", res);
      if(res['PensionAccountResponse'].status.statusCode != 0){        
        //this._globalService.deleteCookie('accessToken');
        sessionStorage.removeItem('accessToken');
        this._router.navigateByUrl("unexpectedErrorPage");
        
      }else{
      this.errorCheckService.checkErrors(res["PensionAccountResponse"]);
      this.userIsAuthorized = true;
    }});
    }, 2000);
    
    this.activeScreen.currentMsg.subscribe(pensionErrorMsg => this.pensionErrorMsg = pensionErrorMsg);
    this.titleService.setTitle('Large Case Pensions Payee Services - Home');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Home');
    this.activeScreen.changeMessage('Home');
    if (this.sharedLoc === 'https://qamobile.aetna.com/appConfig/PensionPortal') {
      this.qaEnv = true;
    } else {
      this.qaEnv = false;
    }
    
    this.pensionErrorFlag = this._globalService.getFederalErrorFlag();
    this.pensionErrorMsg = this._globalService.getPensionErrorMsg();
    
    
    if(this.pensionErrorFlag){
      this.pensionErrorFlag = true;
    }//
    this._dashboardService.getDashboardData()
     .subscribe(resDashboardData => this.dashboardData = resDashboardData);
     
    }
   
  navigate(to) {
    this._router.navigateByUrl(to);
  }

  callAuthToken() {
    
    this.authService.getAuthToken();
  }
  typeof(msg) {
    if (msg) {
      return true;
    }
    return false;
  }
}
