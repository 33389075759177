import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class HeaderService {
  private _textUrl = '../../pensionportalUI/assets/textsData/header.json';
  private _dbTextUrl = '../../pensionportalUI/assets/textsData/dashboard.json';
   constructor(private _http:  HttpClient) {
  } 

  getHeaderData() {
    return this._http.get(this._textUrl)
    .pipe(map((response: Response) => response));
  }


  getDashboardLinks() {
    return this._http.get(this._dbTextUrl)
    .pipe(map((response: Response) => response));
  }

}
