export class Payment{
dueDate : string;
grossAmt : string;
federalAmt : string;
stateAmt : string;
otherDeductions : string;
netAmt :string;
paymentType :string;
bankName :string;
check :string;
outstandingFlag : boolean;
deductionArray = [];
}

export class Paymentdeduction{
    otherDeductionName : string;
    otherDeductionAmount : string;
    }