import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber', 
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: any, args: any): any {
      if (isNaN(args) || args < 1) {
          return String(value).replace(/./g, '*');
      }
      const mask = RegExp('(.{1,' + args + '}$)|.', 'g');
      return String(value).replace(mask, function(hide, show) {
          return show || '*';
      });
  }

}

