import { Address } from './information/update/address';
import { Injectable } from '@angular/core';
import { Contact } from './information/contact-info/contact';
import { StateElection } from './state/stateElection';
import { FederalElection } from './federal/federalElection';
import { PaymentMethod } from './information/payment-method/payment-method';
import { Location } from '@angular/common';
import { Payment } from './payment-history/payment';
import { TaxStatement } from './../app/tax-statements/tax-statements';
@Injectable()
export class GlobalService{
  goToTab : string = "viewAddress";
  resAdd: Address=new Address();
  mailAdd: Address=new Address();
  stateElection : StateElection = new StateElection();
  paymentMethod: PaymentMethod = new PaymentMethod();
  federalElection : FederalElection = new FederalElection();
  paymentHistory : Payment = new Payment(); 
  taxStatement : TaxStatement = new TaxStatement(); 
  updateSuccess:boolean=false;
  contactInfo: Contact = new Contact();
  isFromUpdate: boolean = false;
  activateRedirect: true;
  emailflow: boolean= false;
  constructor(private currLoc: Location) { }
  
  setEmailPhoneFlag=function(v){
    this.emailPhoneFlag=v;
  }
  getEmailPhoneFlag=function(){
    return this.emailPhoneFlag;
  }

  setTaxStatement=function(v){
    this.taxStatement=v;
  }
  getTaxStatement=function(){
    return this.taxStatement;
  }
  setUserName= function(value){
    this.userName = value;
  }
  getUserName = function(){
    return this.userName;
  }
  setSameAddressFlag= function(value){
    this.sameAddressFlag = value;
  }
  getSameAddressFlag = function(){
    return this.sameAddressFlag;
  }
  setPaymentHistory= function(value){
    this.paymentHistory = value;
  }
  getPaymentHistory = function(){
    return this.paymentHistory;
  }
  setIsFromUpdate = function(value){
    this.isFromUpdate = value;
  }
  getIsFromUpdate = function(){
      return this.isFromUpdate;
  }
  setGoTo = function(value){
    this.goToTab = value;
  }
  getGoTo = function(){
      return this.goToTab;
  }
  setResAdd=function(v){
    this.resAdd=v;
  }
  getResAdd=function(){
    return this.resAdd;
  }
  setMailAdd=function(v){
    this.mailAdd=v;
  }
  getMailAdd=function(){
    return this.mailAdd;
  }
  getUpdateSuccess=function(){
    return this.updateSuccess;
  }
  setUpdateSuccess=function(value){
    this.updateSuccess=value;
  }
  setContactInfo=function(v){
    this.contactInfo=v;
  }
  getContactInfo=function(){
    return this.contactInfo;
  }
  setRegistrationData=function(v){
    this.registrationData=v;
  }
  getRegistrationData=function(){
    return this.registrationData;
  }
  setRegistrationPayload=function(v){
    this.registrationPayload=v;
  }
  getRegistrationPayload=function(){
    return this.registrationPayload;
  }
  setContactRegistrationPayload=function(v){
    this.contactregistrationPayload=v;
  }
  getContactRegistrationPayload=function(){
    return this.contactregistrationPayload;
  }
  setState=function(v){
    this.state=v;
  }
  getState=function(){
    return this.state;
  }
  setNonResidentFlag=function(v){
    this.nonResidentFlag=v;
  }
  getNonResidentFlag=function(){
    return this.nonResidentFlag;
  }
  setNonResidentResData=function(v){
    this.nonResidentResData=v;
  }
  getNonResidentResData=function(){
    return this.nonResidentResData;
  }
  setNonResidentMailData=function(v){
    this.nonResidentMailData=v;
  }
  getNonResidentMailData=function(){
    return this.nonResidentMailData;
  }
  setIndividualName=function(v){
    this.individualName=v;
  }
  getIndividualName=function(){
    return this.individualName;
  }
  setSameResMailFlag = function(value){
    this.sameResMailFlag = value;
  }
  getSameResMailFlag = function(){
      return this.sameResMailFlag;
  }
  setPaymentMethod = function(value) {
    this.paymentMethod = value;
  };
  getPaymentMethod = function() {
      return this.paymentMethod;
  };
  setStateElection = function(value){
    this.stateElection = value;
  }
  getStateElection = function(){
      return this.stateElection;
  }
  setFederalElection = function(val){
    this.federalElection = val;
  }
  getFederalElection = function(){
     return this.federalElection;
  }
  setUpdateStateStatus = function(val){
    this.updateStateStatus = val;
  }
  getUpdateStateStatus = function(){
     return this.updateStateStatus;
  }
  setUpdateFederalStatus = function(val){
    this.updateFederalStatus = val;
  }
  getUpdateFederalStatus = function(){
     return this.updateFederalStatus;
  }

  setStateErrorFlag = function(val){
    this.stateErrorFlag = val;
  }
  getStateErrorFlag = function(){
     return this.stateErrorFlag;
  }


  setFederalErrorFlag = function(val){
    this.federalErrorFlag = val;
  }
  getFederalErrorFlag = function(){
     return this.federalErrorFlag;
  }
  setStateEditMsg = function(val){
    this.stateEditMsg = val;
  }
  getStateEditMsg = function(){
     return this.stateEditMsg;
  }

  setFederalEditMsg = function(val){
    this.federalEditMsg = val;
  }
  getFederalEditMsg = function(){
     return this.federalEditMsg;
  }
setCommonFlag = function(flag){
  this.commonData = flag;
}
getCommonflag= function(){
  return this.commonData;
}
setPensionErrorFlag = function(val){
  this.pensionErrorFlag = val;
}
getPensionErrorFlag = function(){
   return this.pensionErrorFlag;
}
setPensionErrorMsg = function(flag){
this.pensionErrorMsg = flag;
}
getPensionErrorMsg= function(){
return this.pensionErrorMsg;
}
setAccountState = function (flag) {
  this.accountState = flag;
}
getAccountState = function () {
  return this.accountState;
}
setAccountStatusChangeReason = function (flag) {
  this.accountStatusChangeReason = flag;
}
getAccountStatusChangeReason = function () {
  return this.accountStatusChangeReason;
}
setSystemNumber = function (flag) {
  this.systemNumber = flag;
}
getSystemNumber = function () {
  return this.systemNumber;
}
setIsAuthorizedUser = function (flag) {
  this.isAuthorizedUser = flag;
}
getIsAuthorizedUser = function () {
  return this.isAuthorizedUser;
}
setActivateRedirect = function(flag){
  this.activateRedirect = flag;
}
getActivateRedirect = function(){
  return this.activateRedirect;
}
// added for Federal change
setFedWithholdingData = function(data){
  this.fedWithHoldingData = data;
}
getFedWithholdingData = function(){
  return this.fedWithHoldingData;
}

  userIsLogged(){
    let currentPath = this.currLoc.path();
    if(sessionStorage.getItem("accessToken") && (this.currLoc.path() !== "''" && this.currLoc.path().search("/?code") == -1)){
      //if(this.getCookie("accessToken")){
      return true;
    } else  {
      return false;
    }   
  }

  //changes added for replacing localstorage / session storage from cookies - start
  setCookie(name, value) {
    document.cookie = name + "=" + (value || "")  + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  
  deleteCookie( name ) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  // end of changes
}

//deleting cooking on browser tab close
// window.onbeforeunload = function () {
//   document.cookie = "id_token" + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
//   document.cookie = "Nonce" + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
//   document.cookie = "accessToken" + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
//   document.cookie = "sessionStorage" + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  
// };