import { GlobalErrorHandler } from './GlobalErrorHandler';
import { HelpComponent } from './help/components/help.component';
import { HelpModule } from './help/help.module';
import { NondiscriminationNoticeModule } from './nondiscrimination-notice/nondiscrimination-notice.module';
import { PrivacyCenterModule } from './privacy-center/privacy-center.module';
import { TermsOfUseComponent } from './terms-of-use/components/terms-of-use.component';
import { RouterModule } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { TermsOfUseModule } from './terms-of-use/terms-of-use.module';
import { SharedModule } from './shared/shared.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { MatTableModule, MatSortModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { AppComponent } from './app.component';
import { InformationComponent } from './information/information.component';
import { ViewComponent } from './information/view/view.component';
import { UpdateComponent } from './information/update/update.component';
import { GlobalService } from './global.service';
import { ContactInfoComponent } from './information/contact-info/contact-info.component';
import { PrivacyCenterComponent } from './privacy-center/components/privacy-center.component';
import { NondiscriminationNoticeComponent } from './nondiscrimination-notice/components/nondiscrimination-notice.component';
import { ContactUsComponent } from './contact-us/components/contact-us.component';
import { ContactUsModule } from './contact-us/contact-us.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DashboardComponent } from './dashboard/components/dashboard.component';
import { GetSystemNumberModule } from './getSystemNumber/getSystemNumber.module';
import { GetSystemNumberComponent } from './getSystemNumber/components/getSystemNumber.component';
import { ErrorComponent } from './shared/components/error.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { TaxStatementsComponent } from './tax-statements/tax-statements.component';
import { StateComponent } from './state/state.component';
import { PaymentMethodComponent } from './information/payment-method/payment-method.component';


import {ApiServiceCall} from './shared/services/apiCall.service';

import { AuthService } from './shared/services/auth.service';
import { ApiService } from './shared/services/apiService.service';
import { ClientActiveService } from './shared/services/clientActiveService.service';
import { DownloadPdfServiceService } from './services/downloadPdf/download-pdf-service.service';
import { ActiveScreenService } from './services/activeScreen/active-screen.service';
import { StoredResponseDataService } from './services/storedResponseData/stored-response-data.service';
import { LanguageAssistanceComponent } from './language-assistance/language-assistance.component';
import { FederalComponent } from './federal/federal.component';
import { UpdateFedralComponent } from './federal/update-federal/update-fedral.component';
import { StateElectionUpdateComponent } from './state-election-update/components/state-election-update.component';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { FormatPhonePipe } from './pipes/formatphone.pipe';
import { DownloadFormsComponent } from './download-forms/download-forms.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { IncomeDocumentComponent } from './income-document/components/income-document.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { ProfileComponent } from './profile/profile.component';
import { InsertSpacePipe } from './pipes/insert-space/insert-space.pipe';
import {MatTabsModule} from '@angular/material/tabs';
import {ExcelService} from './shared/services/ExcelService';
import { UnauthorizedUserComponent } from './unauthorized-user/unauthorized-user.component';
import { TimeoutErrorPageComponent } from './timeout-error-page/timeout-error-page.component';
import { UnexpectedErrorPageComponent } from './unexpected-error-page/unexpected-error-page.component';
import { ErrorCheckService } from './shared/services/errorCheck.service';
import { AppInitService } from './app-init.service';

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.Init();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    InformationComponent,
    ViewComponent,
    UpdateComponent,
    ContactInfoComponent,
    ErrorComponent,
    PaymentHistoryComponent,
    TaxStatementsComponent,
    StateComponent,
    LanguageAssistanceComponent,
    FederalComponent,
    UpdateFedralComponent,
    StateElectionUpdateComponent,
    PaymentMethodComponent,
    FormatNumberPipe,
    FormatPhonePipe,
    DownloadFormsComponent,
   IncomeDocumentComponent,
   ProfileComponent,
   InsertSpacePipe,
   UnauthorizedUserComponent,
   TimeoutErrorPageComponent,
   UnexpectedErrorPageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TextMaskModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TermsOfUseModule,
    PrivacyCenterModule,
    NondiscriminationNoticeModule,
    HelpModule,
    ContactUsModule,
    DashboardModule,
    GetSystemNumberModule,
    MatTableModule,
    MatSortModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    MatTabsModule,
    PopoverModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    RouterModule.forRoot([{path: 'contact-us', component: ContactUsComponent},
    {path: 'information', component: InformationComponent},
    {path: 'paymentHistory', component: PaymentHistoryComponent},
    {path: 'taxStatements', component: TaxStatementsComponent},
    {path: 'incomeDocuments', component: IncomeDocumentComponent},
    {path: 'privacyCenter', component: PrivacyCenterComponent},
    {path: 'terms', component: TermsOfUseComponent},
    {path: '', component: GetSystemNumberComponent},
    {path: 'home', component: DashboardComponent},
    {path: 'nondiscriminationNotice', component: NondiscriminationNoticeComponent},
    {path: 'help', component: HelpComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'language-assistance', component: LanguageAssistanceComponent},
    {path: 'downloadForms', component: DownloadFormsComponent},
    {path: 'unauthorized-user', component: UnauthorizedUserComponent},
    {path: 'timeoutErrorPage', component: TimeoutErrorPageComponent},
    {path: 'unexpectedErrorPage', component: UnexpectedErrorPageComponent},
    
    // {path: '**', component: ErrorComponent}
    {path: '**', component: UnexpectedErrorPageComponent}
    
    ], {onSameUrlNavigation: 'reload', useHash: true}) //
  ],
  exports: [RouterModule], // ExcelService
  providers: [CurrencyPipe, GlobalService, ExcelService, DownloadPdfServiceService, ApiServiceCall, ClientActiveService, ActiveScreenService, StoredResponseDataService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    AppInitService,
    { provide: APP_INITIALIZER,useFactory: initializeApp1, deps: [AppInitService], multi: true}, //intial environment
    AuthService, ApiService, ErrorCheckService, 
    {provide: LocationStrategy, useClass: HashLocationStrategy} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
