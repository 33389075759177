import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor(private _pipe: CurrencyPipe) { }

  public exportAsExcelFile(json: any[], excelFileName: string, headerList, userName, selectedYear): void {
    const headerA = headerList[0];
    const headerB = headerList[1];
    const headerC = headerList[2];
    const headerD = headerList[3];
    const headerE = headerList[4];
    const headerF = headerList[5];
    const headerG = headerList[6];
    const headerH = headerList[7];
    const headerI = headerList[8];

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('data', { pageSetup: { paperSize: 9, orientation: 'landscape' } });

    // Setting column width 
    worksheet.columns = [{ key: 'A', width: 15 }, { key: 'B', width: 15 }, { key: 'C', width: 15 },
    { key: 'D', width: 15 }, { key: 'E', width: 12 }, { key: 'F', width: 14 }, { key: 'G', width: 12 }, { key: 'H', width: 21 }, { key: 'I', width: 12 }];

    //Title Row 
    const titleRow = worksheet.addRow(["Aetna Large Case Pensions"]);
    titleRow.font = { name: 'Calibri', size: 12, bold: true };
    titleRow.alignment = { vertical: 'justify', horizontal: 'left', wrapText: true };
    worksheet.mergeCells('A1:I1');

    //Payee Details 
    const payeeNameRow = worksheet.addRow(["Payee Name : " + userName]);
    payeeNameRow.font = { name: 'Calibri', size: 12 };
    payeeNameRow.alignment = { vertical: 'justify', horizontal: 'left', wrapText: true };
    worksheet.mergeCells('A2:I2');

    // Payment history year
    const paymentYearRow = worksheet.addRow(["Payment History For the Year : " + selectedYear]);
    paymentYearRow.font = { name: 'Calibri', size: 12 };
    paymentYearRow.alignment = { vertical: 'justify', horizontal: 'left', wrapText: true };
    worksheet.mergeCells('A3:I3');

    //blank row
    worksheet.addRow([]);

    //Table header
    const headerRow = worksheet.addRow([headerA, headerB, headerC, headerD, headerE, headerF, headerG, headerH, headerI]);

    // Table header formatting
    headerRow.font = { name: 'Calibri', size: 12, bold: true };
    headerRow.eachCell((cell, number) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    });

    /// Table data 
    json.forEach(data => {
      const dataRow = worksheet.addRow([(data.dueDate),
      this._pipe.transform(parseFloat(data.grossAmount), 'USD'),
      this._pipe.transform(parseFloat(data.federalAmt), 'USD'),
      this._pipe.transform(parseFloat(data.stateAmt), 'USD'),
      this._pipe.transform(parseFloat(data.otherDeductions), 'USD'),
      this._pipe.transform(parseFloat(data.netAmt), 'USD'),
      data.paymentType, data.bankName, data.check]);

      dataRow.font = { name: 'Calibri', size: 12 };

      dataRow.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
      let cellIndex;
      for (cellIndex = 1; cellIndex <= 9; cellIndex++) {
        if (cellIndex > 1 && cellIndex <= 6) {
          dataRow.getCell(cellIndex).alignment = { vertical: 'bottom', horizontal: 'right', wrapText: true };
        } else {
          dataRow.getCell(cellIndex).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }
      }
      dataRow.numFmt = "$#,##0.00";
      //Grand total row 
      if (data.dueDate == "Grand Total for the Year") {
        let x = dataRow.number;
        const cellNoA = 'A' + x;
        const cellNoI = 'I' + x;
        const mergeString: string = cellNoA + ':' + cellNoI;
        worksheet.mergeCells(mergeString);
        dataRow.font = { bold: true };
        dataRow.alignment = { vertical: 'justify', horizontal: 'left', wrapText: true };
      }
    })
    workbook.xlsx.writeBuffer().then((data: any) => {const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }
}
