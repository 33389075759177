import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { GlobalService } from '../../global.service';
import { timer } from 'rxjs/internal/observable/timer';
@Injectable()
export class AuthService {
  private authURL: string=environment.path;
  private tokenURL: string=environment.tokenURL;
  private authToken: string= "";
  private accessToken: string= "";
  private client_id: string = environment.client_id;
  private client_secret: string = environment.client_secret;
  private authTokenInUrl: string;
  private redirectURI: string = environment.redirectURI;
  private nonce: string = "";
  private id_token: string = "";
  accountStatusReason;
  goTo = this._globalService.getGoTo();
  constructor(private http:HttpClient, private route: ActivatedRoute, private _router: Router, private apiServiceCall: ApiServiceCall, 
    private spinnerService: Ng4LoadingSpinnerService, private _globalService: GlobalService) { } 
 
  getAuthToken(){
    if(this.getNonce()){
      this.deleteNonce();
    }
    this.setNonce();
    
    console.log("Auth url"+environment.authURL + '?&response_type=token&client_id='+ this.client_id +'&redirect_uri='+ this.redirectURI +'&scope=PII&nonce='+ this.nonce);
    if(this._globalService.emailflow){
      window.location.href = environment.authURL + '?&response_type=token&client_id='+ this.client_id +'&redirect_uri='+ this.redirectURI +'&scope=Public NonPII PII PHI openid&nonce='+ this.nonce+'&appname=PP&branding=signIn';
    }else{
      window.location.href = environment.authURL + '?&response_type=token&client_id='+ this.client_id +'&redirect_uri='+ this.redirectURI +'&scope=Public NonPII PII PHI openid&nonce='+ this.nonce+'&appname=PP';
    }
     
  }

  getTokenFromStorage(tokenName: string): string {
    return this._globalService.getCookie(tokenName);
  }

  setToken(tokenName: string, value: string, expiryTime: number): void {
    let todayDate = new Date();
    let expirtDate = todayDate.setHours(todayDate.getHours() + 1);
    let d1 = new Date (),
    d2 = new Date ( d1 );
    d2.setMinutes( d1.getMinutes() + 28);
    let newTokenToSet = {"tokenValue":value, "expireOn": d2.toString()}
    //this._globalService.setCookie(tokenName, JSON.stringify(newTokenToSet));
    sessionStorage.setItem(tokenName, JSON.stringify(newTokenToSet));
  }

  isTokenExpired(token?: string): boolean {
    if(!token) { token = this.getTokenFromStorage('authToken'); }
    if(!token) { return true; }
    
    let parsedToken = JSON.parse(token);
    let expirationTime = parsedToken.expireOn;
    return (new Date(expirationTime).valueOf() < new Date().valueOf());
  }

  checkToken(isFromInterval) {
    //Check Tokens are present in local storate otherwiser set it...
    /* When user come to dashboard check if access token is present and not expired in local storage */
    //let accessTokenTemp = this.getTokenFromStorage("accessToken");
    let accessTokenTemp = sessionStorage.getItem('accessToken');

    if(accessTokenTemp ){
        this.accessToken = JSON.parse(accessTokenTemp).tokenValue;
        /***********************************************************/
        this.route.queryParams.subscribe(map => map);
          let url = window.location.href;
          //let results = new RegExp('[\\?&]' + 'access_token' + '=([^&#]*)').exec(window.location.href);
          var hash = window.location.hash.substr(1);
          if(hash.indexOf("access_token") >= 0){
          var results = hash.split('&').reduce(function (result, item) {
              var parts = item.split('=');
              result[parts[0]] = parts[1];
              return result;
          }, {});
        }
          if(results){
              let storedNonce = this._globalService.getCookie('Nonce');
              this.accessToken = results['access_token'];
              this.id_token = results['id_token'];
              let decryptedIdTokenArray = this.parseIdToken(this.id_token);
                this._globalService.setCookie("id_token", JSON.stringify(this.id_token));
                this.setToken("accessToken", this.accessToken, results['expires_in']);
                this._globalService.setCookie("id_token_array", JSON.stringify(decryptedIdTokenArray));
                this._globalService.setCookie('sessionStorage', JSON.stringify(sessionStorage));
                console.log("redirecting to home");
                this._globalService.emailflow = false;
                parent.location.hash = "home";
          }
        else if(!accessTokenTemp){
            if(this.isTokenExpired(accessTokenTemp)){
                this.route.queryParams.subscribe(map => map);
                this.removeTokenFromStorage();
                this.getAuthToken();
            }
        }
        else{
            if(this.isTokenExpired(accessTokenTemp)){
              this.removeTokenFromStorage();
              this.getAuthToken();
            }
            else if(this._globalService.getCookie("email_ID")){
              if(this._globalService.getAccountState() !== 'OPEN'){
              //this.verifyEmail();
            }
            }
        }
    }
    else{
      //adding comment
          this.route.queryParams.subscribe(map => map);
          let url = window.location.href;
          //let results = new RegExp('[\\?&]' + 'access_token' + '=([^&#]*)').exec(window.location.href);
          var hash = window.location.hash.substr(1);
          if(hash.indexOf("access_token") >= 0){
          var results = hash.split('&').reduce(function (result, item) {
              var parts = item.split('=');
              result[parts[0]] = parts[1];
              return result;
          }, {});
        }
          if(results){
              //console.log("complete URL"+ url);

              let storedNonce = this._globalService.getCookie('Nonce');
              this.accessToken = results['access_token'];
              this.id_token = results['id_token'];
              /* Decrypt the id_token to get nonce and action and email address values */ 
             // console.log("ID token Received in URL: "+ this.id_token);
              let decryptedIdTokenArray = this.parseIdToken(this.id_token);
              //if(this.validateNonce(storedNonce, decryptedIdTokenArray.nonce)){
                this._globalService.setCookie("id_token", JSON.stringify(this.id_token));
                this.setToken("accessToken", this.accessToken, results['expires_in']);
                this._globalService.setCookie("id_token_array", JSON.stringify(decryptedIdTokenArray));
                this._globalService.setCookie('sessionStorage', JSON.stringify(sessionStorage));
                this._globalService.emailflow = false;
                parent.location.hash = "home";
                if(this._globalService.getCookie("email_ID")){
                  if(this._globalService.getAccountState() !== 'OPEN'){
                  
                }
              }
              /*}
              else{
                this.removeTokenFromStorage();
                this.getAuthToken();
              }*/
              
              
              

              //console.log(decryptedIdTokenArray);
            //}
          }
          else{
            this.getAuthToken();
          }
    }
  }

  parseIdToken(token) {
    try {
      // Get Token payload and date's
      const base64Token = token.split('.')[1];
      const TempToken = base64Token.replace('-', '+').replace('_', '/');
      const parsedToken = JSON.parse(window.atob(TempToken));
      return parsedToken;
    } catch (err) {
      return false;
    }
  }

  getParams (str) {
    var queryString = str || window.location.search || '';
    var keyValPairs = [];
    var params      = {};
    queryString     = queryString.replace(/.*?\?/,"");
 
    if (queryString.length)
    {
       keyValPairs = queryString.split('&');
       for (let pairNum in keyValPairs)
       {
          var key = keyValPairs[pairNum].split('=')[0];
          if (!key.length) continue;
          if (typeof params[key] === 'undefined')
          params[key] = [];
          params[key].push(keyValPairs[pairNum].split('=')[1]);
       }
    }
    return params;
 }

  removeTokenFromStorage(){
    //this._globalService.setCookie('logged_in', Date.now().toString());
    localStorage.setItem('logged_in', Date.now().toString());
    //this._globalService.deleteCookie("accessToken");
    sessionStorage.removeItem("accessToken");
    this._globalService.deleteCookie("id_token");
    this._globalService.deleteCookie("Nonce");
    this._globalService.deleteCookie("sessionStorage");
  }

  setTimer(){
    const source = timer(100,10000)
    const subscribe = source.subscribe(
         (x)=>{
           //if(this._globalService.getActivateRedirect()){
            this.checkToken(1);
          //}
        },
        function (err) {
            console.log('Error: ' + err);
        },
        function () {
            console.log('Completed');
        });
    
  }

  createNonce = function() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(var i = 0; i < 32; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  setNonce(){
    this.nonce = this.createNonce();
    this._globalService.setCookie("Nonce", this.nonce);
  }

  getNonce(){
    this.nonce = this._globalService.getCookie("Nonce");
    return this.nonce;
  }

  deleteNonce(){
    this._globalService.deleteCookie("Nonce");
  }

  validateNonce(nonce1: string, nonce2: string){
    return (nonce1 === nonce2) ? true : false;
  }
}
