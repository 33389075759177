import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GlobalService } from '../../global.service';
import { InformationComponent } from '../../information/information.component';
import { StateElection } from '../../state/stateElection';
import { ApiServiceCall } from '../../shared/services/apiCall.service';
import { ActiveScreenService } from './../../services/activeScreen/active-screen.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-state-election-update',
  templateUrl: './../views/state-election-update.component.html',
  styleUrls: ['./../views/state-election-update.component.scss']
})
export class StateElectionUpdateComponent implements OnInit {

  stateElection: StateElection;
  stateForm: FormGroup;
  showStateElectionFlag: boolean;
  electedInFlag: boolean;
  statePayload;
  registrationData;
  addressDataObj;
  witholdingData;//to be removed once service is integrated
  maritalIndex;//to be removed
  maritalList = [];
  dropDownLabel1;
  dropDownLabel2;
  dropDownLabel3;
  dropDownLabel4;
  dropDownLabel1Index;
  dropDownLabel2Index;
  dropDownLabel3Index;
  dropDownLabel4Index;
  dropDownLabelValue1;
  dropDownLabelValue2;
  dropDownLabelValue3;
  dropDownLabelValue4;
  dropDown1 = [];
  dropDown2 = [];
  dropDown3 = [];
  dropDown4 = [];
  currentMaritalStatus;
  payload;
  errorMsg;
  stateEditMsg;
  maritalStatusFlag: boolean = false;
  nbrExemptionsFlag: boolean = false;
  flatWhldingAmtFlag: boolean = false;
  addlWhldingPctFlag: boolean = false;
  addlWhldingAmtFlag: boolean = false;
  taxReductionFlag: boolean = false;
  flatWhldingPctFlag: boolean = false;
  stateSaveClicked: boolean = false;

  constructor(private _formBuilder: FormBuilder, private _globalService: GlobalService,
    private spinnerService: Ng4LoadingSpinnerService, private _infoComponent: InformationComponent,
    private apiServiceCall: ApiServiceCall, private activeScreen: ActiveScreenService, private titleService: Title) { }

  ngOnInit() {
    this.activeScreen.changeMessage('Update My Information');
    this.titleService.setTitle('Large Case Pensions Payee Services - Update State Election');
    this.activeScreen.setTitle('Large Case Pensions Payee Services - Update State Election');
    this.stateElection = this._globalService.getStateElection();
    //console.log(this.stateElection);
    this.stateEditMsg = this._globalService.getStateEditMsg();
    this._globalService.setStateEditMsg('');
    console.log(this.stateEditMsg);
    this.stateFormData();
    history.pushState(null, null, location.href);
    $(window).on('popstate', function (event) {
      history.go(1);
      $('#backBtnModal').modal();
    });

  }

  stateFormData() {
    this.stateElection = this._globalService.getStateElection();
    this.spinnerService.show();
    this.apiServiceCall.getWithholdingData().subscribe(res => {
      this.witholdingData = res['WithholdingsResponse'].readWithHoldingsResponse;
      if (this.witholdingData.maritalStatusRule.currentValue == "Y") {
        this.maritalStatusFlag = true;
      }
      if (this.witholdingData.nbrExemptionsRule.currentValue == "Y") {
        this.nbrExemptionsFlag = true;
      }
      if (this.witholdingData.flatWhldingAmtRule.currentValue == "Y") {
        this.flatWhldingAmtFlag = true;
      }
      if (this.witholdingData.flatWhldingPctRule.currentValue == "Y") {
        this.flatWhldingPctFlag = true;
      }
      if (this.witholdingData.addlWhldingPctRule.currentValue == "Y") {
        this.addlWhldingPctFlag = true;
      }
      if (this.witholdingData.addlWhldingAmtRule.currentValue == "Y") {
        this.addlWhldingAmtFlag = true;
      }
      if (this.witholdingData.addlWhldingAmtRule.currentValue == "Y" && this.stateElection['taxReductionFlag'] == "R") {
        this.taxReductionFlag = true;
      }
      this.maritalIndex = this.witholdingData.maritalStatusKey.currentValue;
      if (this.maritalIndex == 0) {
        this.currentMaritalStatus = '';
      } else {
        this.currentMaritalStatus = this.witholdingData.dropDownList[this.maritalIndex - 1].description;
      }
      this.dropDownLabel1 = this.witholdingData.saeList[0].description;
      this.dropDownLabel2 = this.witholdingData.saeList[1].description;
      this.dropDownLabel3 = this.witholdingData.saeList[2].description;
      this.dropDownLabel4 = this.witholdingData.saeList[3].description;
      this.dropDownLabel1Index = this.witholdingData.saeList[0].value;
      this.dropDownLabel2Index = this.witholdingData.saeList[1].value;
      this.dropDownLabel3Index = this.witholdingData.saeList[2].value;
      this.dropDownLabel4Index = this.witholdingData.saeList[3].value;
      if (!(this.dropDownLabel1Index == 0)) {
        this.dropDownLabelValue1 = this.witholdingData.dropDownList[this.dropDownLabel1Index - 1].description;
      }
      if (!(this.dropDownLabel2Index == 0)) {
        this.dropDownLabelValue2 = this.witholdingData.dropDownList[this.dropDownLabel2Index - 1].description;
      }
      if (!(this.dropDownLabel3Index == 0)) {
        this.dropDownLabelValue3 = this.witholdingData.dropDownList[this.dropDownLabel3Index - 1].description;
      }
      if (!(this.dropDownLabel4Index == 0)) {
        this.dropDownLabelValue4 = this.witholdingData.dropDownList[this.dropDownLabel4Index - 1].description;
      }
      this.maritalList.push('');
      for (let i = 0; i < this.witholdingData.dropDownList.length; i++) {
        if (this.witholdingData.dropDownList[i].value == 2) {
          this.maritalList.push(this.witholdingData.dropDownList[i].description);
        }
        if (this.witholdingData.dropDownList[i].value == 3) {
          this.dropDown1.push(this.witholdingData.dropDownList[i].description);
        }
        if (this.witholdingData.dropDownList[i].value == 4) {
          this.dropDown2.push(this.witholdingData.dropDownList[i].description);
        }
        if (this.witholdingData.dropDownList[i].value == 5) {
          this.dropDown3.push(this.witholdingData.dropDownList[i].description);
        }
        if (this.witholdingData.dropDownList[i].value == 6) {
          this.dropDown4.push(this.witholdingData.dropDownList[i].description);
        }
      }
      this.showState();
      this.spinnerService.hide();
    })


  }

  showState() {
    //console.log(this.stateElection, "inside show state");
    if (this.stateElection.stateWithholdingStatus === "Elected In") {
      this.electedInFlag = false;
      this.showStateElectionFlag = true;
    } else {
    this.stateElection['noOfExemptions'] = '';
    this.stateElection['withholdingPercentage'] = '';
    this.stateElection['flatWithholdingAmt'] = '';
    this.stateElection['addWithholdingPercentage'] = '';
    this.stateElection['addWithholdingAmt'] = '';
    this.stateElection['dropdownLabel1Value'] = '';
    this.stateElection['dropdownLabel2Value'] = '';
    this.stateElection['dropdownLabel3Value'] = '';
    this.stateElection['dropdownLabel4Value'] = '';
      this.electedInFlag = true;
    }
    this.stateForm = this._formBuilder.group({
      taxState: [{ value: this.stateElection.taxState, disabled: true }],
      stateWithholdingStatus: [this.stateElection.stateWithholdingStatus],
      maritalStatus: [this.currentMaritalStatus],
      noOfExemptions: [this.stateElection.noOfExemptions, Validators.pattern('^[0-9]+$')],
      withholdingPercentage: [this.stateElection.withholdingPercentage, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')],
      flatWithholdingAmt: [this.stateElection.flatWithholdingAmt, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')],
      addWithholdingPercentage: [this.stateElection.addWithholdingPercentage, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')],
      addWithholdingAmt: [this.stateElection.addWithholdingAmt, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')],
      electedOptions: [this.electedInFlag],
      dropdownLabel1: [''],
      dropdownLabel2: [''],
      dropdownLabel3: [''],
      dropdownLabel4: [''],
    });
    // setTimeout(() => {
    // console.log(this.currentMaritalStatus)
    this.stateForm.patchValue({
      maritalStatus: [this.currentMaritalStatus],
      dropdownLabel1: [this.dropDownLabelValue1],
      dropdownLabel2: [this.dropDownLabelValue2],
      dropdownLabel3: [this.dropDownLabelValue3],
      dropdownLabel4: [this.dropDownLabelValue4]
    })
    // }, 3000);
  }

  save() {
    //console.log("This is save Function");
    this.stateSaveClicked = true;
    //Save
    if (this.stateForm.valid) {
      this.setStateFieldFromUI();
      this.payload = this.statePayloadStructure();
      console.log(this.payload);
      this.spinnerService.show();
      this.apiServiceCall.updateState(this.payload).subscribe(res => {
        console.log(res);
        if (res == 0) {
          this._globalService.setCommonFlag(false);
          console.log("inside if");
          this._globalService.setUpdateStateStatus(true);
          this._globalService.setStateErrorFlag(false);
          this.setGoTo('stateElec');
          this.spinnerService.hide();
        } else {
          console.log("inside else");
          this.stateEditMsg = res;
          this._globalService.setStateErrorFlag(true);
          this._globalService.setUpdateStateStatus(false);
          this.spinnerService.hide();
          this.stateSaveClicked = false;
        }
      } ,error=>{
        console.log("error inside state");
        this.spinnerService.hide();
        this.stateSaveClicked = false;
      })
    } else {
      this.validateAllFields(this.stateForm);
      $('.errorBorder:first').focus();
    }
  }

  setStateFieldFromUI() {
    //console.log("This is setStateFieldFromUI Function");
    //this.stateSaveClicked = true;
    //console.log("inside save", this.stateForm.value);
    let maritalValue;
    let dropValue1;
    let dropValue2;
    let dropValue3;
    let dropValue4;
    let maritalArray = [];
    this.stateElection = new StateElection();
    this.stateElection['taxState'] = this.stateForm.get('taxState').value;
    if (this.stateForm.get('electedOptions').value === false) {
      this.stateElection['stateWithholdingStatus'] = "I"
    } else {
      this.stateElection['stateWithholdingStatus'] = "O"
    }
    if (this.stateForm.get('maritalStatus').value == '' || this.stateForm.get('maritalStatus').value == null || this.stateForm.get('maritalStatus').value == undefined) {
      console.log("inside null marital");
      this.stateElection['maritalStatus'] = '';
    } else {
      maritalValue = this.stateForm.get('maritalStatus').value;
      maritalArray = Array.isArray(maritalValue) ? maritalValue[0].split('-') : maritalValue.split('-');
      this.stateElection['maritalStatus'] = maritalArray[0].trim();
      console.log("marital final value", this.stateElection['maritalStatus']);
    }

    //console.log(this.stateElection['maritalStatus']);
    this.stateElection['noOfExemptions'] = this.stateForm.get('noOfExemptions').value;
    if (this.stateElection['noOfExemptions'] == '' || this.stateElection['noOfExemptions'] == null) {
      this.stateElection['noOfExemptions'] = "0";
    }
    this.stateElection['withholdingPercentage'] = this.stateForm.get('withholdingPercentage').value;
    if (this.stateElection['withholdingPercentage'] == '' || this.stateElection['withholdingPercentage'] == null) {
      this.stateElection['withholdingPercentage'] = "0";
    }else if(this.stateElection['withholdingPercentage'] && this.stateElection['withholdingPercentage'].length>9){
      this.stateElection['withholdingPercentage']=this.stateElection['withholdingPercentage'].substring(0, 9);
     console.log(this.stateElection['withholdingPercentage'])
    } 



    this.stateElection['flatWithholdingAmt'] = this.stateForm.get('flatWithholdingAmt').value;
    if (this.stateElection['flatWithholdingAmt'] == '' || this.stateElection['flatWithholdingAmt'] == null) {
      this.stateElection['flatWithholdingAmt'] = "0";
    }else if(this.stateElection['flatWithholdingAmt'] && this.stateElection['flatWithholdingAmt'].length>9){
      this.stateElection['flatWithholdingAmt']=this.stateElection['flatWithholdingAmt'].substring(0, 9);
     console.log(this.stateElection['flatWithholdingAmt'])
    } 
    
    this.stateElection['addWithholdingPercentage'] = this.stateForm.get('addWithholdingPercentage').value;
    if (this.stateElection['addWithholdingPercentage'] == '' || this.stateElection['addWithholdingPercentage'] == null) {
      this.stateElection['addWithholdingPercentage'] = "0";
    }else if(this.stateElection['addWithholdingPercentage'].length>9){
      this.stateElection['addWithholdingPercentage']=this.stateElection['addWithholdingPercentage'].substring(0, 9);
     console.log(this.stateElection['addWithholdingPercentage'])
    }
    this.stateElection['addWithholdingAmt'] = this.stateForm.get('addWithholdingAmt').value;
    if (this.stateElection['addWithholdingAmt'] == '' || this.stateElection['addWithholdingAmt'] == null) {
      this.stateElection['addWithholdingAmt'] = "0";
    }else if(this.stateElection['addWithholdingAmt'] && this.stateElection['addWithholdingAmt'].length>9){
      this.stateElection['addWithholdingAmt']=this.stateElection['addWithholdingAmt'].substring(0, 9);
     console.log(this.stateElection['addWithholdingAmt'])
    }
    
    dropValue1 = this.stateForm.get('dropdownLabel1').value;
    this.stateElection['dropdownLabel1Value'] = dropValue1;
    if (this.stateElection['dropdownLabel1Value'] == undefined) {
      this.stateElection['dropdownLabel1Value'] = '';
    }

    dropValue2 = this.stateForm.get('dropdownLabel2').value;
    this.stateElection['dropdownLabel2Value'] = dropValue2;
    if (this.stateElection['dropdownLabel2Value'] == undefined) {
      this.stateElection['dropdownLabel2Value'] = '';
    }

    dropValue3 = this.stateForm.get('dropdownLabel3').value;
    this.stateElection['dropdownLabel3Value'] = dropValue3;
    if (this.stateElection['dropdownLabel3Value'] == undefined) {
      this.stateElection['dropdownLabel3Value'] = '';
    }

    dropValue4 = this.stateForm.get('dropdownLabel4').value;
    this.stateElection['dropdownLabel4Value'] = dropValue4;
    if(this.stateElection['dropdownLabel4Value'] == undefined){
      this.stateElection['dropdownLabel4Value'] = '';
    }
    //console.log("data coming from state edit UI", this.stateElection);
  }

  setGoTo(value) {
    this._globalService.setGoTo(value);
    this._infoComponent.goTo = this._globalService.getGoTo();
  }

  electedOptions(e) {
    if (!e.target.checked) {
      $('#checkboxModal').modal('show');
    } else {
      $('#checkboxModal2').modal('show');
    }
  }
  changeCheckboxStatus() {
    this.showStateElectionFlag = false;
    this.stateForm.patchValue({
      'electedOptions': true
    });
  }
  changeCheckboxStatus2() {
    this.showStateElectionFlag = true;
    this.stateForm.patchValue({
      'electedOptions': false
    });
  }
  showElectOutField() {
    this.showStateElectionFlag = true;
    this.stateForm.patchValue({
      'electedOptions': false
    });
  }
  showElectInField() {
    this.showStateElectionFlag = false;
    this.stateForm.patchValue({
      'electedOptions': true
    });
  }

  ///field Validation
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }


  statePayloadStructure() {
    this.registrationData = this._globalService.getRegistrationData();
    this.addressDataObj = this.registrationData['RegistrationResponse'].readRegistrationResponse;

    return this.statePayload =
      {
        "RegistrationRequest": {
          "updateRegistrationRequest": {
            "addressrules": "N",
            "contactrules": "N",
            "paymentmethodrules": "N",
            "fedexrules": "N",
            "statetaxrules": "Y",
            "addressfinalist": "N",
            "residentstreetline1": this.addressDataObj.residentAddress.streetLine1,
            "residentstreetline2": this.addressDataObj.residentAddress.streetLine2,
            "residentstreetline3": this.addressDataObj.residentAddress.streetLine3,
            "residentcity": this.addressDataObj.residentAddress.city,
            "residentstate": "CT",
            "residentpostalcode": '00000-0000',
            "foreigncountryname": this.addressDataObj.residentForeignAddress.country.fullName,
            "foreignprovincename": [
              {
                "name": this.addressDataObj.residentForeignAddress.country.geopoliticalSubregion[0].name,
              }],
            "foreigncityname": this.addressDataObj.residentForeignAddress.city,
            "foreignmailcode": this.addressDataObj.residentForeignAddress.postalCode,
            "requeststreetLine1": this.addressDataObj.mailingAddress.streetLine1,
            "requeststreetLine2": this.addressDataObj.mailingAddress.streetLine2,
            "requeststreetLine3": this.addressDataObj.mailingAddress.streetLine3,
            "requestcity": this.addressDataObj.mailingAddress.city,
            "requeststate": "CT",
            "requestpostalCode": '00000-0000',
            "requestcountryname": this.addressDataObj.mailingForeignAddress.country.fullName,
            "requestprovincename": [
              {
                "name": this.addressDataObj.mailingForeignAddress.country.geopoliticalSubregion[0].name
              }],
            "requestcityname": this.addressDataObj.mailingForeignAddress.city,
            "requestforeignmailcode": "00000000",
            "foreigncountrycode": this.addressDataObj.contactForeignPhone.country.code,
            "foreignphonenumber": "12345-74474744",
            "requestphonenumber": "456-273-1234",
            "emailaddress": this.addressDataObj.email.address,
            "emaildomain": this.addressDataObj.email.domain,
            "bankroutingnumber": this.addressDataObj.pensionAccount.pensionholderBankAccount.routingNumber,
            "bankname": this.addressDataObj.pensionAccount.pensionholderBankAccount.bankName,
            "bankpaymenttype": this.addressDataObj.pensionAccount.preferredPaymentType,
            "bankaccountnumber": this.addressDataObj.pensionAccount.pensionholderBankAccount.accountNumber,
            "bankaccounttype": this.addressDataObj.pensionAccount.pensionholderBankAccount.accountType,
            "bankjointaccountind": this.addressDataObj.pensionAccount.pensionholderBankAccount.isJointAccount,
            "bankjointholderlastname": this.addressDataObj.jointAccountHolder.nameLast,
            "bankjointholderfirstname": this.addressDataObj.jointAccountHolder.nameFirst,
            "bankjointholdermiddlename": this.addressDataObj.jointAccountHolder.nameMiddle,
            "bankjointholderssn": this.addressDataObj.jointAccountHolder.socialSecurityNumber,
            "bankjointholderrelation": this.addressDataObj.jointaccountHolderRel.relationship,
            "fedtaxelectioncode": this.addressDataObj.fedTax.pensionTaxElectionCode,
            "fedtaxexemptionnumber": this.addressDataObj.fedTax.numberOfExemptions,
            "fedtaxfillingstatus": this.addressDataObj.fedTax.filingStatus,
            "fedtaxwithholdingamt": this.addressDataObj.fedTax.withholdingAmount,
            "fedtaxaddnlwithholdingamt": this.addressDataObj.fedTax.additionalWithholdingAmount,


            "statetaxelectioncode": this.stateElection['stateWithholdingStatus'],
            "statetaxexemption": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['noOfExemptions'] : "0",
            "statetaxfillingstatus": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['maritalStatus'] : "",
            "statetaxwithholdingamt": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['flatWithholdingAmt'] : "0",
            "statetaxaddlwithholdingamt": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['addWithholdingAmt'] : "0",
            "statetaxwithholdingPCT": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['withholdingPercentage'] : "0",
            "statetaxaddlwithholdingPCT": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['addWithholdingPercentage'] : "0",
            "splexemptiondesc1": [
              {
                "description": this.stateElection['stateWithholdingStatus'] === "I" ? this.dropDownLabel1 : "",
                "value": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['dropdownLabel1Value'] : ""
              },
              {
                "description": this.stateElection['stateWithholdingStatus'] === "I" ? this.dropDownLabel2 : "",
                "value": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['dropdownLabel2Value'] : ""
              },
              {
                "description": this.stateElection['stateWithholdingStatus'] === "I" ? this.dropDownLabel3 : "",
                "value": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['dropdownLabel3Value'] : ""
              },
              {
                "description": this.stateElection['stateWithholdingStatus'] === "I" ? this.dropDownLabel4 : "",
                "value": this.stateElection['stateWithholdingStatus'] === "I" ? this.stateElection['dropdownLabel4Value'] : ""
              }
            ]
          }
        }
      }
  }
}
