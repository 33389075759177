import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './components/help.component';


@NgModule({
    declarations: [HelpComponent],
    imports: [CommonModule],
    exports: [],
    bootstrap: [HelpComponent]
})
export class HelpModule { }
